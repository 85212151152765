import { Box, Card, CardHeader, Divider, Stack } from '@mui/material';
import { useIsMobile } from '@nvapps/common/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PositionCommunications } from '@nvtracker/common/helpers';
import { useDevicesPositionsSummary } from '../../../../store/contexts/userDevices';
import NumberTextCard from '../../../Ui/NumberTextCard';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';

export default function ComunicationsCard() {

    const [summary, isLoading] = useDevicesPositionsSummary();
    const isMobile = useIsMobile('sm');
    const orientation = !isMobile ? 'vertical' : 'horizontal';
    const { t } = useTranslation();

    return (
        <Card>
            <CardHeader title={t('communications')} />
            <Box p={2} position={"relative"}>
                {isLoading && <OverlapProgress />}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    divider={<Divider orientation={orientation} flexItem />}
                    justifyContent="space-evenly"
                    alignItems="center"
                    textAlign="center"
                    spacing={2}>
                    <NumberTextCard value={summary.More24h} text={t(PositionCommunications.More24h.text)} color='error' />
                    <NumberTextCard value={summary.Between12And24H} text={t(PositionCommunications.Between12And24H.text)} color='warning' />
                    <NumberTextCard value={summary.UpToDate} text={t(PositionCommunications.UpToDate.text)} color='success' />
                </Stack>
            </Box>
        </Card>
    );
}
