import { authRequest } from "./utils";

const apiBaseUrl = "";

export function getInvoices(userId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/invoices?userId=${userId}`
    });
}

export function createInvoice(invoice) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/invoices`,
        data: invoice
    });
}

export function updateInvoice(invoiceId, { purchases = []}) {
    return authRequest({
        method: "PUT",
        url: `${apiBaseUrl}/api/invoices/${invoiceId}`,
        data: { purchases }
    });
}