import { DialogContent, DialogTitle, Box } from '@mui/material';
import Dialog from '@nvapps/common/components/ui/Dialog';
import React from 'react';
import CloseIconButton from '../CloseIconButton';
import { Img } from '../Img';

export default function DialogViewImage({ open, name, url, token, translations, onClose }) {

    return <Dialog open={open} maxWidth='md'>
        <DialogTitle>
            <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
                {name}
                <CloseIconButton title={translations.close} onClick={onClose}  />
            </Box>
        </DialogTitle>
        <DialogContent>
            <Box sx={{ height : '70vh' }}>
                <Img src={url} token={token} />
            </Box>
        </DialogContent>
    </Dialog>;
}
