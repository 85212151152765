import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { Box, CircularProgress, Divider } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIsMobile } from '../../../hooks';
import { downloadBlob, downloadUrlFile, groupDataBy, isImageFile } from '../../../utils';
import FullScreenProgress from '../FullScreenProgress';
import DialogFolderName from './DialogFolderName';
import DialogViewImage from './DialogViewImage';
import FileManagerToolbar from './FileManagerToolbar';
import FolderContent from './FolderContent';
import UploadZone from './UploadZone';


const data =
    [
        {
            id: '1',
            name: 'Child - 1',
            type: 'folder',
            items: [
                { id: '48', name: 'Child - 11', type: 'folder' },
                { id: '55', name: 'Child - 12', type: 'folder' },
                { id: 5, name: 'Joao.jpg', type: 'file', url: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png' },
                { id: 6, name: 'Joao.jpg', type: 'file' },
            ]
        },
        {
            id: '3',
            name: 'Child - 3', type: 'folder',
            items: [
                {
                    id: '4',
                    name: 'Child - 4', type: 'folder',

                    items: [
                        { id: 8, name: 'Joao.jpg' },
                        { id: 9, name: 'Joao.jpg' },
                    ]
                },
            ],
        },
    ]


const search = (tree, target) => {

    if (!target) return null;

    if (tree.id === target) return tree;

    for (const child of (tree.items || [])) {
        const found = search(child, target);
        if (found) return found;
    }
};

const getFoldersIds = tree => {
    let res = [];

    if (tree.type == 'Folder') res = [...res, tree.id];

    for (const child of (tree.items || [])) {
        const resChild = getFoldersIds(child);
        res = [...res, ...resChild];
    }

    return res;
}

function listToTreeItems(key, indexed) {
    const list = indexed[key] || [];

    return list.map(e => {
        return {
            ...e,
            items: listToTreeItems(e.id, indexed)
        };
    })
}



const defaultLocale =
{
    root: 'Raiz',
    newFolder: 'New folder',
    editFolder: 'Edit folder',
    open: 'Open',
    delete: 'Delete',
    upload: 'Upload',
    download: 'Download',
    emptyFolder: 'Empty folder',
    folderName: 'Folder name',
    requiredField: 'Required field',
    confirm: 'Confirm',
    cancel: 'Cancel',
    clickOrDrag: 'Drag files here or click to select...',
    errorFileToBig: 'The file is to big',
    edit: 'Edit',
    deleteQuestion: 'Do wish to delete?',
    yes: 'Yes',
    no: 'No',
    close : 'Close',
    view : 'View',
    options : 'Options'
}

const dialogFolderInitProps = { open: false, name: '', id: null };

export default function FileManager(
    {
        items = [],
        accept,
        locales = {},
        maxFileSize = '3145728',
        loading = false,
        authenticationToken,
        onUploadFile,
        onDownloadFile,
        onOpenFile,
        onCreateFolder,
        onUpdateFolder,
        onDelete,
    }) {

    const isMobile = useIsMobile();
    const translations = { ...defaultLocale, ...locales };
    const treeItems = useMemo(() => {
        const dataIndexed = groupDataBy(items, 'parentId');
        return { id: 'root', name: translations.root, items: listToTreeItems(null, dataIndexed) };
    }, [items]);

    const [treeItemSelected, setTreeItemSelected] = useState('root');
    const [contentItemSelected, setContentItemSelected] = useState(null);
    const [dialogNewFolderProps, setDialogNewFolderProps] = useState(dialogFolderInitProps);
    const [showUploadArea, setShowUploadArea] = useState(false);
    const [expanded, setExpanded] = useState(['root']);
    const [dialogViewImageOpen, setDialogViewImageOpen] = useState(false);

    const selectedFolder = useMemo(() => search(treeItems, treeItemSelected), [treeItems, treeItemSelected]);
    const selectedItem = useMemo(() => search(treeItems, contentItemSelected), [treeItems, contentItemSelected]);

    useEffect(() => setContentItemSelected(null), [treeItemSelected, items]);

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.items)
                ? nodes.items.filter(e => e.type === 'Folder').map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    const toggleUpload = open => setShowUploadArea(open);
    const showFileButtons = selectedItem !== null && !showUploadArea;

    const handleConfirmUpload = file => onUploadFile(file, getParentId(), success => {
        if (success) toggleUpload(false);
    });

    ///// Folder
    const callBackChangeFolderName = success => {
        if (success) handleCloseDialogFolderName();
    }

    const handleChangeFolderName = folderName => {
        if (!dialogNewFolderProps.id) onCreateFolder && onCreateFolder(folderName, getParentId(), callBackChangeFolderName);
        else onUpdateFolder && onUpdateFolder(dialogNewFolderProps.id, folderName, getParentId(), callBackChangeFolderName);
    };

    const handleCloseDialogFolderName = () => setDialogNewFolderProps(dialogFolderInitProps);
    const handleEditFolder = () => setDialogNewFolderProps({ id: selectedItem.id, name: selectedItem.name, open: true });
    const handleCreateFolder = () => setDialogNewFolderProps({ id: null, name: "", open: true });

    const getParentId = () => treeItemSelected == 'root' ? null : treeItemSelected;

    const handleDelete = () => onDelete && onDelete(contentItemSelected);
    const handleItemDoubleClick = id => {
        const item = search(treeItems, id);
        if (item && item.type === 'Folder') setTreeItemSelected(id);
    }

    const handleToggle = (event, nodeIds) => {
        if (event.target.closest(".MuiTreeItem-iconContainer")) setExpanded(nodeIds);
    };

    const handleDownload = () =>
    {
        if (selectedItem && selectedItem.type == 'File')
        {
            onDownloadFile && onDownloadFile(selectedItem);
            downloadUrlFile(selectedItem.name, selectedItem.url, authenticationToken);
        }
    }
    const handleView = () =>
    {
        if (selectedItem && selectedItem.type == 'File')
        {
            onOpenFile && onOpenFile(selectedItem);
            setDialogViewImageOpen(true);
        }
    }

    const mainCss = !isMobile ? { gridTemplateColumns: 'auto auto 1fr' } : { gridTemplateRows: 'auto auto 1fr' };

    return (
        <Box sx={{ height: '100%', display: 'grid', gridTemplateRows: 'auto auto 1fr' }}>
            {loading && <FullScreenProgress style={{ backgroundColor: '#ffffff61', zIndex: 1, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} progressProps={{ size: 150 }} />}
            <FileManagerToolbar
                showDeleteButton={showFileButtons}
                showDownloadButton={selectedItem && selectedItem.type === 'File'}
                showViewButton={selectedItem && selectedItem.type === 'File' && isImageFile(selectedItem.name)}
                showEditButton={selectedItem && selectedItem.type === 'Folder'}
                showUploadButton={!showUploadArea}
                translations={translations}
                onUpload={() => toggleUpload(true)}
                onEditFolder={handleEditFolder}
                onCreateFolder={handleCreateFolder}
                onDelete={handleDelete}
                onDownload={handleDownload}
                onView={handleView}
            />
            <Divider orientation={'horizontal'} />
            <Box display="grid" height={"100%"} p={1} gap={2} overflow={"auto"} sx={mainCss}>
                <Box width={isMobile ? '100%' : 200} maxHeight={isMobile ? 200 : '100%'}>
                    <TreeView
                        aria-label="files treeview"
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                        defaultCollapseIcon={<ExpandMoreIcon onClick={(event, id) => console.log('expand', event, id)} />}
                        defaultExpanded={['root']}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ height: '100%', flexGrow: 1, overflowY: 'auto' }}
                        onNodeSelect={(event, nodeIds) => setTreeItemSelected(nodeIds)}
                        selected={treeItemSelected}
                    >
                        {renderTree(treeItems)}
                    </TreeView>
                </Box>
                <Divider orientation={isMobile ? 'horizontal' : 'vertical'} />
                <Box sx={{ height: '100%' }} overflow={"auto"} >
                    {!showUploadArea ?
                        <FolderContent
                            folder={selectedFolder}
                            selected={contentItemSelected}
                            translations={translations}
                            authenticationToken={authenticationToken}
                            onItemClick={id => setContentItemSelected(id)}
                            onItemDoubleClick={handleItemDoubleClick} /> :
                        <UploadZone
                            accept={accept}
                            translations={translations}
                            maxFileSize={maxFileSize}
                            onConfirmUpload={handleConfirmUpload}
                            onCloseUpload={() => toggleUpload(false)} />
                    }
                </Box>
                <DialogFolderName
                    open={dialogNewFolderProps.open}
                    name={dialogNewFolderProps.name}
                    title={dialogNewFolderProps.id ? `${translations.editFolder} - ${dialogNewFolderProps.name}` : translations.newFolder}
                    translations={translations}
                    onCancel={handleCloseDialogFolderName}
                    onConfirm={handleChangeFolderName} />
            </Box>
            <DialogViewImage
                open={dialogViewImageOpen}
                url={selectedItem && selectedItem.url}
                name={selectedItem && selectedItem.name}
                token={authenticationToken}
                translations={translations}
                onClose={() => setDialogViewImageOpen(false)} />
        </Box>
    )
}
