import { Box, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import CloseIconButton from '@nvapps/common/components/ui/CloseIconButton';
import Dialog from '@nvapps/common/components/ui/Dialog';
import Label from '@nvapps/common/components/ui/Label';
import CarMarker from "@nvtracker/common/components/map/CarMarker";
import Map from '@nvtracker/common/components/map/Map';
import BoxInfo from '@nvtracker/common/components/ui/BoxInfo';
import { getStatusOfPosition, getStatusOfPositionCommunication, PositionCommunications } from '@nvtracker/common/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DateTime from '../../components/Ui/DateTime';


//<Chip sx={{ fontWeight: 600, mr: 1 }} label={<DateTime datetime={stateEndTime} />} size="small" variant="filled" />

export function MapDialogContent({ device, position, onClose }) {


    const { name, brand, model } = (device || {});
    const brandModel = [brand, model].filter(e => (e || '').trim().length > 0).join(' ');
    const carPosition = position || { coordinates: [59.95, 30.33] };
    const { stateEndTime, status = 'NOLOCATION', address } = carPosition;
    const { t } = useTranslation();

    return <>
        <DialogTitle>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <BoxInfo title={name} subtitle={brandModel} />
                <Box>
                    <CloseIconButton title={t('close')} onClick={onClose} />
                </Box>
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={2}>
                <Box>
                    <Label color={PositionCommunications[getStatusOfPositionCommunication(stateEndTime)].color} sx={{ mr : 1, fontWeight: 600 }}>
                        <DateTime datetime={stateEndTime} />
                    </Label>
                    <Label color={getStatusOfPosition(status).color}>
                        {t(getStatusOfPosition(status).text)}
                    </Label>
                    <Typography variant="subtitle2" mt={0.5} display="block">{address || 'N/D'}</Typography>
                </Box>
                <Box sx={{ overflow: 'hidden', height: 500 }} borderRadius={1}>
                    <Map center={carPosition.coordinates} zoom={16}>
                        <CarMarker position={carPosition} />
                    </Map>
                </Box>
            </Stack>
        </DialogContent>
    </>;
}

export default function DeviceMapDialog({ open, device, position, onClose }) {

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <MapDialogContent device={device} position={position} onClose={onClose} />
        </Dialog>
    );
}
