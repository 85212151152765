import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as profileApi from '../api/profile';
import { getReportsSettingsUrl } from '../api/settings';
import { useFetchV2 } from '@nvapps/common/hooks';

export const getDefaultProfile = () =>
({
    userName: '',
    name: '',
    country: 'PT',
    language: 'pt',
    newsletter: false,
    phoneNumber: '',
    picture : null
})

const initialState = {

    profile:
    {
        data: getDefaultProfile(),
        fetching: false,
        fetched: false,
    },
    photo:
    {
        data: null,
        fetching: false,
    },
    reportsSettings :
    {
        data: [],
        fetching: false,
    }
    // timeZones : { data : [], fetching : false, error : null }
}

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {

        fetchProfileStart(state, { payload }) {
            state.profile.fetching = true;
        },

        fetchProfileSuccess(state, { payload }) {
            state.profile.fetching = false;
            state.profile.fetched = true;
            state.profile.data = { ...payload };
        },

        fetchPhotoStart(state, { payload }) {
            state.photo.fetching = true;
        },

        fetchPhotoSuccess(state, { payload }) {
            state.photo.fetching = false;
            state.photo.data = payload;
        },

        updatePhotoSuccess(state, { payload }) {
            state.profile.data.picture = payload;
        },

         fetchReportsSettingsStart(state, { payload }) {
            state.reportsSettings.fetching = true;
        },

        fetchReportsSettingsSuccess(state, { payload }) {
            state.reportsSettings.fetching = false;
            state.reportsSettings.fetched = true;
            state.reportsSettings.data = [...payload ];
        },

        // fetchTimeZonesStart(state, { payload }) {
        //     state.timeZones.fetching = true;
        // },

        // fetchTimeZonesSuccess(state, { payload }) {
        //     state.timeZones.fetching = false;
        //     state.timeZones.data = payload;
        // },

        // fetchTimeZonesFailed(state, { payload }) {
        //     state.timeZones.fetching = false;
        //     state.timeZones.error = payload;
        // },
    }
});


export function useUserActions() {
    const dispatch = useDispatch();
    const actions = user.actions;

    return useMemo(() => {
        return {

            updateProfile: profile => dispatch(actions.fetchProfileSuccess(profile)),
            changePassword: v => profileApi.changePassword(v),
            updatePhoto: async p => profileApi.updatePicture(p).then(res => dispatch(actions.updatePhotoSuccess(res ? res.data : null))),
            clearPhoto: () => actions.fetchPhotoSuccess(null)
        };
    }, [dispatch, actions]);
}

function fetchProfile(reload) {
    const actions = user.actions;

    return (dispatch, getState) => {
        var { user } = getState();

        if (!reload && (user.profile.fetching || user.profile.fetched)) return;

        dispatch(
            {
                type: actions.fetchProfileStart.type,
                meta:
                {
                    debounce: 200,
                    apiRequest:
                    {
                        method: "GET",
                        url: profileApi.getProfileUrl,
                        successActionType: actions.fetchProfileSuccess.type
                    },
                    cancelApiRequest:
                    {
                        startedBy: actions.fetchProfileStart.type
                    }
                }
            });
    };
}

export function useUserProfile(reload) {
    const state = useSelector(state => state.user.profile);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(fetchProfile(reload))
    }, [dispatch, reload]);

    return [state.data, state.fetching];
}

function fetchPhoto(reload) {
    const actions = user.actions;

    return (dispatch, getState) => {

        const { user } = getState();

        if (user.photo.fetching) return;

        dispatch(actions.fetchPhotoStart());
        profileApi.getProfilePhoto(user.profile.data.picture).then(res => {
            dispatch(actions.fetchPhotoSuccess(res));
        }).catch(res => {
            dispatch(actions.fetchPhotoSuccess(null));
        })
    };
}

export function useProfilePhoto() {

    const state = useSelector(state => state.user.photo);
    const dispatch = useDispatch();
    const picture = useSelector(state => state.user.profile.data.picture)

    useEffect(() => {
        dispatch(fetchPhoto());
    }, [dispatch, picture]);

    return [state.data, state.fetching]
}

export function useProfileLanguage()
{
    const state = useSelector(state => state.user.profile.data.language);
    return state || 'pt';
}

export function useUserCanSeeDevicesRegistered()
{
    return useFetchV2(() => profileApi.getPermissions(), []);
}

// function fetchTimeZones()
// {
//     return (dispatch, getState) => {

//         dispatch(user.actions.fetchTimeZonesStart());
//         return profileApi.getTimeZones().then(
//             res => {
//                 dispatch(user.actions.fetchTimeZonesSuccess(res.data));
//             },
//             err => dispatch(user.actions.fetchTimeZonesFailed(err))
//         );
//     }
// }

// function isFetchingTimeZones(state) {
//     return state.user.timeZones.fetching;
// }

// function timeZonesFetchError(state) {
//     return state.user.timeZones.error;
// }

// function timeZonesSelector(state)
// {
//     return state.user.timeZones.data;
// }

// export const useTimeZones = () => {

//     return useReduxFetch(fetchTimeZones, isFetchingTimeZones, timeZonesSelector, timeZonesFetchError);
// }

// export const useTimeZonesForSelect = () =>
// {
//     const [data, loading] = useTimeZones();
//     const res = useMemo(() => indexDataBy((data || []).map(e=> ({ value : e.id, label : e.name })), 'value'), [data]);
//     return [res, loading];
// }

function fetchReportsSettings(reload) {
    const actions = user.actions;

    return (dispatch, getState) => {
        var { user } = getState();

        if (!reload && (user.reportsSettings.fetching || user.reportsSettings.fetched)) return;

        dispatch(
            {
                type: actions.fetchReportsSettingsStart.type,
                meta:
                {
                    debounce: 200,
                    apiRequest:
                    {
                        method: "GET",
                        url: getReportsSettingsUrl,
                        successActionType: actions.fetchReportsSettingsSuccess.type
                    },
                    cancelApiRequest:
                    {
                        startedBy: actions.fetchReportsSettingsStart.type
                    }
                }
            });
    };
}

export function useUserReportsSettings(reload) {
    const state = useSelector(state => state.user.reportsSettings);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(fetchReportsSettings(reload))
    }, [dispatch, reload]);

    return [state.data, state.fetching];
}



export default user.reducer;