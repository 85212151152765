import { Box, Typography } from '@mui/material';
import RoundLabel from '@nvapps/common/components/ui/RoundLabel';
import React from 'react';

export default function NumberTextCard({ value, text, color }) {
    return (
        <Box>
            <RoundLabel variant="ghost" color={color} sx={{ mb: 1 }}>
                <Typography variant="h3">{value}</Typography>
            </RoundLabel>
            <Typography variant="subtitle2">{text}</Typography>
        </Box>
    );
}
