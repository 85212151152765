import { Icon } from '@iconify/react';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import FileUploadArea from '../FileUploadArea';

const units = [' B', ' KB', ' MB'];

const getFormattedFileSize = function (value) {
    var i = 0;
    while (value > 1000) { value /= 1000; i++ };
    return value.toFixed(0) + units[i];
}

export default function UploadZone({ maxFileSize, disableUploadButton, translations, accept, onCloseUpload, onConfirmUpload }) {

    const [file, setFile] = useState(null);
    const isFileBig = file && maxFileSize && file.size > maxFileSize;

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    return <Box display="grid" gridTemplateRows="1fr auto" height={"100%"} p={5} gap={2} alignItems={"center"}>
        <FileUploadArea sx={{ height: '100%' }} accept={accept} emptyMessage={translations.clickOrDrag} onChange={handleFileUpload}>
            {file ? <Box textAlign={"center"}>
                <Typography variant='h5' color={isFileBig ? 'error' : null}>
                    {file.name}
                </Typography>
                {isFileBig &&
                    <Typography variant='subtitle2' color="error">
                        {`${translations.errorFileToBig}. Max: ${getFormattedFileSize(maxFileSize)}`}
                    </Typography>}
                <Typography variant='subtitle2' color={isFileBig ? 'error' : null}>
                    {getFormattedFileSize(file.size)}
                </Typography>
            </Box> : <Typography variant='h5'>
                {translations.clickOrDrag}
            </Typography>}
        </FileUploadArea>
        <ButtonGroup size='large' sx={{ mx: 'auto' }}>
            <Button onClick={() => onConfirmUpload(file)} disabled={!file || disableUploadButton || isFileBig} startIcon={<Icon icon="ant-design:plus-outlined" height={24} width={24} />}>
                {translations.upload}
            </Button>
            <Button onClick={onCloseUpload} startIcon={<Icon icon="ant-design:close-outlined" height={24} width={24} />}>
                {translations.cancel}
            </Button>
        </ButtonGroup>
    </Box>;
}
