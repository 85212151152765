import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import React from 'react';

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkIconStyled = styled(Icon)({
    animation: `${blink} 1s linear infinite`,
});

const BlinkIcon = React.forwardRef((props, ref) => {

    return <BlinkIconStyled ref={ref} {...props} />
});

export default BlinkIcon;