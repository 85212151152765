import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, formatRelative, formatDistance, isDate } from "date-fns";

import { locales } from '@nvapps/common/utils/dateUtils';

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'pt',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default,

            format: (value, format, lng) => {

                if (isDate(value)) {

                    const locale = locales[lng];

                    if (format === "short")
                        return formatDate(value, "P", { locale });
                    if (format === "long")
                        return formatDate(value, "PPPP", { locale });
                    if (format === "relative")
                        return formatRelative(value, new Date(), { locale });
                    if (format === "ago")
                        return formatDistance(value, new Date(), {
                            locale,
                            addSuffix: true
                        });

                    return formatDate(value, format, { locale });
                }

                return value;
            }
        },
        resources: {
            en: {
                translation: {
                    search: 'Search',
                    selected: '{{count}} selected',
                    sendTestNotification: 'Send test notification'
                }
            },
            pt: {
                translation: {
                    search: 'Pesquisar',
                    selected_one: '{{count}} selecionada',
                    selected_other: '{{count}} selecionadas',
                    delete: 'Eliminar',
                    filterList: 'Filtrar lista',
                    yes: 'Sim',
                    no: 'Não',
                    deleteQuestion: 'Deseja eliminar?',
                    name: 'Nome',
                    username: 'Utilizador',
                    country: 'País',
                    edit: 'Editar',
                    informations: 'Informações',
                    save: 'Guardar',
                    requiredField: 'Campo obrigatório',
                    newsletter: 'Boletim informativo',
                    email: 'Email',
                    profile: 'Perfil',
                    language: 'Idioma',
                    registerDevice: 'Registar equipamento',
                    regularized: 'Regularizado',
                    renewDevice: 'Renovar equipamento',
                    expired: 'Expirou',
                    expiring: 'A expirar',
                    expires: 'Expira',
                    allowedImagemFiles: 'Allowed *.jpeg, *.jpg, *.png, *.gif max size of 2 MB',
                    information: 'Informação',
                    devices_one: '{{count}} equipamento',
                    devices_other: '{{count}} equipamentos',
                    options: 'Opções',
                    client: 'Cliente',
                    info: 'Informações',
                    contract: 'Contrato',
                    expiringDevices: 'Equipamentos a expirar',
                    expiredDevices: 'Equipamentos expirados',
                    in30days: 'Dentro de 30 dias',
                    in90days: 'Dentro de 90 dias',
                    in60days: 'Dentro de 60 dias',
                    export: 'Exportar',
                    view: 'Consultar',
                    renew: 'Renovar',
                    close: 'Fechar',
                    contacts: 'Contactos',
                    noContacts: 'Sem contactos',
                    device: 'Equipamento',
                    brand: 'Marca',
                    model: 'Modelo',
                    serialNumber: 'Nº série',
                    gsmNumber: 'Nº gsm',
                    plan: 'Plano',
                    renewalDate: 'Data de renovação',
                    currentRenewalDate: 'Data de renovação actual',
                    nextRenewalDate: 'Próxima data de renovação',
                    cancel: 'Cancelar',
                    devices: 'Equipamentos',
                    validated: 'Validado',
                    clients: 'Clientes',
                    phoneNumber: 'Nº telefone',
                    data: 'Dados',
                    purchases: 'Compras',
                    recordSaved: 'Registo guardado',
                    recordDeleted: 'Registo eliminado',
                    addContact: 'Adicionar contacto',
                    billingInfo: 'Informações de pagamento',
                    add: 'Adicionar',
                    fullName: 'Nome completo',
                    address: 'Morada',
                    postalCode: 'Código postal',
                    city: 'Cidade',
                    contact: 'Contacto',
                    obs: 'Observações',
                    communications: 'Comunicações',
                    more24h: 'Há mais de 24h',
                    between12and24h: 'Entre 12 e 24h',
                    upToDate: 'Actualizado',
                    status: 'Estado',
                    location: 'Localização',
                    openMap: 'Abrir mapa',
                    contracts: 'Contratos',
                    invoice: 'Factura',
                    confirmation: 'Confirmação',
                    selectDevice: 'Selecionar equipamento',
                    previous: 'Anterior',
                    next: 'Seguinte',
                    refresh: 'Actualizar',
                    version: 'Versão',
                    equipmentVersion: 'Versão de equipamento',
                    type: 'Tipo',
                    invoiceDate: 'Data factura',
                    register: 'Registar',
                    invoiceNumber: 'Nº factura',
                    id: 'Código',
                    creationDate: 'Data criação',
                    payment: 'Pagamento',
                    statusDate: 'Data estado',
                    device_s: 'Equipamento/s',
                    purchaseDetails: 'Detalhes compra',
                    total: 'Total',
                    seeDetails: 'Ver detalhes',
                    affectedDevices: 'Equipamentos afectados',
                    created: 'Criado',
                    general: 'Geral',
                    changePassword: 'Alterar senha',
                    remove: 'Remover',
                    updatePhoto: 'Alterar foto',
                    errorOldPass: 'Senha antiga errada',
                    oldPassword: 'Senha actual',
                    newPassword: 'Senha nova',
                    confirmPassword: 'Confirme a nova senha',
                    oldPasswordRequired: 'Senha antiga obrigatória',
                    newPasswordMustBe6Chars: 'Senha nova deve ter um mínimo de 6 caracteres. Caracteres especiais não permitidos',
                    passwordsMustMatch: 'A confirmação de senha não corresponde à senha nova',
                    newPasswordMustBeDifferentThanOldPassword: 'A senha nova deve ser diferente da senha actual',
                    errorLoginFailed: "Erro ao iniciar sessão",
                    login: 'Iniciar Sessão',
                    password: 'Senha',
                    noResults: 'Sem resultados',
                    pay: 'Pagar',
                    dates:
                    {
                        expires: 'Expira {{date, ago }}',
                        expired: 'Expirou {{date, ago }}'
                    },
                    cancelPurchaseQuestion: 'Deseja cancelar a compra?',
                    confirm: 'Confirmar',
                    cancelPurchase: 'Cancelar compra',
                    MBPayment: 'Pagamento Multibanco',
                    entity: 'Entidade',
                    reference: 'Referência',
                    value: 'Valor',
                    paymentDeadline: 'Prazo pagamento',
                    creditDebitCardPayment: 'Pagamento cartão crédito/débito',
                    payFailed: 'Pagamento falhou!',
                    cardNumber: 'Número cartão',
                    expiryDate: 'Data de validade',
                    userDeviceInfo: 'Info equipamento',
                    viewClient: 'Consultar cliente',
                    clientRecord: 'Ficha do cliente',
                    back: 'Voltar',
                    pending: 'Pendente',
                    paid: 'Pago',
                    failed: 'Com falha',
                    canceled: 'Cancelado',
                    nonAvailable: 'Não disponível',
                    card: 'Cartão',
                    atm: 'Multibanco',
                    other: 'Outro',
                    youHaveContractsExpiring_one: 'Tem {{count}} contrato a expirar dentro de 30 dias',
                    youHaveContractsExpiring_other: 'Tem {{count}} contratos a expirar dentro de 30 dias',
                    youHaveContractsExpired_one: 'Tem {{count}} contrato expirado',
                    youHaveContractsExpired_other: 'Tem {{count}} contratos expirados',
                    clickToSeeMore: 'Clique para ver mais',
                    alerts: 'Alertas',
                    noAlerts: 'Sem alertas',
                    root: 'Raiz',
                    newFolder: 'Nova pasta',
                    editFolder: 'Editar pasta',
                    open: 'Abrir',
                    upload: 'Carregar',
                    download: 'Descarregar',
                    emptyFolder: 'Pasta vazia',
                    folderName: 'Nome da pasta',
                    clickOrDrag: 'Arraste ficheiros para aqui ou clique para selecionar...',
                    errorFileToBig: 'Ficheiro demasiado grande',
                    files: 'Ficheiros',
                    purchase: 'Compra',
                    date: 'Data',
                    number: 'Número',
                    invoices: 'Facturas',
                    n_A: 'N/D',
                    events: 'Eventos',
                    event: 'Evento',
                    settings: 'Definições',
                    notificationsSettings: 'Definições notificações',
                    notifications: 'Notificações',
                    push: 'Push',
                    eventsTypes:
                    {
                        GEOFENCE_ENTER: 'Entrada de área',
                        GEOFENCE_EXIT: 'Saida de área',
                        OVERSPEED: 'Excesso de velocidade',
                        UNPLUG: 'Desligado',
                        INPUT: 'Sinais',
                        SCHEDULE: 'Horário',
                        TOW: 'Reboque'
                    },
                    logout: 'Terminar sessão',
                    operationSuccess: 'Operação efectuada com sucesso',
                    error: 'Erro',
                    dateTime: 'Data/Hora',
                    moving: 'Em movimento',
                    idle: 'Em relanti',
                    stopped: 'Parado',
                    noLocation: 'Sem localização',
                    "resultsFound_one": '{{count}} resultado encontrado',
                    "resultsFound_other": '{{count}} resultados encontrados',
                    stock: 'Em stock',
                    inUse: 'Em uso',
                    clear: 'Limpar',
                    invalidPhoneNumber: 'Nº telefone inválido',
                    vat: 'Nº identificação fiscal',
                    pendingPurchase: 'Pagamento pendente',
                    active: 'Activo',
                    inactive: 'Desactivado',
                    contractValidity: 'Validade contrato',
                    noVersion: 'Sem versão',
                    requestedBy: 'Pedido por',
                    invalidField: 'Campo inválido',
                    timeZone: 'Fuso horário',
                    server_InvalidTimeZone: 'Fuso horário inválido',
                    smsBalance: "Saldo SMS",
                    lastUpdate: 'Última actualização',
                    lastPosition: 'Última posição',
                    lastResult: 'Último resultado',
                    expired_plural: 'Expirados',
                    oldContract: 'Contrato antigo',
                    reset: 'Reset',
                    resetQuestion: 'Deseja fazer reset à data de renovação e plano do equipamento?',
                    planInDeviceInfo: 'Plano já presente no equipamento. Para poder alterar o plano por favor faça reset ao equipamento',
                    input1Name: 'Nome do input 1',
                    input2Name: 'Nome do input 2',
                    input3Name: 'Nome do input 3',
                    input4Name: 'Nome do input 4',
                    inputs: 'Sinais',
                    hasInput: 'Tem sinal',
                    doesntHaveInput: 'Não tem sinal',
                    activateGsm: 'Ativar cartão gsm',
                    suspendGsm: 'Suspender cartão gsm',
                    activeGsmQuestion: 'Deseja ativar o cartão gsm?',
                    suspendGsmQuestion: 'Deseja suspender o cartão gsm?',
                    gsmSuspended: 'Cartão gsm suspenso',
                    mustBeGreaterThanAndLessThan: 'Tem que ser maior que {{min}} e menor que {{max}}',
                    devicesExpiredTime: 'Equipamentos expirados há',
                    hours: 'Horas',
                    hour_s: 'Hora(s)',
                    reports: 'Relatórios',
                    suspended: 'Suspensos',
                    createInvoice: 'Criar factura',
                    linkInvoice: 'Associar factura',
                    linkPurchasetoInvoices: 'Associar compra {{purchase}} a facturas',
                    link: 'Associar',
                    ok: 'Ok',
                    insertPassword: 'Inserir a senha',
                    repairSerialNumber: 'Reparar número de série',
                    repairSerialNumberQuestion: 'Deseja reparar o número de série deste equipamento?',
                    changeRenewalDate: 'Alterar data de contracto',
                    changeRenewalDateQuestion: 'Deseja alterar a data de contracto deste equipamento?',
                    invalidDate: 'Data inválida',
                    server_WrongPassword: 'Senha errada',
                    annulLastPurchase: 'Anular última compra{{purchase}}',
                    annulLastPurchaseInfo: 'Anula a compra paga mais recente com o tipo de pagamento \'Outro\' desde que a última compra não tenha sido efetuada pelo pagamento automático',
                    annulled: 'Anulada',
                    finishInstallation: 'Finalizar instalação',
                    fieldMaxLength: "Campo não pode ser maior que",
                    licencePlate: 'Matrícula',
                    installationDate: 'Data de instalação',
                    maxCharLength: 'Número máximo de caracteres',
                    charRange: 'Número de caracteres',
                    installation: 'Instalação',
                    server_NothingToRepair: 'Equipamento não necessita de reparação',
                    sendTestNotification: 'Enviar notificação de teste',
                    sendTestNotifications: 'Enviar notificações de teste',
                    updateDate: 'Data actualização',
                    devicesRegistered: 'Dispositivos registados',
                    possibleLogin: 'Login possível',
                    day_s: 'Dia(s)',
                    devicesExpiringTime: 'Equipamentos a expirar em'
                }
            }
        }
    });


export default i18n;