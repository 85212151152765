import { Icon } from '@iconify/react';
import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import Label from '@nvapps/common/components/ui/Label';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import useDialogOpener, { useDebounce, useSessionStorage } from '@nvapps/common/hooks';
import { getCurrencySymbol } from '@nvapps/common/utils';
import { getFormatedDate } from '@nvapps/common/utils/dateUtils';
import { PaymentTypes, getPurchaseStatus } from '@nvtracker/common/helpers';
import { applyFilters } from '@nvtracker/common/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PurchaseDetailsDialog from '../../../../modals/purchases/PurchaseDetailsDialog';
import { usePurchaseActions, usePurchaseAnnulmentState, usePurchaseUserId, usePurchases } from '../../../../store/contexts/userPurchases';
import { usePlansById } from '../../../../store/plans';
import { useProfileLanguage } from '../../../../store/user';
import DateTime from '../../../Ui/DateTime';
import PasswordDialog from '../../../../modals/PasswordDialog';
import { useCallApi } from '../../../../hooks';

function Toolbar({ searchQuery, onSearch }) {

    const { t } = useTranslation();
    const [annulStatus, loading] = usePurchaseAnnulmentState();
    const { annulPurchase } = usePurchaseActions();
    const dialog = useDialogOpener();
    const { callApi, isLoading } = useCallApi();

    const handleAnullPurchase = async password => {
        if (Boolean(annulStatus)) {
            const res = await callApi(annulPurchase(annulStatus.id, password));
            if (res === true) dialog.handleCloseDialog();
        }
    }

    return (
        <TableToolbar>
            <SearchBox
                value={searchQuery}
                placeholder={`${t('search')}...`}
                onChange={ev => onSearch(ev.target.value)} />
            <Tooltip title={t('annulLastPurchaseInfo')} placement="top">
                <Box sx={{ ml: 'auto' }}>
                    <ButtonWithProgress
                        variant="outlined"
                        loading={loading}
                        disabled={annulStatus == null}
                        onClick={dialog.handleOpenDialog}
                        startIcon={<Icon icon="mdi:file-cancel-outline" />}>
                        <Trans values={{ purchase: annulStatus ? ` (${annulStatus.id})` : null }}>annulLastPurchase</Trans>
                    </ButtonWithProgress>
                </Box>
            </Tooltip>
            <PasswordDialog
                title={<Trans values={{ purchase: annulStatus ? ` (${annulStatus.id})` : null }}>annulLastPurchase</Trans>}
                open={dialog.open}
                loading={isLoading}
                onOk={handleAnullPurchase}
                onClose={dialog.handleCloseDialog}
            />
        </TableToolbar>
    );
}

export default function TabPurchases() {

    const [list, isLoading] = usePurchases();
    const [plans] = usePlansById();
    const purchaseDialog = useDialogOpener({});
    const { t } = useTranslation();
    const language = useProfileLanguage();
    const [filters, setFilters] = useSessionStorage('usersTabPurchasesFilters', { text: '', sortModel: [{ field: 'id', sort: 'desc' }] });
    const [searchQuery, setSearchQuery] = useState(filters.text);
    const userId = usePurchaseUserId();

    useDebounce(() => {
        setFilters(state => ({ ...state, text: searchQuery }));
    }, 200, [searchQuery])

    const getPlanById = useCallback(plan => plans && plans[plan] ? plans[plan].name : '-', [plans]);

    const getDevicesOfPurchase = useCallback(function getDevicesOfPurchase(purchase) {

        const devices = purchase.purchaseDevices || [];
        if (devices.length !== 1) return `${devices.length} ${t('devices')}`;
        else return devices[0].deviceName;
    }, [t]);

    const purchasesFiltered = useMemo(() => {
        return applyFilters(list.map(e => ({
            ...e,
            devices: getDevicesOfPurchase(e)
        })),
            [
                'id',
                { key: 'planId', formatter: (key, r) => getPlanById(r.planId) },
                'devices',
                'total',
                { key: 'creationDate', formatter: (key, r) => getFormatedDate(r.creationDate, 'dd MMMM yyyy', language) },
                { key: 'statusDate', formatter: (key, r) => getFormatedDate(r.statusDate, 'dd MMMM yyyy', language) },
                { key: 'status', formatter: (key, r) => t(getPurchaseStatus(r.status).text) },
                { key: 'paymentType', formatter: (key, r) => t((PaymentTypes[r.paymentType] || PaymentTypes.Other).text) }
            ], filters.text);
    }, [list, filters.text, language, getDevicesOfPurchase, getPlanById, t]);

    const columns =
        [{
            field: 'id',
            headerName: t('id'),
            width: 80,
            renderCell: ({ row: { id } }) =>
                <Typography variant="subtitle2" ml={1} noWrap>
                    {id}
                </Typography>,
        },
        {
            field: 'creationDate',
            headerName: t('creationDate'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row: { creationDate } }) => <DateTime datetime={creationDate} format='dd MMM yyyy' />,
        },
        {
            field: 'paymentType',
            headerName: t('payment'),
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row: { paymentType } }) => <Label color='info'>{t((PaymentTypes[paymentType] || PaymentTypes.Other).text)}</Label>
        },
        {
            field: 'status',
            headerName: t('status'),
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row: { status } }) => <Label color={getPurchaseStatus(status).color}>{t(getPurchaseStatus(status).text)}</Label>
        },
        {
            field: 'statusDate',
            headerName: t('statusDate'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row: { statusDate } }) => <DateTime datetime={statusDate} format='dd MMM yyyy' />
        },
        {
            field: 'devices',
            headerName: t('device_s'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row }) => row.devices,
        },
        {
            field: 'planId',
            headerName: t('plan'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row: { planId } }) => getPlanById(planId)
        },
        {
            field: 'total',
            headerName: t('total'),
            minWidth: 100,
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ row: { total, currency } }) => `${total.toLocaleString()} ${getCurrencySymbol(currency)}`,
        },
        {
            field: 'options',
            sortable: false,
            renderHeader: _ => <div></div>,
            renderCell: ({ row }) => <Tooltip title={t('seeDetails')} placement="top">
                <IconButton>
                    <Icon icon="eva:search-outline" width={20} height={20} />
                </IconButton>
            </Tooltip>,
            align: 'right',
            width: 70
        }];

    return (
        <Card>
            <Toolbar searchQuery={searchQuery} onSearch={text => setSearchQuery(text)} />
            <Box sx={{ height: 600 }}>
                <DataGrid
                    columns={columns}
                    rows={purchasesFiltered}
                    loading={isLoading}
                    density="comfortable"
                    pagination
                    getRowId={d => d.id}
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    onRowClick={({ row }) => purchaseDialog.handleOpenDialog({ ...row, userId })}
                    sortModel={filters.sortModel}
                    onSortModelChange={sortModel => setFilters({ ...filters, sortModel })}
                    pageSize={15} />
            </Box>
            <PurchaseDetailsDialog purchase={purchaseDialog.props} open={purchaseDialog.open} onClose={purchaseDialog.handleCloseDialog} />
        </Card>);
}