
import { Icon } from '@iconify/react';
import {
    Box, Button,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Label from '@nvapps/common/components/ui/Label';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import useDialogOpener from '@nvapps/common/hooks';
import { getCurrencySymbol } from '@nvapps/common/utils';
import { PaymentTypes, getPurchaseStatus } from '@nvtracker/common/helpers';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import DateTime from '../../components/Ui/DateTime';
import { useGridActions } from '../../hooks';
import PurchaseDetailsDialog from '../../modals/purchases/PurchaseDetailsDialog';
import { usePurchases, usePurchasesActions, usePurchasesFilters } from '../../store/purchasesPage';
import MoreMenu from '../Ui/MoreMenu';

export default function Purchases() {

    const [purchases, loading] = usePurchases();
    const { t } = useTranslation();
    const { pageSize, sortModel, searchQuery, selectedRows, changePage, changePageSize, selectRows, onSearch, sort, actions, page } = useGridActions(usePurchasesActions, usePurchasesFilters);

    const dialog = useDialogOpener();

    const columns =
        [{
            field: 'id',
            headerName: t('id'),
            width: 80,
            renderCell: ({ row: { id } }) =>
                <Typography variant="subtitle2" ml={1} noWrap>
                    {id}
                </Typography>,
        },
        {
            field: 'userName',
            headerName: t('username'),
            flex: 0.4,
            minWidth: 200,
            renderCell: ({ row: { userName, userRealName } }) => <Box sx={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${userRealName || userName}`}
                </Typography>
                {userRealName &&
                    <Typography variant="caption" noWrap>
                        {`${userName}`}
                    </Typography>}
            </Box>
        },
        {
            field: 'creationDate',
            headerName: t('creationDate'),
            flex: 0.2,
            minWidth: 120,
            renderCell: ({ row: { creationDate } }) => <DateTime datetime={creationDate} format='dd MMM yyyy' />,
        },
        {
            field: 'paymentType',
            headerName: t('payment'),
            width: 110,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row: { paymentType } }) => <Label color='info'>{t((PaymentTypes[paymentType] || PaymentTypes.Other).text)}</Label>
        },
        {
            field: 'status',
            headerName: t('status'),
            width: 110,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row: { status } }) => <Label color={getPurchaseStatus(status).color}>{t(getPurchaseStatus(status).text)}</Label>
        },
        {
            field: 'statusDate',
            headerName: t('statusDate'),
            flex: 0.2,
            minWidth: 120,
            renderCell: ({ row: { statusDate } }) => <DateTime datetime={statusDate} format='dd MMM yyyy' />
        },
        {
            field: 'devices',
            headerName: t('device_s'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row }) => row.devices,
        },
        {
            field: 'planId',
            headerName: t('plan'),
            flex: 0.2,
            minWidth: 120,
            renderCell: ({ row: { planName } }) => planName || '-'
        },
        {
            field: 'total',
            headerName: t('total'),
            minWidth: 100,
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ row: { total, currency } }) => `${total.toLocaleString()} ${getCurrencySymbol(currency)}`,
        },
        {
            field: 'options',
            sortable: false,
            renderHeader: _ => <div></div>,
            renderCell: ({ row }) => <MoreMenu>
                <MenuItem sx={{ color: 'text.secondary' }} onClick={()=> dialog.handleOpenDialog(row)}>
                    <ListItemIcon>
                        <Icon icon="eva:search-outline" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary={t('view')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                <Divider />
                <MenuItem sx={{ color: 'text.secondary' }} component={NavLink} to={`/app/clients/${row.userId}/general`}>
                    <ListItemIcon>
                        <Icon icon="eva:person-fill" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary={t('clientRecord')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </MoreMenu>,
            align: 'right',
            width: 70
        }];

    return <>
        <TableToolbar>
            <SearchBox
                value={searchQuery}
                onChange={ev => onSearch(ev.target.value)}
                placeholder={`${t('search')}...`} />
            <Button
                sx={{ ml: 'auto' }}
                variant="outlined"
                onClick={() => actions.fetchPurchases()}
                startIcon={<Icon icon="eva:refresh-fill" />}>
                <Trans>refresh</Trans>
            </Button>
        </TableToolbar>
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGrid
                rows={purchases}
                columns={columns}
                pageSize={pageSize}
                page={page}
                rowsPerPageOptions={[5, 10, 25]}
                loading={loading}
                sortModel={sortModel}
                selectionModel={selectedRows}
                density="comfortable"
                disableColumnMenu
                hideFooterSelectedRowCount
                pagination
                onRowClick={({ row }) => dialog.handleOpenDialog(row)}
                onSortModelChange={sort}
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                onSelectionModelChange={selectRows}
            />
        </Box>
        <PurchaseDetailsDialog purchase={dialog.props} open={dialog.open} showOptions={false} onClose={() => dialog.handleCloseDialog()} />
    </>
}

