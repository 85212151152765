import { Icon } from '@iconify/react';
import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import { useDebounce, useFetchV2, useSessionStorage } from '@nvapps/common/hooks';
import { getFormatedDate } from '@nvapps/common/utils/dateUtils.js';
import { applyFilters } from '@nvtracker/common/utils';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getDevicesRegistered, sendTestMessage } from '../../../../api/settings.js';
import { useProfileLanguage } from '../../../../store/user';
import DateTime from '../../../Ui/DateTime';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress.js';

function Toolbar({ searchQuery, onSearch, loading, onRefresh }) {

    const { t } = useTranslation();

    return (
        <TableToolbar>
            <SearchBox
                value={searchQuery}
                placeholder={`${t('search')}...`}
                onChange={ev => onSearch(ev.target.value)} />
            <Box sx={{ ml: 'auto' }}>
                <ButtonWithProgress
                    variant="outlined"
                    loading={loading}
                    disabled={loading}
                    onClick={onRefresh}
                    startIcon={<Icon icon="eva:refresh-fill" />}>
                    <Trans>refresh</Trans>
                </ButtonWithProgress>
            </Box>
        </TableToolbar>
    );
}

function useTestMessage() {

    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const callApi = async promise => {
        setIsLoading(true);
        return await promise
            .then(res => {

                const allSuccess = res.data.every(e => e.isSuccess);
                const message = allSuccess ? t('operationSuccess') : `${t('error')} - ${res.data.filter(e => !e.isSuccess).map(e => e.message).join(', ')}`;
                const variant = allSuccess ? 'success' : 'error';

                enqueueSnackbar(message, { variant });
                setIsLoading(false);
                return Promise.resolve(true);
            })
            .catch(error => {
                enqueueSnackbar(t('error', { defaultValue: 'Erro' }), { variant: 'error' });
                setIsLoading(false);
                return Promise.resolve(false);
            });
    }

    return [callApi, isLoading]
}

export default function TabDevicesRegistered({ userId }) {

    const [list, isFetching, , updateList] = useFetchV2(() => userId ? getDevicesRegistered(userId) : null, [userId]);
    const { t } = useTranslation();
    const language = useProfileLanguage();
    const [filters, setFilters] = useSessionStorage('usersTabDevicesRegisteredFilters', { text: '', sortModel: [{ field: 'deviceUniqueId', sort: 'desc' }] });
    const [searchQuery, setSearchQuery] = useState(filters.text);
    const [callTestMessage, isLoading] = useTestMessage();

    useDebounce(() => {
        setFilters(state => ({ ...state, text: searchQuery }));
    }, 200, [searchQuery])

    const purchasesFiltered = useMemo(() => {
        return applyFilters(list || [],
            [
                'deviceUniqueId',
                'deviceName',
                { key: 'dateCreated', formatter: (key, r) => getFormatedDate(r.dateCreated, 'Pp', language) },
                { key: 'dateModified', formatter: (key, r) => getFormatedDate(r.dateModified, 'Pp', language) },
                { key: 'datePossibleLogin', formatter: (key, r) => getFormatedDate(r.datePossibleLogin, 'Pp', language) },

            ], filters.text);
    }, [list, filters.text, language]);

    const handleSendTestMessage = async token => {
        await callTestMessage(sendTestMessage(parseInt(userId), token));
    }

    const columns =
        [{
            field: 'id',
            headerName: t('id'),
            flex: 0.2,
            renderCell: ({ row: { deviceUniqueId } }) =>
                <Typography variant="subtitle2" ml={1} noWrap>
                    {deviceUniqueId}
                </Typography>,
        },
        {
            field: 'deviceName',
            headerName: t('name'),
            flex: 0.2,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row: { deviceName } }) => deviceName || 'N/A'
        },
        {
            field: 'dateCreated',
            headerName: t('creationDate'),
            width: 150,
            renderCell: ({ row: { dateCreated } }) => <DateTime datetime={dateCreated} />,
        },
        {
            field: 'dateModified',
            headerName: t('updateDate'),
            width: 150,
            renderCell: ({ row: { dateModified } }) => <DateTime datetime={dateModified} />,
        },
        {
            field: 'datePossibleLogin',
            headerName: t('possibleLogin'),
            width: 150,
            renderCell: ({ row: { datePossibleLogin } }) => <DateTime datetime={datePossibleLogin} />,
        },
        {
            field: 'options',
            sortable: false,
            renderHeader: _ => <div></div>,
            renderCell: ({ row }) => <Tooltip title={t('sendTestNotification')} placement="top">
                <IconButton onClick={() => handleSendTestMessage(row.token)}>
                    <Icon icon="ic:round-send" width={20} height={20} />
                </IconButton>
            </Tooltip>,
            align: 'right',
            width: 70
        }];

    return (
        <Card>
            <Toolbar searchQuery={searchQuery} loading={isFetching || isLoading} onSearch={text => setSearchQuery(text)} onClick={() => handleSendTestMessage()} onRefresh={updateList} />
            <Box sx={{ height: 600 }}>
                <DataGrid
                    columns={columns}
                    rows={purchasesFiltered}
                    loading={isFetching || isLoading}
                    density="comfortable"
                    pagination
                    getRowId={d => d.deviceUniqueId}
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    sortModel={filters.sortModel}
                    rowsPerPageOptions={[15]}
                    onSortModelChange={sortModel => setFilters({ ...filters, sortModel })}
                    pageSize={15} />
            </Box>
        </Card>);
}