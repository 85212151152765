import { Box, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import Dialog from '@nvapps/common/components/ui/Dialog';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import { useTimeout } from '@nvapps/common/hooks';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

function Content({ title, loading, onOk, onClose }) {
	const [password, setPassword] = useState('');
	const inputRef = useRef();
	const { t } = useTranslation();

	useTimeout(() => {
		inputRef.current.querySelector('input').focus();
	}, 200);

	return (
		<React.Fragment>
			{loading && <OverlapProgress progressProps={{ size: 100 }} />}
			<DialogTitle>{ title || t('insertPassword')}</DialogTitle>
			<DialogContent>
				<Box component='form' autoComplete='off'>
					<TextField
						htmlFor='password'
						required
						fullWidth
						variant='standard'
						autoFocus
						label={<Trans>password</Trans>}
						id='not-a-password'
						name='not-a-password'
						type='password'
						ref={inputRef}
						sx={{ mb: 2 }}
						autoComplete='not-a-password'
						onChange={e => setPassword(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} disabled={loading} color='secondary'>
					<Trans>cancel</Trans>
				</Button>
				<ButtonWithProgress
					onClick={() => onOk(password)}
					disabled={password.length === 0}
					color='primary'
					loading={loading}>
					<Trans>ok</Trans>
				</ButtonWithProgress>
			</DialogActions>
		</React.Fragment>
	);
}

export default function PasswordDialog({ open, title, loading, onClose, onOk }) {
	return (
		<Dialog open={open} maxWidth='xs' onClose={onClose}>
			<Content title={title} loading={loading} onOk={onOk} onClose={onClose} />
		</Dialog>
	);
}
