import { Icon } from '@iconify/react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useCallApi } from '../../hooks';
import { useDevicesActions } from '../../store/devices';
import ConfirmPopover from './ConfirmPopover';

export default function RepairSerialNumberMenuItem({ deviceId, onSuccess }) {
    const ref = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { repairDeviceSerialNumber } = useDevicesActions();
    const { callApi } = useCallApi();

    const handleOk = async () => {
        await callApi(repairDeviceSerialNumber(deviceId).then(r => onSuccess && onSuccess(r.data)));
    };

    const handleClick = ev => {
        ev.stopPropagation();
        setConfirmOpen(true)
    }

    return <>
        <ConfirmPopover
            text={<Trans>repairSerialNumberQuestion</Trans>}
            open={confirmOpen}
            anchorEl={ref.current}
            onClose={() => setConfirmOpen(false)} onResolve={v => Boolean(v) && handleOk()}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
        <MenuItem sx={{ color: 'text.secondary' }} ref={ref} onClick={handleClick} >
            <ListItemIcon>
                <Icon icon="eva:swap-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={<Trans>repairSerialNumber</Trans>} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

    </>
}

