import { useFetchV2 } from "@nvapps/common/hooks";
import { useEffect, useState } from "react";
import { getAlerts } from "../api/alerts";
import { registerSubscription, unregisterSubscription } from "../mqtt";


// TODO - adicionar um intervalo de x minutos e por um timeout na sub do mqtt para não estar a obter os dados muitas vezes quando tiver muitos users
export function useAlerts()
{
    const [alerts, setAlerts] = useState([]);
    const [data, isLoading] = useFetchV2(() => getAlerts(), []);

    useEffect(() => setAlerts(data), [data]);

    useEffect(() => {

        const subTopic = `users/+/nvtracker/devices/#`;
        let timer;
        const refreshAlerts = () =>
        {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => getAlerts().then(res => setAlerts(res.data)), 1000);
        }

        const mqttSub = function (topic, data) {

            switch (data.action) {
                case "add":
                    return refreshAlerts()
                case "delete" :
                    return refreshAlerts()
                case "update":
                    return refreshAlerts()
                default:
                    throw new Error("Invalid action");
            }
        };

        registerSubscription(subTopic, mqttSub);

        return () =>
        {
            unregisterSubscription(subTopic, mqttSub);
        }
    }, []);

    return [alerts, isLoading];
}
