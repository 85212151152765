import DatePicker from '@mui/lab/DatePicker';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, Stack, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import CenteredContent from "@nvapps/common/components/ui/CenteredContent";
import Dialog from '@nvapps/common/components/ui/Dialog';
import { Field, FieldList } from '@nvapps/common/components/ui/Fields';
import { PhoneNumber } from "@nvapps/common/components/ui/PhoneInput";
import { useIsMobile } from '@nvapps/common/hooks';
import { getFormatedDate } from '@nvapps/common/utils/dateUtils';
import SelectPlan from '@nvtracker/common/components/ui/SelectPlan';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import { isValid } from 'date-fns';
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { registerDevice } from "../../api/devices";
import DateTime from '../../components/Ui/DateTime';
import DeviceTypeLabel from '../../components/Ui/DeviceTypeLabel';
import { useSaveApi } from "../../hooks";
import { useDeviceTypes } from "../../store/deviceTypes";
import { usePlansOfGsmNumber } from "../../store/plans";
import { useProfileLanguage } from '../../store/user';
import SelectDeviceDialog from "./SelectDeviceDialog";

function getErrors(activeStep, device) {

    const err = [];
    if (!device) return null;

    if (activeStep === 0) {
        if ((device.deviceTypeId || '').length === 0) err.push({ id: "deviceType", message: "requiredField" });
        if ((device.serialNumber || '').length === 0) err.push({ id: "serialNumber", message: "invalidSerialNumber" });
        if ((device.gsmNumber || '').length === 0) err.push({ id: "gsmNumber", message: "invalidGsmNumber" });
    }

    if (activeStep <= 1) {
        if ((device.name || '').trim().length === 0) err.push({ id: "name", message: "requiredField" });
        if (device.planId === "") err.push({ id: "plan", message: "requiredField" });
        if (device.deviceTypeId === "") err.push({ id: "deviceType", message: "requiredField" });
    }

    if (activeStep <= 2) {
        if ((device.invoiceNumber || '').trim().length === 0) err.push({ id: "invoiceNumber", message: "requiredField" });
        if (!device.invoiceDate) err.push({ id: "invoiceDate", message: "invalidField" });
    }

    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

const getEmptyDevice = () =>
({
    deviceTypeId: '',
    serialNumber: "",
    equipmentVersion: "",
    name: "",
    brand: "",
    model: "",
    gsmNumber: "",
    planId: "",
    invoiceNumber: "",
    invoiceObs: "",
    invoiceDate: new Date().toISOString()
});

function BigField({ title, value }) {
    return <>
        <Typography variant="body">
            {title || '-'}
        </Typography>
        <Typography variant="h5" fontWeight="600">
            {value || '-'}
        </Typography>
    </>
}

function DeviceStep({ device, errors, onChange }) {
    const { t } = useTranslation();
    const [selectDeviceOpen, setSelectDeviceOpen] = useState(false);
    const { equipmentVersion, gsmNumber, serialNumber } = device;
    let component;

    const handleSelectDevice = v => {
        onChange(
            {
                ...device,
                serialNumber: v.serialNumber,
                gsmNumber: v.gsmNumber,
                equipmentVersion: v.equipmentVersion,
                renewalDate : v.renewalDate,
                planId: v.planId || 0,
                planName : v.planName
            });

        setSelectDeviceOpen(false);
    }

    if (hasError(errors, "serialNumber")) {
        component = <CenteredContent>
            <Button onClick={() => setSelectDeviceOpen(true)} size="large" variant="outlined">
                <Trans>selectDevice</Trans>
            </Button>
        </CenteredContent>
    }
    else {
        component = <Box width="100%">
             <Box pb={2} textAlign="left">
                <Button size="small" onClick={() => setSelectDeviceOpen(true)} variant="outlined">
                    <Trans>selectDevice</Trans>
                </Button>
            </Box>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                    <BigField title={t('serialNumber')} value={serialNumber} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <BigField title={t('gsmNumber')} value={<PhoneNumber value={gsmNumber} />} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <BigField title={t('equipmentVersion')} value={equipmentVersion || '-'} />
                </Grid>
            </Grid>
        </Box>
    }

    return <>
        {component}
        <SelectDeviceDialog
            open={selectDeviceOpen}
            deviceTypeId={device.deviceTypeId}
            onSelect={handleSelectDevice}
            onClose={() => setSelectDeviceOpen(false)} />
    </>
}

function InvoiceStep({ device, errors, onChange }) {
    const { t } = useTranslation();

    const updateField = (ev) => {
        onChange({
            ...device,
            [ev.target.name]: ev.target.value
        })
    }

    const updateInvoiceDate = (date) => {
        onChange({
            ...device,
            invoiceDate: (date && isValid(date) && date.toISOString()) || ''
        })
    }

    function getMessage(key, defaultValue) {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : defaultValue;
    }

    return <Grid container spacing={3} pt={2} >
        <Grid item xs={12} sm={6}>
            <TextField
                required
                label={t('invoice')}
                value={device.invoiceNumber}
                name="invoiceNumber"
                error={hasError(errors, "invoiceNumber")}
                helperText={getMessage("invoiceNumber")}
                onChange={updateField}
                fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DatePicker
                inputVariant="filled"
                margin="dense"
                clearable
                label={t('invoiceDate')}
                format="yyyy-MM-dd"
                value={device.invoiceDate}
                onChange={updateInvoiceDate}
                renderInput={(params) => <TextField {...params} fullWidth required error={hasError(errors, "invoiceDate")}
                    helperText={getMessage("invoiceDate")} />} />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t('obs')}
                multiline
                rows={2}
                value={device.invoiceObs}
                name="invoiceObs"
                onChange={updateField}
                fullWidth />
        </Grid>
    </Grid>
}

function InfoStep({ device, errors, onChange, onUpdatePlans }) {
    const { t } = useTranslation();
    const [deviceTypes] = useDeviceTypes();
    const [plans] = usePlansOfGsmNumber(device.gsmNumber);
    const language = useProfileLanguage();

    useEffect(() => onUpdatePlans(plans), [plans, onUpdatePlans]);
    const { planName, renewalDate } = device || {}

    const updateField = (ev) => {
        onChange({
            ...device,
            [ev.target.name]: ev.target.value
        })
    }

    function getMessage(key, defaultValue) {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : defaultValue;
    }

    return <Grid container spacing={3} pt={2} pb={1} >
        <Grid item xs={12} sm={6}>
             <TextField
                required
                select
                autoFocus
                label={t('type')}
                value={device.deviceTypeId}
                name="deviceTypeId"
                error={hasError(errors, "deviceType")}
                helperText={getMessage("deviceType")}
                fullWidth
                onChange={updateField}>
                {(deviceTypes || []).map(t => (
                    <MenuItem key={t.id} value={t.id}>
                        {t.type}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
            {Boolean(planName) && Boolean(renewalDate) ?
            <Tooltip title={<Trans>planInDeviceInfo</Trans>}>
                <TextField
                    label={<Trans>plan</Trans>}
                    defaultValue={planName}
                    name="plan"
                    readOnly
                    disabled
                    helperText={`${t('renewalDate')}: ${getFormatedDate(renewalDate, 'P', language)}`}
                    fullWidth />
            </Tooltip> :
            <SelectPlan
                required
                label={t('plan')}
                value={device.planId}
                name="planId"
                onChange={updateField}
                error={hasError(errors, "plan")}
                helperText={getMessage("plan")}
                plans={plans}
                fullWidth />}
        </Grid>
        <Grid item xs={12}>
            <TextField
                required
                label={t('name')}
                value={device.name}
                name="name"
                error={hasError(errors, "name")}
                helperText={getMessage("name")}
                onChange={updateField}
                fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label={t('brand')}
                value={device.brand}
                name="brand"
                onChange={updateField}
                fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label={t('model')}
                value={device.model}
                name="model"
                onChange={updateField}
                fullWidth />
        </Grid>
    </Grid>
}

function ConfirmationStep({ device, plans }) {
    const { t } = useTranslation();
    const { name, gsmNumber, serialNumber, planId, planName, equipmentVersion, invoiceNumber, invoiceDate, renewalDate } = device || {};
    const plan = (plans || []).find(e=> e.id === planId);
    const isMobile = useIsMobile()
    const language = useProfileLanguage();

    return <Box width="100%">
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4} divider={isMobile ? null : <Divider orientation="vertical" flexItem />} justifyContent="space-between" >
            <Box width="100%" >
                <FieldList>
                    <Field px={0} title={t('type')} value={<DeviceTypeLabel deviceTypeId={device.deviceTypeId} /> } />
                    <Field px={0} title={t('serialNumber')} value={serialNumber} />
                    <Field px={0} title={t('gsmNumber')} value={<PhoneNumber value={gsmNumber} />} />
                    <Field px={0} title={t('equipmentVersion')} value={equipmentVersion || '-'} />
                </FieldList>
            </Box>
            <Box width="100%" >
                <FieldList >
                    <Field px={0} title={t('name')} value={name} />
                    {Boolean(renewalDate) ?
                        <Field px={0} title={t('plan')} value={
                            <Tooltip title={`${t('renewalDate')}: ${getFormatedDate(renewalDate, 'P', language)}`}>
                                <Box color="warning.dark">{planName}</Box>
                            </Tooltip>} /> :
                        <Field px={0} title={t('plan')} value={plan ? plan.name : '-'} /> }
                    <Field px={0} title={t('invoiceNumber')} value={invoiceNumber} />
                    <Field px={0} title={t('invoiceDate')} value={<DateTime datetime={invoiceDate} format="P" />} />
                </FieldList>
            </Box>
        </Stack>
    </Box>
}

function CreateDeviceContent({ isSaving, onSave, onClose }) {

    const [createDeviceForm, setCreateDeviceForm] = useState(getEmptyDevice());
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const { t } = useTranslation();
    const steps = [t('device'), t('info'), t('invoice'), t('confirmation')];
    const errors = useMemo(() => getErrors(activeStep, createDeviceForm), [activeStep, createDeviceForm]);
    const hasErrors = errors !== null;
    const isMobile = useIsMobile();
    const [plans, setPlans] = useState([]);

    const isStepSkipped = step => skipped.has(step);
    const isStepFinal = activeStep === steps.length - 1;
    const canGoNextStep = (activeStep === 0 && !hasError(errors, "serialNumber")) || (activeStep === 1 && !hasError(errors, "plan") && !hasError(errors, "name")) || (activeStep === 2 && !hasError(errors, "invoiceNumber") && !hasError(errors, "invoiceDate"));

    const handleNext = () => {

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const handleOnChange = value => setCreateDeviceForm(value);

    return <>
        <DialogTitle>
            <Trans>registerDevice</Trans>
        </DialogTitle>
        <DialogContent>
            <Box display="grid" gridTemplateRows="1fr auto" height="100%">
                <Box minHeight={200} display="flex" width="100%" justifyContent="center" alignItems={activeStep === 3 ? "center" : "initial" }  >
                    {activeStep === 0 && <DeviceStep device={createDeviceForm} errors={errors} onChange={handleOnChange} />}
                    {activeStep === 1 && <InfoStep device={createDeviceForm} onUpdatePlans={plans => setPlans(plans)} errors={errors} onChange={handleOnChange} />}
                    {activeStep === 2 && <InvoiceStep device={createDeviceForm} errors={errors} onChange={handleOnChange} />}
                    {activeStep === 3 && <ConfirmationStep device={createDeviceForm} plans={plans} />}
                </Box>
                <Stepper activeStep={activeStep} alternativeLabel={isMobile} sx={{ mt : 2 }}>
                    {steps.map((label, index) => {

                        const stepProps = {};
                        const labelProps = {};

                        if (isStepSkipped(index)) stepProps.completed = false;
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleBack} disabled={activeStep === 0 || isSaving} sx={{ mr: 'auto' }} color="primary">
                <Trans>previous</Trans>
            </Button>
            <Button onClick={onClose} disabled={isSaving} color="secondary">
                <Trans>cancel</Trans>
            </Button>
            {isStepFinal ?
                <ButtonWithProgress
                    onClick={() => onSave(createDeviceForm)}
                    color="primary"
                    loading={isSaving}
                    disabled={hasErrors}>
                    <Trans>register</Trans>
                </ButtonWithProgress> :
                <Button onClick={handleNext} disabled={!canGoNextStep} color="primary">
                    <Trans>next</Trans>
                </Button>}
        </DialogActions>
    </>
}

export default function CreateDeviceDialog({ userId, open, onSaved, onClose }) {

    const { saveApi, isSaving } = useSaveApi();

    const handleSave = async (createDeviceForm) => {

        const res = await saveApi(registerDevice(userId, {...createDeviceForm, planId : Boolean(createDeviceForm.renewalDate) ? 0 : createDeviceForm.planId }));
        if (res === true) {
            onSaved && onSaved();
            onClose && onClose();
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <CreateDeviceContent isSaving={isSaving} onSave={handleSave} onClose={onClose} />
        </Dialog>
    );
}