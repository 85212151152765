import { authRequest } from "./utils";

const apiBaseUrl = "";

export function getEvents() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/events`,
    });
}

export function getEvent(userId, id) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/events/${id}?userId=${userId}`,
    });
}

export function getEventsPaged(userId, params) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/events/paged?userId=${userId}`,
        params
    });
}

export const getEventsPagedUrl = `${apiBaseUrl}/api/events/paged`
