import { Icon } from "@iconify/react";
import { Box, Button, Chip, DialogActions, DialogContent, DialogTitle, Divider, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import CenteredContent from "@nvapps/common/components/ui/CenteredContent";
import CloseIconButton from "@nvapps/common/components/ui/CloseIconButton";
import Dialog from '@nvapps/common/components/ui/Dialog';
import { Field, FieldList } from "@nvapps/common/components/ui/Fields";
import Label from "@nvapps/common/components/ui/Label";
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import { PhoneNumber } from "@nvapps/common/components/ui/PhoneInput";
import SmsInfo from "@nvapps/common/components/ui/SmsInfo";
import useDialogOpener from "@nvapps/common/hooks";
import BoxInfo from '@nvtracker/common/components/ui/BoxInfo';
import { DevicesStatus, canRenewDevice } from "@nvtracker/common/devices";
import { PositionCommunications, getStatusColorOfContract, getStatusOfPositionCommunication } from "@nvtracker/common/helpers";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import BlinkIcon from "../../components/Ui/BlinkIcon";
import DateTime from "../../components/Ui/DateTime";
import DeviceTypeLabel from "../../components/Ui/DeviceTypeLabel";
import { GsmState, GsmStateButton } from "../../components/Ui/GsmState";
import { useDeviceExtraInfo } from "../../store/devices";
import { usePlanById } from "../../store/plans";
import { useUserAdditionalContacts } from "../../store/users";
import RenewalDeviceDialog from './RenewalDeviceDialog';

function ContentDevice({ device, showRenewButton, showGsmActionsButton, onRenewed, onChangeGsmState, onClose }) {

    const { t } = useTranslation();
    const { id, name, brand, model, gsmNumber, gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername, serialNumber, renewalDate, planId, equipmentVersion, deviceTypeId, status, hasPendingPurchase, smsCredits, smsLimit, smsDate, position, lastResult } = device || {};
    const [plan] = usePlanById(planId);
    const brandModel = [brand, model].filter(e => (e || '').trim().length > 0).join(' ');
    const renewDialog = useDialogOpener({ user: null, device: null });
    const canShowRenewButton = showRenewButton && canRenewDevice(device);
    const [extraInfo, fetchingExtraInfo] = useDeviceExtraInfo(id)

    const handleRenewSuccess = ({ renewalDate, planId }) => onRenewed && onRenewed({ renewalDate, planId });

    return <React.Fragment>
        <DialogTitle>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <BoxInfo
                    title={name || '-'}
                    subtitle={brandModel} />
                <Box>
                    <CloseIconButton title={t('close')} onClick={onClose} />
                </Box>
            </Stack>
        </DialogTitle>
        <DialogContent sx={{ position: 'relative' }}>
            <Divider>
                <Typography variant="h6" >
                    {t('information')}
                </Typography>
            </Divider>
            <FieldList mt={1}>
                <Field px={0} title={t('serialNumber')} value={serialNumber} />
                <Field px={0} title={t('gsmNumber')} value={<GsmState gsmNumber={gsmNumber} gsmNumberActive={gsmNumberActive} gsmNumberStateDate={gsmNumberStateDate} gsmNumberStateUsername={gsmNumberStateUsername} />} />
                <Field px={0} title={t('smsBalance')} value={<SmsInfo credits={smsCredits} limit={smsLimit} tooltip={<div>
                    <Trans>lastUpdate</Trans>
                    <br />
                    <DateTime datetime={smsDate} format="PPPpp" />
                </div>} />} />
                <Field px={0} title={t('equipmentVersion')} value={equipmentVersion || '-'} />
                <Field px={0} title={t('plan')} value={plan ? plan.name : '-'} />
                <Field px={0} title={t('renewalDate')} value={<Label variant="ghost" color={getStatusColorOfContract(renewalDate)}>
                    <Typography variant="subtitle2">
                        <DateTime datetime={renewalDate} format="PPP" />
                    </Typography>
                </Label>} />
                <Field px={0} title={t('type')} value={<DeviceTypeLabel deviceTypeId={deviceTypeId} />} />
                <Field px={0} title={t('status')} value={t(status === DevicesStatus.Active ? 'active' : 'inactive')} />
                {lastResult !== undefined &&
                    <Field px={0} title={t('lastResult')} value={lastResult ? <Label color={PositionCommunications[getStatusOfPositionCommunication(lastResult)].color}>
                        <Typography variant="subtitle2">
                            <DateTime datetime={lastResult} />
                        </Typography>
                    </Label> : '-'} />}
                {position !== undefined &&
                    <Field px={0} title={t('lastPosition')} value={position.stateEndTime ? <Label color={PositionCommunications[getStatusOfPositionCommunication(position.stateEndTime)].color}>
                        <Typography variant="subtitle2">
                            <DateTime datetime={position.stateEndTime} />
                        </Typography>
                    </Label> : '-'} />}
                <Field px={0} title={t('inputs')} value={<Box display="flex" flexDirection="row" gap={1}>{Boolean(fetchingExtraInfo) ? <Skeleton width={100} /> : Boolean(extraInfo) ? [extraInfo.hasInput1, extraInfo.hasInput2, extraInfo.hasInput3, extraInfo.hasInput4].map((value, index) => <Tooltip title={t(value === true ? 'hasInput' : 'doesntHaveInput')} key={index}><Box><Label color={value === true ? 'success' : 'error'}>
                    {index + 1}
                </Label></Box></Tooltip>) : '-'}</Box>} />
            </FieldList>
            <InstallationInfo extraInfo={extraInfo} />
        </DialogContent>
        <DialogActions>
            {hasPendingPurchase &&
                <Chip
                    size="medium"
                    icon={<BlinkIcon icon="ant-design:warning-outlined" height={20} width={20} />}
                    label={<Typography variant='subtitle2'>
                        <Trans>pendingPurchase</Trans>
                    </Typography>}
                    sx={{ px: 1 }}
                    color="warning"
                />
            }
            <Box sx={{ mr: 'auto' }}>
                {canShowRenewButton &&
                    <Button sx={{ marginRight: 'auto' }} onClick={() => renewDialog.handleOpenDialog({ device })} startIcon={<Icon icon="ant-design:calendar-outlined" width={24} height={24} />}>
                        {t('renewDevice')}
                    </Button>}
                {showGsmActionsButton && <GsmStateButton deviceId={id} gsmNumberActive={gsmNumberActive} onChangeGsmState={onChangeGsmState} />}
            </Box>
        </DialogActions>
        {canShowRenewButton &&
            <RenewalDeviceDialog open={renewDialog.open} device={renewDialog.props.device} onSuccess={handleRenewSuccess} onClose={renewDialog.handleCloseDialog} />}
    </React.Fragment>
}

function InstallationInfo({ extraInfo, headerVariant = 'h6' }) {

    const { licencePlate, brand, model, installationDate } = extraInfo || {};

    return <>
        <Divider>
            <Typography variant={headerVariant} >
                <Trans>installation</Trans>
            </Typography>
        </Divider>
        <FieldList mt={1}>
            <Field px={0} title={<Trans>licencePlate</Trans>} value={licencePlate || '-'} />
            <Field px={0} title={<Trans>brand</Trans>} value={brand || '-'} />
            <Field px={0} title={<Trans>model</Trans>} value={model || '-'} />
            <Field px={0} title={<Trans>installationDate</Trans>} value={<DateTime datetime={installationDate} />} />
        </FieldList>
    </>
}

export function ViewDeviceDialog({ open, device, showRenewButton = true, showGsmActionsButton = true, onRenewed, onChangeGsmState, onClose }) {

    return (
        <Dialog open={open} onClose={onClose}  >
            <ContentDevice device={device} showRenewButton={showRenewButton} showGsmActionsButton={showGsmActionsButton} onRenewed={onRenewed} onChangeGsmState={onChangeGsmState} onClose={onClose} />
        </Dialog>
    );
}

function ContentUserAndDevice({ user, device, showRenewButton, showGsmActionsButton, onClose, onRenewed, onChangeGsmState }) {

    const { t } = useTranslation();
    const { id, userId, name, brand, model, gsmNumber, gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername, serialNumber, equipmentVersion, renewalDate, planId, status, hasPendingPurchase, deviceTypeId, smsCredits, smsLimit, smsDate, position, lastResult } = device || {};
    const { userName, name: userRealName, phoneNumber: userPhoneNumber } = user || {};
    const renewDialog = useDialogOpener({ user: null, device: null });
    const [extraInfo, fetchingExtraInfo] = useDeviceExtraInfo(id);

    const [plan] = usePlanById(planId);
    const brandModel = [brand, model].filter(e => (e || '').trim().length > 0).join(' ');
    const [contacts, isLoadingContacts] = useUserAdditionalContacts(userId);

    const canShowRenewButton = showRenewButton && canRenewDevice(device);
    const handleRenewSuccess = ({ renewalDate, planId }) => onRenewed && onRenewed({ renewalDate, planId });

    return <React.Fragment>
        <DialogTitle>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <BoxInfo
                    title={userRealName || userName}
                    subtitle={userRealName ? userName : null}
                    subtitle2={userPhoneNumber ? <PhoneNumber value={userPhoneNumber} /> : null} />
                <Box>
                    <CloseIconButton title={t('close')} onClick={onClose} />
                </Box>
            </Stack>
        </DialogTitle>
        <DialogContent sx={{ position: 'relative' }}>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={4}
                pt={2}
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="space-between">
                <Box sx={{ minHeight: 200 }} display={'grid'} gridTemplateRows={'1fr auto'} width="100%" position="relative">
                    {(isLoadingContacts) && (<OverlapProgress progressProps={{ size: 75 }} />)}
                    <Box display={'grid'} gridTemplateRows={'auto 1fr'}>
                        <Divider>
                            <Typography variant="h5" >
                                {t('contacts')}
                            </Typography>
                        </Divider>
                        <FieldList mt={1} sx={{ overflow: 'auto', height: '100%', maxHeight: 300 }} mx={-1.5} px={1.5}>
                            {(contacts || []).length === 0 && <CenteredContent>
                                <Typography variant="subtitle1">{t('noContacts')}</Typography>
                            </CenteredContent>}
                            {(contacts || []).length > 0 && contacts.map((c, index) => <Field key={index} px={0} title={c.name} value={<PhoneNumber value={c.phoneNumber} />} />)}
                        </FieldList>
                    </Box>
                    <Box>
                        <InstallationInfo extraInfo={extraInfo} headerVariant={'h5'} />
                    </Box>
                </Box>
                <Box height={'100%'} width="100%" position="relative">
                    <Divider>
                        <Typography variant="h5" >
                            {t('device')}
                        </Typography>
                    </Divider>
                    <FieldList mt={1}>
                        <Field px={0} title={t('name')} value={name} />
                        <Field px={0} title={`${t('brand')}/${t('model')}`} value={brandModel || '-'} />
                        <Field px={0} title={t('serialNumber')} value={serialNumber} />
                        <Field px={0} title={t('gsmNumber')} value={<GsmState gsmNumber={gsmNumber} gsmNumberActive={gsmNumberActive} gsmNumberStateDate={gsmNumberStateDate} gsmNumberStateUsername={gsmNumberStateUsername} />} />
                        <Field px={0} title={t('smsBalance')} value={<SmsInfo credits={smsCredits} limit={smsLimit} date={smsDate} tooltip={<div>
                            <Trans>lastUpdate</Trans>
                            <br />
                            <DateTime datetime={smsDate} format="PPPpp" />
                        </div>} />} />
                        <Field px={0} title={t('equipmentVersion')} value={equipmentVersion || '-'} />
                        <Field px={0} title={t('plan')} value={plan ? plan.name : '-'} />
                        <Field px={0} title={t('renewalDate')} value={<Label variant="ghost" color={getStatusColorOfContract(renewalDate)}>
                            <Typography variant="subtitle2">
                                <DateTime datetime={renewalDate} format="PPP" />
                            </Typography>
                        </Label>} />
                        <Field px={0} title={t('type')} value={<DeviceTypeLabel deviceTypeId={deviceTypeId} />} />
                        <Field px={0} title={t('status')} value={t(status === DevicesStatus.Active ? 'active' : 'inactive')} />
                        {lastResult !== undefined &&
                            <Field px={0} title={t('lastResult')} value={lastResult ? <Label color={PositionCommunications[getStatusOfPositionCommunication(lastResult)].color}>
                                <Typography variant="subtitle2">
                                    <DateTime datetime={lastResult} />
                                </Typography>
                            </Label> : '-'} />}
                        {position !== undefined &&
                            <Field px={0} title={t('lastPosition')} value={position.stateEndTime ? <Label color={PositionCommunications[getStatusOfPositionCommunication(position.stateEndTime)].color}>
                                <Typography variant="subtitle2">
                                    <DateTime datetime={position.stateEndTime} />
                                </Typography>
                            </Label> : '-'} />}
                        <Field px={0} title={t('inputs')} value={<Box display="flex" flexDirection="row" gap={1}>{Boolean(fetchingExtraInfo) ? <Skeleton width={100} /> : Boolean(extraInfo) ? [extraInfo.hasInput1, extraInfo.hasInput2, extraInfo.hasInput3, extraInfo.hasInput4].map((value, index) => <Tooltip title={t(value === true ? 'hasInput' : 'doesntHaveInput')} key={index}><Box><Label color={value === true ? 'success' : 'error'}>
                            {index + 1}
                        </Label></Box></Tooltip>) : '-'}</Box>} />
                    </FieldList>
                </Box>
            </Stack>
        </DialogContent>
        <DialogActions>
            {hasPendingPurchase &&
                <Chip
                    size="medium"
                    icon={<BlinkIcon icon="ant-design:warning-outlined" height={20} width={20} />}
                    label={<Typography variant='subtitle2'>
                        <Trans>pendingPurchase</Trans>
                    </Typography>}
                    sx={{ px: 1 }}
                    color="warning"
                />
            }
            <Box variant="text" sx={{ mr: 'auto' }}>
                {canShowRenewButton &&
                    <Button onClick={() => renewDialog.handleOpenDialog({ user, device })} startIcon={<Icon icon="ant-design:calendar-outlined" width={24} height={24} />}>
                        {t('renewDevice')}
                    </Button>}
                {showGsmActionsButton &&
                    <GsmStateButton deviceId={id} gsmNumberActive={gsmNumberActive} onChangeGsmState={onChangeGsmState} />}
            </Box>
        </DialogActions>
        {canShowRenewButton &&
            <RenewalDeviceDialog open={renewDialog.open} user={renewDialog.props.user} device={renewDialog.props.device} onSuccess={handleRenewSuccess} onClose={renewDialog.handleCloseDialog} />}
    </React.Fragment>
}

export function ViewUserAndDeviceDialog({ open, user, device, showRenewButton = true, onRenewed, showGsmActionsButton = true, onChangeGsmState, onClose }) {

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" >
            <ContentUserAndDevice user={user} device={device} showRenewButton={showRenewButton} onClose={onClose} onRenewed={onRenewed} onChangeGsmState={onChangeGsmState} showGsmActionsButton={showGsmActionsButton} />
        </Dialog>
    );
}