
import { Icon } from '@iconify/react';
import { Box, Button, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Label from '@nvapps/common/components/ui/Label';
import { PhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import useDialogOpener from '@nvapps/common/hooks';
import { ContractStatusEnum, getStatusColorOfContract, getStatusOfContract } from '@nvtracker/common/helpers';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { removeDevicePlanAndRenewalDate } from '../../api/devices';
import { useCallApi } from '../../hooks';
import { ViewDeviceDialog } from '../../modals/devices/ViewDeviceDialog';
import { useDevices, useDevicesActions, useDevicesFilters, useDevicesStockExport } from '../../store/devicesStock';
import ConfirmPopover from '../Ui/ConfirmPopover';
import DateTime from '../Ui/DateTime';
import MoreMenu from '../Ui/MoreMenu';
import { useDevicesGridActions } from './DevicesLogic';

function ContractCell({ renewalDate, hasPendingPurchase }) {

    const { t } = useTranslation();
    const statusContract = getStatusOfContract(renewalDate);
    const translation = statusContract === ContractStatusEnum.EXPIRED ? 'expired' : 'expires';
    const text = renewalDate ? t(`dates.${translation}`, { date: new Date(renewalDate) }) : '-';

    return <Box display="flex" flexDirection="row" gap={1}>
        <Tooltip title={text}>
            <Box>
                <Label variant="ghost" color={getStatusColorOfContract(renewalDate)}>
                    <Typography variant="subtitle2">
                        <DateTime datetime={renewalDate} format="PPP" />
                    </Typography>
                </Label>
            </Box>
        </Tooltip>
    </Box>
}

function OptionsMenu({ row, onResetRenewalDate }) {

    const ref = useRef(null);
    const [resetConfirmOpen, setResetConfirmOpen] = useState(false);

    const handleResetRenewalDate = row => {
        onResetRenewalDate(row);
        setResetConfirmOpen(false);
    }

    return <Box ref={ref}>
        <MoreMenu>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setResetConfirmOpen(true)} >
                <ListItemIcon>
                    <Icon icon="material-symbols:device-reset" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={<Trans>reset</Trans>} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
        </MoreMenu>
        <ConfirmPopover text={<Trans>resetQuestion</Trans>} open={resetConfirmOpen} anchorEl={ref.current} onClose={() => setResetConfirmOpen(false)} onResolve={() => handleResetRenewalDate(row)} />
    </Box>;
}


export default function DevicesStock() {

    const [devices, loading] = useDevices();
    const { pageSize, sortModel, searchQuery, selectedRows, changePage, changePageSize, selectRows, onSearch, sort, fetchDevices, page } = useDevicesGridActions(useDevicesActions, useDevicesFilters);
    const { t } = useTranslation();
    const detailsDialog = useDialogOpener(null);
    const { callApi, isLoading } = useCallApi();


    const handleResetRenewalDate = async row => {

        const res = await callApi(removeDevicePlanAndRenewalDate(row.deviceId));
        if (res === true) fetchDevices();
    }


    /*const getDeviceRow = row =>
    ({
        id : row.id,
        userId: row.userId,
        name: row.name,
        brand: row.brand,
        model: row.model,
        gsmNumber: row.gsmNumber,
        serialNumber: row.serialNumber,
        renewalDate: row.renewalDate,
        planId: row.planId,
        equipmentVersion: row.equipmentVersion
    });*/

    const columns = [{
        field: 'serialNumber',
        headerName: t('device'),
        minWidth: 190,
        flex: 0.3,
        renderCell: ({ row: { serialNumber } }) => <Typography variant="subtitle2" noWrap>
            {serialNumber || <Trans>n_A</Trans>}
        </Typography>
    },
    {
        field: 'gsmNumber',
        headerName: t('gsmNumber'),
        flex: 0.3,
        minWidth: 160,
        renderCell: ({ row: { gsmNumber } }) => <PhoneNumber value={gsmNumber} />
    },
    {
        field: 'equipmentVersion',
        headerName: t('equipmentVersion'),
        minWidth: 220,
        renderCell: ({ row: { equipmentVersion } }) => equipmentVersion || <Trans>n_A</Trans>
    },
    {
        field: 'renewalDate',
        headerName: t('oldContract'),
        minWidth: 200,
        valueGetter: ({ row }) => row.renewalDate,
        renderCell: ({ row: { renewalDate, planName } }) => Boolean(renewalDate) === false && Boolean(planName) === false ? <Trans>n_A</Trans> :
            <Box display="flex" flexDirection="column" gap={0.5}>
                {Boolean(planName) === true &&
                    <Box display="flex" flexDirection="row" gap={0.5}>
                        <Typography variant="subtitle2" noWrap>
                            <Trans>plan</Trans>:
                        </Typography>
                        <Typography variant="body2" noWrap>
                            {planName}
                        </Typography>
                    </Box>}
                {Boolean(renewalDate) && <ContractCell renewalDate={renewalDate} />}
            </Box>
    },
    {
        field: 'options',
        sortable: false,
        renderHeader: _ => <div></div>,
        align: 'center',
        width: 70,
        renderCell: ({ row }) => Boolean(row.renewalDate) ? <OptionsMenu row={row} onResetRenewalDate={handleResetRenewalDate} /> : '-'
    }

   /* {
        field: 'Options',
        renderHeader: () => <div></div>,
        sortable: false,
        renderCell: ({ row }) => <MoreMenu>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={() => detailsDialog.handleOpenDialog(getDeviceRow(row))} >
                <ListItemIcon>
                    <Icon icon="eva:search-outline" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={t('view')} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
        </MoreMenu>,
        align: 'right',
        width: 70
    }*/]

    const [exportList, isExporting] = useDevicesStockExport();

    return <>
        <TableToolbar>
            <SearchBox
                value={searchQuery}
                onChange={ev => onSearch(ev.target.value)}
                placeholder={`${t('search')}...`} />
            <Button
                sx={{ ml: 'auto' }}
                variant="outlined"
                onClick={() => fetchDevices()}
                startIcon={<Icon icon="eva:refresh-fill" />}>
                <Trans>refresh</Trans>
            </Button>
            <MoreMenu sx={{ ml: 1 }}>
                <MenuItem sx={{ color: 'text.secondary' }} onClick={() => exportList(searchQuery, sortModel)} >
                    <ListItemIcon>
                        <Icon icon="ic:round-print" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary={t('export')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </MoreMenu>
        </TableToolbar>
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGrid
                rows={devices}
                columns={columns}
                getRowId={d => d.serialNumber}
                pageSize={pageSize}
                page={page}
                rowsPerPageOptions={[5, 10, 25]}
                loading={loading || isLoading || isExporting}
                sortModel={sortModel}
                selectionModel={selectedRows}
                density="comfortable"
                disableColumnMenu
                disableSelectionOnClick
                hideFooterSelectedRowCount
                pagination
                onSortModelChange={sort}
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                onSelectionModelChange={selectRows}
            />
        </Box>
        <ViewDeviceDialog open={detailsDialog.open} showRenewButton={false} device={detailsDialog.props} onClose={detailsDialog.handleCloseDialog} />
    </>
}

