import { Icon } from '@iconify/react';
import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import useDialogOpener, { useDebounce, useSessionStorage } from '@nvapps/common/hooks';
import { getFormatedDate } from '@nvapps/common/utils/dateUtils';
import { applyFilters } from '@nvtracker/common/utils';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceDetailsDialog from '../../../../modals/invoices/InvoiceDetailsDialog';
import { useInvoices } from '../../../../store/contexts/userInvoices';
import { useProfileLanguage } from '../../../../store/user';
import DateTime from '../../../Ui/DateTime';
import { useUserId } from '../../../../store/contexts/userDevices';

function Toolbar({ searchQuery, onSearch }) {

    const { t } = useTranslation();

    return (
        <TableToolbar>
            <SearchBox
                value={searchQuery}
                placeholder={`${t('search')}...`}
                onChange={ev => onSearch(ev.target.value)} />
        </TableToolbar>
    );
}

export default function TabInvoices() {

    const [list, isLoading] = useInvoices();
    const [filters, setFilters] = useSessionStorage('usersTabInvoicesFilters', { text : '', sortModel : [{ field : 'date', sort : 'desc' }]});
    const [searchQuery, setSearchQuery] = useState(filters.text);
    const invoiceDialog = useDialogOpener({ invoice : {} });
    const { t } = useTranslation();
    const language = useProfileLanguage();
    const userId = useUserId();

    useDebounce(() => {
        setFilters(state => ({...state, text: searchQuery }));
    }, 200, [searchQuery])

    const invoicesFiltered = useMemo(() => {
        return applyFilters(list, ['id', 'number', 'deviceSerialNumber', { key : 'date', formatter : (key, r) => getFormatedDate(r.date, 'dd MMMM yyyy', language) }, { key : 'creationDate', formatter : (key, r) => getFormatedDate(r.creationDate, 'dd MMMM yyyy', language) }], filters.text);
    }, [list, filters.text, language]);

    const columns =
        [{
            field: 'id',
            headerName: t('id'),
            width: 150,
            renderCell: ({ row: { id } }) => <Typography ml={1} variant="subtitle2" noWrap>
                {id}
            </Typography>,
        },
        {
            field: 'number',
            headerName: t('number'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row }) => row.number,
        },
        {
            field: 'date',
            headerName: t('date'),
            width: 200,
            renderCell: ({ row: { date } }) => <DateTime datetime={date} format='dd MMMM yyyy' />,
        },
        {
            field: 'deviceSerialNumber',
            headerName: t('device'),
            flex: 0.2,
            minWidth: 150,
            renderCell: ({ row }) => row.deviceSerialNumber || '-'
        },
        {
            field: 'creationDate',
            headerName: t('creationDate'),
            width: 200,
            renderCell: ({ row: { creationDate } }) => <DateTime datetime={creationDate} format='dd MMMM yyyy' />,
        },
        {
            field: 'options',
            sortable: false,
            renderHeader: _ => <div></div>,
            renderCell: ({ row }) => <Tooltip title={t('seeDetails')} placement="top">
                <IconButton>
                    <Icon icon="eva:search-outline" width={20} height={20} />
                </IconButton>
            </Tooltip>,
            align: 'right',
            width: 70
        }];

    return (
        <Card>
            <Toolbar searchQuery={searchQuery} onSearch={text => setSearchQuery(text)} />
            <Box sx={{ height: 600 }}>
                <DataGrid
                    columns={columns}
                    rows={invoicesFiltered}
                    loading={isLoading}
                    density="comfortable"
                    pagination
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    onRowClick={({ row }) => invoiceDialog.handleOpenDialog({ invoice : row })}
                    sortModel={filters.sortModel}
                    onSortModelChange={sortModel => setFilters({...filters, sortModel})}
                    pageSize={15} />
            </Box>
            <InvoiceDetailsDialog userId={userId} invoice={invoiceDialog.props.invoice} open={invoiceDialog.open} onClose={invoiceDialog.handleCloseDialog} />
        </Card>);
}