import DatePicker from '@mui/lab/DatePicker';
import {
    Button, DialogActions, DialogContent, DialogTitle,
    Grid,
    MenuItem,
    Stack, TextField
} from "@mui/material";
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import Dialog from "@nvapps/common/components/ui/Dialog";
import { getErrorMessage, hasError } from "@nvtracker/common/utils";
import { isValid } from 'date-fns';
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createInvoice } from '../../api/invoices';
import { useSaveApi } from "../../hooks";

function getErrors(invoice) {

    const err = [];
    if (!invoice) return null;

    if ((invoice.deviceId || '').trim().length === 0) err.push({ id: "deviceId", message: "requiredField" });
    if ((invoice.number || '').trim().length === 0) err.push({ id: "number", message: "requiredField" });
    if (!invoice.date) err.push({ id: "date", message: "invalidField" });

    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Content({ purchaseId, devices, onClose }) {

    const [invoice, setInvoice] = useState({ deviceId: '', number: '', obs: '', date: null })
    const errors = useMemo(() => getErrors(invoice), [invoice]);
    const { t } = useTranslation();

    const { saveApi, isSaving } = useSaveApi();

    const handleSave = async () => {
        const res = await saveApi(createInvoice({...invoice, purchaseId }));
        if (res === true) onClose && onClose();
    }

    const updateField = (ev) => {
        setInvoice(state => ({
            ...state,
            [ev.target.name]: ev.target.value
        }))
    }

    const updateInvoiceDate = (date) => {
        setInvoice(state => ({
            ...state,
            date: (date && isValid(date) && date.toISOString()) || ''
        }))
    }

    function getMessage(key, defaultValue) {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : defaultValue;
    }

    return <>
        <DialogContent>
            <Grid container spacing={3} pt={2} >
                <Grid item xs={12}>
                    <TextField
                        required
                        select
                        autoFocus
                        label={<Trans>device</Trans>}
                        value={invoice.deviceId}
                        name="deviceId"
                        error={hasError(errors, "deviceId")}
                        helperText={getMessage("deviceId")}
                        fullWidth
                        onChange={updateField}>
                        {(devices || []).map(t => (
                            <MenuItem key={t.id} value={t.id}>
                                {t.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label={t('invoice')}
                        value={invoice.number}
                        name="number"
                        error={hasError(errors, "number")}
                        helperText={getMessage("number")}
                        onChange={updateField}
                        fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        inputVariant="filled"
                        margin="dense"
                        clearable
                        label={t('date')}
                        format="yyyy-MM-dd"
                        value={invoice.date}
                        onChange={updateInvoiceDate}
                        renderInput={(params) => <TextField {...params} fullWidth required error={hasError(errors, "invoiceDate")}
                            helperText={getMessage("invoiceDate")} />} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('obs')}
                        multiline
                        rows={2}
                        value={invoice.obs}
                        name="obs"
                        onChange={updateField}
                        fullWidth />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={isSaving} color="secondary">
                <Trans>cancel</Trans>
            </Button>
            <ButtonWithProgress
                onClick={handleSave}
                disabled={isSaving}
                color="primary"
                loading={isSaving}>
                <Trans>save</Trans>
            </ButtonWithProgress>
        </DialogActions>
    </>
}

export default function CreateInvoiceDialog({ open, purchaseId, devices, onClose }) {

    return (
        <Dialog
            open={open}
            onClose={onClose} >
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Trans>createInvoice</Trans>
                </Stack>
            </DialogTitle>
            <Content purchaseId={purchaseId} devices={devices} onClose={onClose} />
        </Dialog>
    );
}