import { Box, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import noImage from '../../assets/no-image-icon.png';
import { getAuthenticatedImage } from "../../utils";

const ImgContainerStyle = styled(Box)(({ theme }) => ({
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'center',
    position : 'relative',
    width : '100%',
    height : '100%'
}));

const CircularProgressStyle = styled(CircularProgress)(({ theme }) => ({
    position : 'absolute'
}));

const ImgStyle = styled('img')(({ theme }) => ({
    userSelect : 'none',
    maxWidth : '100%',
    maxHeight : '100%',
    overflow : 'hidden',
    visibility : 'visible',
    opacity : 1,

    '&.hidden':
    {
        visibility : 'hidden',
        opacity : 0
    }
}));

function useImgLoading(src, token)
{
    const [isLoading, setIsLoading] = useState(false)
    const [image, setImage] = useState();
    const handleOnLoad = () => setIsLoading(false);
    const handleOnError = () =>
    {
        setIsLoading(false);
        setImage(noImage);
    }

    useEffect(() => {

        setIsLoading(true);
        if (token) getAuthenticatedImage(src, token).then(res => setImage(res || noImage));
        else setImage(src);
    }, [src]);

    return { image, isLoading, handleOnLoad, handleOnError };
}

export function Img({ src, alt = '', token })
{
    const { image, isLoading, handleOnLoad, handleOnError } = useImgLoading(src, token);
    const imageCss = isLoading ? 'hidden' : '';

    return <ImgContainerStyle>
        {isLoading && <CircularProgressStyle size={50}  />}
        <ImgStyle alt={name} src={image} onLoad={handleOnLoad} onError={handleOnError} className={imageCss}/>
    </ImgContainerStyle> ;
}