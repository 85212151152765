import { getJwtTokenCookie } from '@nvapps/common/utils';
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { axiosInstance } from '../api/utils';
import { startMqttClient, stopMqttClient } from '../mqtt';
import authentication, { loginSuccess, logout } from './authentication';
import devicesInUse from './devicesInUse';
import devicesSuspended from './devicesSuspended';
import devicesStock from './devicesStock';
import history from './history';
import apiMiddleware from './middlewares/apiMiddleware';
import debounceMiddleware from './middlewares/debounce';
import search from './search';
import user from './user';
import users from './users';
import deviceTypes from './deviceTypes';
import purchases from './purchasesPage';

const routerMiddleware = store => next => action => {
    if (action.meta && action.meta.routeTo) {
        history.push(action.meta.routeTo);
    }
    next(action);
}

const appReducer = combineReducers({ authentication, user, users, search, devicesInUse, devicesSuspended, devicesStock, deviceTypes, purchases });

const rootReducer = (state, action) => {
    if (action.type === logout.toString()) state = undefined;
    return appReducer(state, action);
}

const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware(), debounceMiddleware, apiMiddleware, routerMiddleware]
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);

const jwtToken = getJwtTokenCookie();
if (jwtToken) store.dispatch(loginSuccess({ token : jwtToken}));

let currentUser;
export function refreshMqttClient() {
    const state = store.getState();

    if (currentUser !== state.authentication) {
        currentUser = state.authentication;
        stopMqttClient();
        if (!currentUser.isAuthenticated) return;
        const token = getJwtTokenCookie();
        startMqttClient(token);
    }
}

store.subscribe(refreshMqttClient);
refreshMqttClient();

export default store;