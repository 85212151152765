import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import * as invoicesApi from "../../api/invoices";
import { registerSubscription, unregisterSubscription } from "../../mqtt";
import { useFetchV2 } from "@nvapps/common/hooks";

const initialState = {
    currentState: {
        isLoading: false,
        userId: null,
        list: []
    }
};

const InvoicesContext = React.createContext(initialState);

export function InvoicesProvider({ children, userId }) {
    const [currentState, setCurrentState] = useState({ ...initialState.currentState, userId });
    const isSubscribed = useRef(false);

    // Due to change user without leaving the user screen (ex. search bar)
    useEffect(() => setCurrentState(state => ({ ...state, userId })), [userId]);

    useEffect(() => {
        const subTopic = `users/${userId}/nvtracker/invoices/#`;
        const setInvoices = (setFn) => setCurrentState(state => ({
            ...state,
            list: setFn(state.list).slice()
        }));
        const setIsLoading = (isLoading) => setCurrentState(state => ({ ...state, isLoading }));
        const mqttSub = function (topic, data) {
            const [, id] = topic.match(/.+\/(\w+)$/);
            switch (data.action) {
                case "add":
                    return setInvoices(invoices => [...invoices, data.payload]);
                case "update":
                    return setInvoices(invoices => invoices.map(d => d.id === data.payload.id ? data.payload : d));
                case "delete":
                    return setInvoices(invoices => invoices.filter(d => d.id !== id));
                default:
                    throw new Error("Invalid action");
            }
        };

        isSubscribed.current = true;
        setIsLoading(true);
        invoicesApi.getInvoices(userId).then(res => {
            if (isSubscribed.current) {
                setInvoices(_ => res.data);
                setIsLoading(false);
                registerSubscription(subTopic, mqttSub);
            }
        });

        return () => {
            isSubscribed.current = false;
            unregisterSubscription(subTopic, mqttSub);
        }
    }, [userId]);

    return (
        <InvoicesContext.Provider value={{ currentState }}>
            {children}
        </InvoicesContext.Provider>
    )
}

export function useInvoices() {
    const { currentState } = useContext(InvoicesContext);
    return [currentState.list, currentState.isLoading];
}

export function useInvoicesUserId() {
    const { currentState } = useContext(InvoicesContext);
    return useMemo(() => currentState.userId, [currentState.userId]);
}

export function useInvoicesOfUser(userId) {
    return useFetchV2(() => Boolean(userId) ? invoicesApi.getInvoices(userId) : Promise.resolve([]), [userId]);
}


