import { useFetchV2 } from '@nvapps/common/hooks';
import { downloadBlob, urlEncode } from '@nvapps/common/utils';
import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { appIdentifier } from '../../package.json';
import { createAdditionalContact, deleteAdditionalContact, exportUsers, getAdditionalContact, getAdditionalContacts, getBillingInformation, getProfile, getUserPhoto, getUsersPagedUrl, registerUser, updateAdditionalContact, updateBillingInformation, updateProfile } from "../api/users";


const users = createSlice({
    name: 'users',
    initialState: {
        list :
        {
            page : 0,
            pageItems : [],
            pageSize : 10,
            rowCount : 0,
            pageCount : 0
        },
        filters :
        {
            searchText : '',
            sortBy : 'Name',
            sortDesc : false,
            page : 0,
            pageSize : 10,
        },
        loading: false,
        hasError: true
    },
    reducers: {
        fetchStart(state) {
            state.loading = true;
            state.hasError = false;
        },
        fetchFailed(state) {
            state.hasError = true;
            state.loading = false;
        },
        fetchSuccess(state, { payload }) {
            state.list = payload;
            state.loading = false;
        },
        updateFilters(state, { payload }) {
            state.filters = { ...state.filters, ...payload };
        },
    }
});

export function fetchUsers()
{
    const actions = users.actions;

    return (dispatch, getState) =>
    {
        const { users: { filters } } = getState();

        dispatch(
        {
            type: actions.fetchStart.type,
            meta:
            {
                debounce: 200,
                apiRequest:
                {
                    method: "GET",
                    url: getUsersPagedUrl,
                    successActionType: actions.fetchSuccess.type,
                    errorActionType : actions.fetchFailed.type,
                    params: {...filters, searchText : urlEncode(filters.searchText), apps : [appIdentifier]}
                },
                cancelApiRequest:
                {
                    startedBy: actions.fetchStart.type
                }
            }
        });
    };
}

export function useUsers() {
    const { pageItems, page, pageSize, rowCount, pageCount } = useSelector(state => state.users.list);
    const loading = useSelector(state => state.users.loading);
    const filters = useUsersFilters();
    const { fetchUsers } = useUsersActions();

    useEffect(() =>
    {
        fetchUsers();
    }, [filters, fetchUsers]);

    return { users : pageItems, page, pageSize, rowCount, pageCount, loading };
}

export function useUsersLoading() {

    const state = useSelector(state => state.users.loading);
    return state;
}

export function useUsersFilters() {

    const state = useSelector(state => state.users.filters, shallowEqual);
    return state;
}

export function useUsersActions() {
    const dispatch = useDispatch();
    const actions = users.actions;

    return useMemo(() => ({
        fetchUsers: () => dispatch(fetchUsers()),
        updateFilters : value => dispatch(actions.updateFilters(value))
    }), [dispatch, actions]);
}

export const getDefaultUser = () =>
({
    id: null,
    email: '',
    profile:
    {
        name: '',
        country: 'pt_PT',
        language: 'pt_PT',
        newsletter: false,
    },
    billingInfo:
    {
        fullName: '',
        company: '',
        email: '',
        phoneNumber: '',
        postalCode: '',
        address: ``,
        city: '',
        country: 'pt_PT',
    },
    contacts: []
})


export function useUser(id) {

    const [profile, isLoadingUser, error] = useUserProfile(id);
    const [billingInfo, isLoadingBilling] = useUserBillingInfo(id);
    const [additionalContacts, isLoadingContacts] = useUserAdditionalContacts(id);
    const isLoading = isLoadingUser || isLoadingBilling || isLoadingContacts;

    const defaultUser = getDefaultUser();
    const user = useMemo(() => (
    {
        profile : profile || defaultUser.profile,
        billingInfo : billingInfo || defaultUser.billingInfo,
        contacts : additionalContacts || []
    }), [profile, billingInfo, additionalContacts, defaultUser.profile, defaultUser.billingInfo]);

    return [user, isLoading, error];
}

export function useUserActions(userId) {
    return {
        updateProfile: profile => updateProfile(userId, profile),
        updateBillingInformation: billingInfo => updateBillingInformation(userId, billingInfo),
        saveAdditionalContact: (contactId, contact) =>
        {
            if (contactId) return updateAdditionalContact(userId, contactId, contact);
            else return createAdditionalContact(userId, contact);
        },
        deleteAdditionalContact : contactId =>
        {
            if (contactId) return deleteAdditionalContact(userId, contactId);
        },

        registerUser : user => registerUser({...user, appToken : appIdentifier}).then(res => res.data)
    };
}

export function useUserProfile(id) {
    return useFetchV2(() => getProfile(id), [id]);
}

export function useUserBillingInfo(id) {
    return useFetchV2(() => getBillingInformation(id), [id]);
}

export function useUserAdditionalContacts(userId) {
    return useFetchV2(() => userId ? getAdditionalContacts(userId) : Promise.resolve([]), [userId]);
}

export function useUserAdditionalContact(userId, contactId) {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        if (!userId || !contactId)
        {
            setData(null);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        getAdditionalContact(userId, contactId).then(res => {
            if (res.status === 200 || res.status === 201)
                setData(res.data);
            else if (res.status === 204)
                setData(null);
            else
                setError(res.data);
            setIsLoading(false);
        });
    }, [userId, contactId]);

    return [data, isLoading, error];
}

export function useUserPhoto(userId, src)
{
    return useFetchV2(() => getUserPhoto(userId, src).then(res => ({ status : 200, data : res })), [userId, src]);
}

export function useUsersExport()
{
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const exportList = async ({ searchText, sortBy, sortDesc }) =>
    {
        setIsLoading(true);
        return exportUsers({ searchText, sortBy, sortDesc }).then(res =>
        {
            const fileName = `${t('clients')}.xlsx`
            downloadBlob(new File([res.data], fileName), fileName);
            setIsLoading(false);
        }).catch(res => setIsLoading(false));
    }

    return [exportList, isLoading];
}

export default users.reducer;