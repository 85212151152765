import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
import { IconButton, Menu, Tooltip } from "@mui/material";
import { useRef, useState } from "react";

export default function MoreMenu({ children, toolTip = 'Opções', width = 200, closeOnClick = true, ...props })
{
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = e =>
    {
        e.stopPropagation();
        setIsOpen(true)
    }

    return (
    <>
        <Tooltip title={toolTip} placement="top">
            <IconButton ref={ref} onClick={handleClick} {...props}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>
        </Tooltip>
        <Menu
            open={isOpen}
            anchorEl={ref.current}
            onClick={() => closeOnClick ? setIsOpen(false) : null}
            onClose={() => setIsOpen(false)}
            PaperProps={{
                sx: { width , maxWidth: '100%' }
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            {children}
        </Menu>
    </>);
}
