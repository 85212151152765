import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as documentsApi from "../../api/documents";

const initialState = {
    currentState: {
        isLoading: false,
        path: null,
        list: []
    },
    actions: {
        addFile(data, parent) { },
        updateFileName(id, name) { },
        deleteFile(id) { },

        createFolder(name, parent) { },
        updateFolder(id, name, parent) { },

        deleteDocument(id) { },

        refresh() { }
    }
};

const DocumentsContext = React.createContext(initialState);

export function DocumentsProvider({ children, path }) {
    const [currentState, setCurrentState] = useState({ ...initialState.currentState, path });

    const fetchItems = useCallback(() => {
        const setFiles = (setFn) => setCurrentState(state => ({ ...state, list: setFn(state.list) }));
        const setIsLoading = (isLoading) => setCurrentState(state => ({ ...state, isLoading }));

        setIsLoading(true);
        documentsApi.getDocuments(path).then(res => {
            setFiles(_ => res.data);
            setIsLoading(false);
        }).catch(res => setIsLoading(false));
    }, [path]);

    const actions = useMemo(() => {
        return {
            addFile(file, parent) {
                return documentsApi.addFile(path, file, parent);
            },

            createFolder(name, parent) {
                return documentsApi.createFolder(path, name, parent);
            },

            updateFolder(id, name, parent) {
                return documentsApi.updateFolder(path, id, name, parent);
            },

            deleteDocument(id) {
                return documentsApi.deleteDocument(path, id);
            },

            refresh() {
                fetchItems();
            }
        }
    }, [path, fetchItems]);

    useEffect(() => {
        fetchItems();
    }, [path, fetchItems]);

    return (
        <DocumentsContext.Provider value={{ currentState, actions }}>
            {children}
        </DocumentsContext.Provider>
    )
}


export function useDocumentsActions() {
    const { actions } = useContext(DocumentsContext);
    return actions;
}

export function useDocuments() {
    const { currentState } = useContext(DocumentsContext);
    const list = useMemo(() => {
        return currentState.list.map(e=> ({
            ...e,
            url: documentsApi.getFileUrl(e.url)
        }));
    }, [currentState.list])


    return [list, currentState.isLoading];
}