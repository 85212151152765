import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import { Box, Button, CircularProgress, ClickAwayListener, Collapse, Divider, IconButton, Input, InputAdornment, List, ListItem, ListItemButton, ListItemText, Slide, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import CenteredContent from '@nvapps/common/components/ui/CenteredContent';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import { PhoneNumber } from "@nvapps/common/components/ui/PhoneInput";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useHasSearchResults, useIsSearchFetching, useSearchActions, useSearchDevices, useSearchFilters, useSearchUsers } from '../store/search';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 86;

const SearchbarWrapperStyle = styled('div')(({ theme }) => ({
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    display: 'block',
    position: 'absolute',
    // alignItems: 'center',
    // minheight: APPBAR_MOBILE,
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    backgroundColor: `${alpha(theme.palette.background.default, 0.92)}`,
    [theme.breakpoints.up('md')]: {
        // minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 3),
        //  paddingTop : theme.spacing(2),
    }
}));

const SearchbarStyle = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: APPBAR_MOBILE,
    //    padding: theme.spacing(0, 3),

    [theme.breakpoints.up('md')]: {
        height: APPBAR_DESKTOP,
        //      padding: theme.spacing(0, 5)
    }
}));

function UsersSearch({ onSelect }) {

    const [users, fetching] = useSearchUsers();
    const { t } = useTranslation();

    const displayResults = () => {

        if ((users || []).length === 0) return <CenteredContent>
            <Typography variant="subtitle2">
                {t('noResults')}
            </Typography>
        </CenteredContent>;

        return users.map((e, index) =>
            <ListItem
                key={e.id}
                disablePadding>
                <ListItemButton
                    onClick={onSelect}
                    component={RouterLink}
                    to={`/app/clients/${e.id}/general`}>
                    <ListItemText
                        primary={e.name} component="div" primaryTypographyProps={{ variant: "subtitle2" }}
                        secondary={<>
                            {e.username}
                            {e.phoneNumber && <PhoneNumber value={e.phoneNumber} />}
                        </>}
                        secondaryTypographyProps={{ component : 'div' }}
                    />
                </ListItemButton>
            </ListItem>)
    }

    return <Stack width="100%">
        <Typography variant="h5">
            {t('clients')}
        </Typography>
        <List sx={{ height: 200, overflow: 'auto', mb: 1 }}>
            {fetching && <OverlapProgress progressProps={{ size: 75 }} />}
            {displayResults()}
        </List>
        <Divider />
        <Box pt={1} textAlign="right">
            <Typography variant="caption2">
                {t(users.length === 0 ? 'resultsFound_other' : 'resultsFound', { count :  users.length })}
            </Typography>
        </Box>
    </Stack>
}

function DevicesSearch({ onSelect }) {

    const [devices, fetching] = useSearchDevices();
    const { t } = useTranslation();

    const displayResults = () => {

        if ((devices || []).length === 0) return <CenteredContent>
            <Typography variant="subtitle2">
                {t('noResults')}
            </Typography>
        </CenteredContent>;

        return devices.map((e, index) =>
            <ListItem
                key={e.id}
                disablePadding>
                <ListItemButton
                    onClick={onSelect}
                    component={RouterLink}
                    to={`/app/clients/${e.userId}/devices/${e.id}`}>
                    <Box>
                        <Typography variant="subtitle2">
                            {e.name}
                            <Typography variant="caption" ml={1} color="text.secondary">{[e.brand, e.model].filter(e => (e || '').trim().length > 0).join(' ')}</Typography>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">{e.serialNumber}</Typography>
                        <Typography variant="body2" component="div" color="text.secondary">
                            <PhoneNumber value={e.gsmNumber} />
                        </Typography>
                    </Box>
                </ListItemButton>
            </ListItem>);
    }

    return <Stack width="100%">
        <Typography variant="h5">
            {t('devices')}
        </Typography>
        <List sx={{ height: 200, overflow: 'auto', mb: 1 }}>
            {fetching && <OverlapProgress progressProps={{ size: 75 }} />}
            {displayResults()}
        </List>
        <Divider />
        <Box pt={1} textAlign="right">
            <Typography variant="caption2">
                {t(devices.length === 0 ? 'resultsFound_other' : 'resultsFound', { count :  devices.length })}
            </Typography>
        </Box>
    </Stack>
}



export default function Searchbar() {
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();

    const { searchText } = useSearchFilters();
    const { updateFilters, clearSearch } = useSearchActions();

    const hasResults = useHasSearchResults();
    const isFetching = useIsSearchFetching();

    const resultsOpen = searchText.length > 2 || hasResults;

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseButton = () => {
        handleClose();
        clearSearch();
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                {!isOpen && <IconButton onClick={handleOpen}>
                    <Icon icon={searchFill} width={20} height={20} />
                </IconButton>}
                <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
                    <SearchbarWrapperStyle>
                        <SearchbarStyle>
                            <Input
                                autoFocus
                                fullWidth
                                disableUnderline
                                placeholder={`${t('search')}...`}
                                value={searchText}
                                onChange={ev => updateFilters({ searchText: ev.target.value })}
                                startAdornment={
                                    <InputAdornment position="start">
                                        {isFetching ? <CircularProgress size={20} sx={{ color: 'text.disabled' }} /> :
                                            <Box
                                                component={Icon}
                                                icon={searchFill}
                                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                            />}
                                    </InputAdornment>
                                }
                                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                            />
                            <Button variant="contained" onClick={handleCloseButton}>
                                {t('close')}
                            </Button>
                        </SearchbarStyle>
                        <Collapse in={resultsOpen} mountOnEnter unmountOnExit>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                color="initial"
                                spacing={2}
                                pb={2}
                                divider={<Divider />}>
                                <UsersSearch onSelect={handleClose} />
                                <DevicesSearch onSelect={handleClose} />
                            </Stack>
                        </Collapse>
                    </SearchbarWrapperStyle>
                </Slide>
            </div>
        </ClickAwayListener>
    );
}
