import { Box, TextField } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import StatusAlert from '@nvapps/common/components/ui/StatusAlert';
import FormPage from '@nvtracker/common/components/FormPage';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticationActions, useAuthenticationState } from '../store/authentication';

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { loginFailed, loginStarted } = useAuthenticationState();
    const { login } = useAuthenticationActions();
    const { t } = useTranslation();

    const handleLoginClick = (e) => {
        e.preventDefault();
        login({ username, password });
    }

    return (
        <FormPage>
            <Box component="form" p={3} sx={{ width: '100%' }}>
                <TextField
                    htmlFor="username"
                    required
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="standard"
                    label={t("email")}
                    name="username"
                    id="username"
                    autoComplete="username"
                    autoFocus
                    onChange={e => setUsername(e.target.value)}
                />
                <TextField
                    htmlFor="password"
                    required
                    fullWidth
                    variant="standard"
                    label={t("password")}
                    name="password"
                    type="password"
                    id="password"
                    sx={{ mb: 2 }}
                    autoComplete="current-password"
                    onChange={e => setPassword(e.target.value)}
                />
                <StatusAlert
                    sx={{ marginTop: 2 }}
                    visible={loginFailed}
                    variant="error"
                    message={t('errorLoginFailed')}>
                </StatusAlert>
                <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={2}>
                    <ButtonWithProgress
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={username.length === 0 || password.length === 0}
                        loading={loginStarted}
                        onClick={handleLoginClick}>
                        {t('login')}
                    </ButtonWithProgress>
                </Box>
            </Box>
        </FormPage>
    );
}