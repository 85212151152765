import { useDebounce } from "@nvapps/common/hooks";
import { useEffect, useRef, useState } from "react";
import { useUsersActions, useUsersExport, useUsersFilters } from "../../store/users";

export function useUsersGridActions()
{
    const [selectedRows, setSelectedRows] = useState([]);
    const { searchText, page, pageSize, sortBy, sortDesc } = useUsersFilters();
    const [searchQuery, setSearchQuery] = useState(searchText);
    const { updateFilters, fetchUsers } = useUsersActions();
    const [exportList, isExporting] = useUsersExport();

    const search = t => setSearchQuery(t);
    const sort = sortModel =>
    {
        if (sortModel && sortModel.length > 0) updateFilters({ sortBy : sortModel[0].field, sortDesc : sortModel[0].sort === 'desc' });
        else updateFilters({ sortBy : null, sortDesc : false });
    };
    const changePage = page => updateFilters({ page });
    const changePageSize = pageSize => updateFilters({ pageSize });
    const selectRows = rows => setSelectedRows(rows);
    const exportUsers = () => exportList({ searchText, sortBy, sortDesc });

    useDebounce(() => {
        updateFilters({ searchText: searchQuery, page : 0});
    }, 200, [searchQuery])

    useEffect(() => {

      return () => updateFilters({ page : 0});
    }, [updateFilters])

    return {
        page,
        pageSize,
        sortModel : sortBy ? [{ field : sortBy, sort : sortDesc ? 'desc' : 'asc' }] : [],
        searchQuery,
        selectedRows,

        changePage,
        changePageSize,
        selectRows,
        search,
        sort,
        fetchUsers,
        exportUsers,
        isExporting
    }
}

export function useActionMenu(userId)
{
    const anchorRef = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const deleteUser = action =>
    {
        if (action === true)
        {
            alert(`Delete user ${userId}`);
        }
    }

    const openConfirm = () => setConfirmOpen(true);
    const closeConfirm = () => setConfirmOpen(false);

    return { anchorRef, confirmOpen, openConfirm, closeConfirm, deleteUser };
}