import { useFetchV2 } from '@nvapps/common/hooks';
import { downloadBlob } from '@nvapps/common/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { registerSubscription, unregisterSubscription } from '../mqtt';
import { activateDeviceGsm, exportDevicesExpiring, getDeviceExtraInfo, getDevicesAvailable, getDevicesExpiring, renewDevice, suspendDeviceGsm, repairDeviceSerialNumber, replaceDeviceRenewalDate, finishDeviceInstallation } from '../api/devices';

export function useDevicesAvailable() {
    return useFetchV2(() => getDevicesAvailable(), []);
}

export function useDevicesActions() {

    return useMemo(() => ({
        renewDevice: (id, formData) => renewDevice(id, formData),
        suspendDeviceGsm: id => suspendDeviceGsm(id),
        activateDeviceGsm: id => activateDeviceGsm(id),
        repairDeviceSerialNumber: (id, password) => repairDeviceSerialNumber(id, password),
        replaceDeviceRenewalDate: (id, newDate, password) => replaceDeviceRenewalDate(id, newDate, password),
        finishDeviceInstallation : (id, formData) => finishDeviceInstallation(id, formData)
    }), []);
}

//TODO - verificar a actualização depois da renovação - talvez fazer refresh à lista depois de um update ao mqtt
export function useDevicesExpiring(daysMargin) {
    const [devices, setDevices] = useState([]);
    const [data, isLoading] = useFetchV2(() => getDevicesExpiring(daysMargin), [daysMargin]);
    const devicesIds = useMemo(() => Array.from(new Set((data || []).map(e => e.id))), [data]);

    useEffect(() => setDevices(data), [data]);

    useEffect(() => {

        if (devicesIds == null) return;

        const subTopic = `users/+/nvtracker/devices/`;
        const mqttSub = function (topic, data) {

            switch (data.action) {
                case "update":
                    return setDevices(devices => devices.map(d => d.id === data.payload.id ? { ...d, ...data.payload } : d));
                default:
                    throw new Error("Invalid action");
            }
        };

        devicesIds.forEach(deviceId => registerSubscription(subTopic + deviceId, mqttSub));

        return () => {
            devicesIds.forEach(deviceId => unregisterSubscription(subTopic + deviceId, mqttSub));
        }
    }, [devicesIds]);

    return [devices, isLoading];
}

export function useDevicesExpiringExport() {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const exportList = async daysMargin => {
        setIsLoading(true);
        return exportDevicesExpiring(daysMargin).then(res => {
            const fileName = `${t('expiringDevices')}.xlsx`
            downloadBlob(new File([res.data], fileName), fileName);
            setIsLoading(false);
        }).catch(res => setIsLoading(false));
    }

    return [exportList, isLoading];
}

export function useDeviceExtraInfo(deviceId) {
    return useFetchV2(() => Boolean(deviceId) ? getDeviceExtraInfo(deviceId) : Promise.resolve([]), [deviceId]);
}
