import { Icon } from '@iconify/react';
import { Box, Button, Card, CardHeader, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import CenteredContent from '@nvapps/common/components/ui/CenteredContent';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import PhoneInput, { isValidPhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import Dialog from '@nvapps/common/components/ui/Dialog';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmPopover from '../../../../components/Ui/ConfirmPopover';
import MoreMenu from '../../../../components/Ui/MoreMenu';
import { useDeleteApi, useSaveApi } from '../../../../hooks';
import { useUserActions, useUserAdditionalContact, useUserAdditionalContacts } from '../../../../store/users';

function getErrors(contact) {

    let err = [];
    if (!contact) return null;

    if ((contact.name || '').trim().length === 0) err.push({ id: "name", message: "requiredField" });
    if ((contact.phoneNumber || '').length < 2) err.push({ id: "phoneNumber", message: "requiredField" });
    else if ((contact.phoneNumber || '').length > 0 && !isValidPhoneNumber(contact.phoneNumber)) err.push({ id: "phoneNumber", message: "invalidPhoneNumber" });

    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Form({ contact, errors, onChange }) {

    const { t } = useTranslation();

    function getMessage(key) {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : '';
    }

    return (
        <Grid container spacing={3} pt={1} >
            <Grid item xs={12}>
                <TextField
                    required
                    label={t('name')}
                    autoFocus
                    fullWidth
                    value={contact.name}
                    error={hasError(errors, "name")}
                    inputProps={{ maxLength: 200 }}
                    helperText={getMessage("name")}
                    onChange={e => onChange({ ...contact, name: e.target.value })} />
            </Grid>
            <Grid item xs={12}>
                <PhoneInput
                    required
                    name="phoneNumber"
                    fullWidth
                    label={t('phoneNumber')}
                    value={contact.phoneNumber}
                    error={hasError(errors, "phoneNumber")}
                    helperText={getMessage("phoneNumber")}
                    onChange={e => onChange({ ...contact, phoneNumber: e.value })}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('obs')}
                    fullWidth
                    multiline
                    maxRows={4}
                    inputProps={{ maxLength: 200 }}
                    value={contact.observations}
                    onChange={e => onChange({ ...contact, observations: e.target.value })} />
            </Grid>
        </Grid>)
}

const getDefaultContact = () =>
({
    name: '',
    phoneNumber: '',
    observations: ''
});

function ContactDialog({ open, userId, contactId, onClose, onSave }) {

    const [data, isLoading] = useUserAdditionalContact(userId, contactId);
    const [contactData, setContactData] = useState(data || getDefaultContact());
    const { t } = useTranslation();
    const { saveAdditionalContact } = useUserActions(userId);
    const { saveApi, isSaving } = useSaveApi();

    const errors = useMemo(() => getErrors(contactData), [contactData]);
    const hasErrors = errors !== null;

    useEffect(() => setContactData(data || getDefaultContact()), [data]);

    const handleClose = () =>
    {
        setContactData(getDefaultContact());
        onClose && onClose();
    }

    const handleSave = async () => {

        const res = await saveApi(saveAdditionalContact(contactId, contactData));
        if (res === true) {
            onSave && onSave();
            handleClose();
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} >
            {(isLoading || isSaving) && (<OverlapProgress progressProps={{ size: 75 }} />)}
            <DialogTitle>{`${t(contactData.id ? 'edit' : 'add')} ${t('contact')}`}</DialogTitle>
            <DialogContent>
                <Form
                    contact={contactData}
                    errors={errors}
                    onChange={value => setContactData(state => ({ ...state, ...value }))} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">{t('close')}</Button>
                <Button onClick={handleSave} disabled={hasErrors} >{t('save')}</Button>
            </DialogActions>
        </Dialog>
    )
}

function ContactCardAction({ onEdit, onDelete }) {
    const ref = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { t } = useTranslation();

    const handleDelete = action => action === true && onDelete();

    return <Box ref={ref}>
        <ConfirmPopover text={t('deleteQuestion')} open={confirmOpen} anchorEl={ref.current} onClose={() => setConfirmOpen(false)} onResolve={handleDelete} />
        <MoreMenu>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={onEdit}>
                <ListItemIcon>
                    <Icon icon="eva:edit-fill" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={t('edit')} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setConfirmOpen(true)}>
                <ListItemIcon>
                    <Icon icon="eva:trash-2-fill" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={t('delete')} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
        </MoreMenu>
    </Box>;
}

const defaultDialogContactProps = { open: false, contactId: null };

export default function ContactsCard({ userId, onChange }) {
    const [dialogContactProps, setDialogContactProps] = useState(defaultDialogContactProps);
    const [data, isLoading,, reFetch] = useUserAdditionalContacts(userId);
    const { deleteApi, isDeleting } = useDeleteApi();
    const { deleteAdditionalContact } = useUserActions(userId);
    const { t } = useTranslation();

    const handleCloseDialog = () => setDialogContactProps(defaultDialogContactProps);
    const handleSaveDialog = () => reFetch();

    const handleAdd = () => setDialogContactProps({ open: true, contactId: null });
    const handleEdit = c => setDialogContactProps({ open: true, contactId: c.id });
    const handleDelete = async c => {
        const res = await deleteApi(deleteAdditionalContact(c.id))
        if (res === true) reFetch();
    }

    const contacts = (data || []);

    return (
        <Card sx={{ height : '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
            {(isLoading || isDeleting) && (<OverlapProgress progressProps={{ size: 75 }} />)}
            <CardHeader title={t('contacts')} action={<Tooltip title={t('addContact')} placement="top"><IconButton size="small" color="primary" onClick={handleAdd}><Icon icon="ant-design:plus-outlined" /></IconButton></Tooltip>} />
            <Box sx={{ p: 1 }} >
                {contacts.length === 0 && <CenteredContent>
                    <Typography variant="subtitle1">{t('noContacts')}</Typography>
                </CenteredContent>}
                {contacts.length > 0 &&
                <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                    {contacts.map((c, index) =>
                        <ListItem key={index}
                            secondaryAction={<ContactCardAction onDelete={() => handleDelete(c)} onEdit={() => handleEdit(c)} />}>
                            <ListItemText
                                primary={c.name}
                                secondary={c.phoneNumber}
                            />
                        </ListItem>)}
                </List>}
            </Box>
            <ContactDialog open={dialogContactProps.open} userId={userId} contactId={dialogContactProps.contactId} onClose={handleCloseDialog} onSave={handleSaveDialog} />
        </Card>);
}

