import { Icon } from '@iconify/react';
import { Box, Card, CardHeader, Grid, TextField } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import CountrySelect from '@nvapps/common/components/ui/CountrySelect';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import PhoneInput, { isValidPhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import { isEmptyField } from '@nvapps/common/utils';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSaveApi } from '../../../../hooks';
import { useProfileLanguage } from '../../../../store/user';
import { useUserActions, useUserBillingInfo } from '../../../../store/users';

function getErrors(billingInfo) {

    let err = [];
    if (!billingInfo) return null;

    if (!isValidPhoneNumber(billingInfo.phoneNumber)) err.push({ id: "phoneNumber", message: "invalidPhoneNumber" });

    if (isEmptyField(billingInfo.fullName)) err.push({ id: "fullName", message: "requiredField" });
    if (isEmptyField(billingInfo.address)) err.push({ id: "address", message: "requiredField" });
    if (isEmptyField(billingInfo.city)) err.push({ id: "city", message: "requiredField" });

    //if (isEmptyField(billingInfo.postalCode)) err.push({ id: "postalCode", message: "requiredField" });

    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Form({ billingInfo, errors, onChange }) {

    const { t } = useTranslation();
    const language = useProfileLanguage();

    const handleChangeField = (e) => {
        onChange && onChange({
            ...billingInfo,
            [e.target.name]: e.target.value
        });
    }
    const handlePhoneNumberChange = ({ value }) => {
        onChange && onChange({
            ...billingInfo,
            phoneNumber: value
        });
    };

    function getMessage(key)
    {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : '';
    }

    return <React.Fragment>
         <Grid item xs={12}>
            <TextField
                required
                name="fullName"
                label={t('fullName')}
                fullWidth
                value={billingInfo.fullName}
                error={hasError(errors, "fullName")}
                helperText={getMessage("fullName")}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs={12}>
            <TextField
                required
                name="address"
                label={t('address')}
                fullWidth
                value={billingInfo.address}
                error={hasError(errors, "address")}
                helperText={getMessage("address")}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                name="postalCode"
                fullWidth
                label={t('postalCode')}
                value={billingInfo.postalCode}
                error={hasError(errors, "postalCode")}
                helperText={getMessage("postalCode")}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextField
                required
                name="city"
                fullWidth
                label={t('city')}
                value={billingInfo.city}
                error={hasError(errors, "city")}
                helperText={getMessage("city")}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs>
            <TextField
                name="vat"
                fullWidth
                label={t('vat')}
                value={billingInfo.vat}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs>
            <CountrySelect
                required
                name="country"
                language={language}
                label={t('country')}
                textFieldProps={{ fullWidth : true }}
                value={billingInfo.country || 'PT'}
                onChange={(ev, opt) => opt && onChange({...billingInfo, country: opt.value })}
            />
        </Grid>
        <Grid item xs>
            <PhoneInput
                required
                name="phoneNumber"
                fullWidth
                label={t('phoneNumber')}
                value={billingInfo.phoneNumber || ''}
                error={hasError(errors, "phoneNumber")}
                helperText={`${getMessage("phoneNumber")}`}
                onChange={handlePhoneNumberChange}
            />
        </Grid>
    </React.Fragment>
}

const getDefaultBillingInfo = () =>
({
    fullName : '',
    company: '',
    email: '',
    phoneNumber: '',
    postalCode : '',
    address: '',
    city : '',
    country: 'PT',
    vat : ''
})

export default function BillingInfoCard({ userId }) {

    const [data, isLoading] = useUserBillingInfo(userId);
    const [billingInfo, setBillingInfo] = useState(data || getDefaultBillingInfo());
    const { t } = useTranslation();
    const errors = useMemo(() => getErrors(billingInfo), [billingInfo]);
    const hasErrors = errors !== null;
    const { updateBillingInformation } = useUserActions(userId);
    const { saveApi, isSaving } = useSaveApi();

    useEffect(() => setBillingInfo(data || getDefaultBillingInfo()), [data]);

    const handleSave = () => saveApi(updateBillingInformation(billingInfo));
    const handleChange = billingInfo => setBillingInfo(billingInfo);

    return <Card>
        <CardHeader title={t('billingInfo')} />
        <Box sx={{ p: 3 }} component="form" autoComplete="off" >
            {(isSaving || isLoading) && (<OverlapProgress progressProps={{ size : 75 }} />)}
            <Grid container spacing={3}>
                <Form
                    billingInfo={billingInfo}
                    errors={errors}
                    onChange={handleChange} />
                <Grid item xs={12} >
                    <Box display="flex" justifyContent="flex-end">
                        <ButtonWithProgress
                            onClick={handleSave}
                            loading={isSaving || isLoading}
                            disabled={isSaving || isLoading || hasErrors}
                            variant="contained"
                            size="small"
                            startIcon={<Icon icon="ant-design:save-filled" />}>
                            <Trans>
                                save
                            </Trans>
                        </ButtonWithProgress>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Card>
}