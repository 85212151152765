import axios from "axios";
import { appIdentifier } from '../../package.json';
import { usersApiBaseUrl } from "./utils";

export function login(credentials = { username: "", password: "" }) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/login`,
        data: { userName : credentials.username, password : credentials.password, appToken : appIdentifier }
    });
}