import { ContractStatusEnum, getStatusOfContract, getStatusOfPositionCommunication, PositionCommunicationsEnum } from "@nvtracker/common/helpers";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import * as devicesApi from "../../api/devices";
import { registerSubscription, unregisterSubscription } from '../../mqtt';

const initialState = {
    currentState: {
        isLoading: false,
        userId: null,
        list: []
    },
    actions: {
        registerDevice(data) { },
        updateDevice(id, data) { },
        removeDevice(id) { }
    }
};

const UserDevicesContext = React.createContext(initialState);

function mergeDevicesWithPositions(devices, positions) {
    const positionsGrouped = Object.fromEntries(positions.map(d => [d.deviceId, d]));
    return devices.map(d => ({ ...d, position: positionsGrouped[d.id] || {} }));
}

async function getDevicesData(userId) {
    return Promise.all([devicesApi.getUserDevices(userId), devicesApi.getUserDevicesPositions(userId).catch(e => ({ data: [] }))])
        .then(([devices, positions]) => mergeDevicesWithPositions(devices.data, positions.data));
}

export function UserDevicesProvider({ children, userId }) {
    const [currentState, setCurrentState] = useState({ ...initialState.currentState, userId });
    const isSubscribed = useRef(false);

    const actions = useMemo(() => {
        return {
            registerDevice(data) {
                return devicesApi.registerDevice(userId, data);
            },
            updateDevice(id, data) {
                return devicesApi.updateDevice(id, data);
            },
            removeDevice(id) {
                return devicesApi.removeDevice(id);
            }
        }
    }, [userId]);

    useEffect(() => {
        const subTopic = `users/${userId}/nvtracker/devices/#`;
        const setDevices = (setFn) => setCurrentState(state => ({ ...state, list: setFn(state.list) }));
        const setIsLoading = (isLoading) => setCurrentState(state => ({ ...state, isLoading }));
        let intervalPositions;

        const mqttSub = function (topic, data) {
            const [, id] = topic.match(/.+\/(\w+)$/);
            switch (data.action) {
                case "add":

                    // reload to obtain positions
                    setIsLoading(true);
                    return getDevicesData(userId).then(res => {
                        setDevices(_ => res);
                        setIsLoading(false);
                    });
                case "update":
                    return setDevices(devices => devices.map(d => d.id === data.payload.id ? { ...data.payload, position: d.position } : d));
                case "delete":
                    return setDevices(devices => devices.filter(d => d.id !== id));
                default:
                    throw new Error("Invalid action");
            }
        };

        isSubscribed.current = true;
        setIsLoading(true);

        getDevicesData(userId).then(res => {
            if (isSubscribed.current) {
                setDevices(_ => res);
                setIsLoading(false);
                registerSubscription(subTopic, mqttSub);
                intervalPositions = setInterval(() => {
                    devicesApi.getUserDevicesPositions(userId).then(res => {
                        setDevices(devices => mergeDevicesWithPositions(devices, res.data));
                    })
                }, 60000);
            }
        }).catch(res => setIsLoading(false));

        return () => {
            isSubscribed.current = false;
            unregisterSubscription(subTopic, mqttSub);
            if (intervalPositions) clearInterval(intervalPositions);
        }
    }, [userId]);

    return (
        <UserDevicesContext.Provider value={{ currentState, actions }}>
            {children}
        </UserDevicesContext.Provider>
    )
}

export function useUserDevices() {
    const { currentState } = useContext(UserDevicesContext);
    return [currentState.list, currentState.isLoading];
}

export function useUserId() {
    const { currentState } = useContext(UserDevicesContext);
    return useMemo(() => currentState.userId, [currentState.userId]);
}

/* export function useUserDevicesById() {
    const { currentState } = useContext(UserDevicesContext);
    const devicesListById = useMemo(() => {
        return Object.fromEntries((currentState.list || []).map(d => [d.id, d]));
    }, [currentState.list]);

    return [devicesListById, currentState.isLoading]
} */

export function useUserDevicesActions() {
    const { actions } = useContext(UserDevicesContext);
    return actions;
}

export function useDevice(deviceId) {
    const { currentState } = useContext(UserDevicesContext);
    return [currentState.isLoading === false ? currentState.list.find(d => d.id === deviceId) : null, currentState.isLoading];
}

/* export function useDeviceRoute(deviceId, from, to) {
    return useFetchV2(() => devicesApi.getRoute(deviceId, from, to), [deviceId, from, to]);
} */

export function useDevicesContractsSummary() {
    const { currentState } = useContext(UserDevicesContext);
    return useMemo(() => {

        let summary =
        {
            [ContractStatusEnum.ACTIVE]: 0,
            [ContractStatusEnum.EXPIRED]: 0,
            [ContractStatusEnum.EXPIRING]: 0
        };

        currentState.list.forEach(device => {
            const status = getStatusOfContract(device.renewalDate);
            summary[status] = summary[status] + 1;
        });

        return [summary, currentState.isLoading];

    }, [currentState.list, currentState.isLoading])
}

export function useDevicesPositionsSummary() {
    const { currentState } = useContext(UserDevicesContext);
    return useMemo(() => {

        let summary =
        {
            [PositionCommunicationsEnum.UPTODATE]: 0,
            [PositionCommunicationsEnum.BETWEEN12AND24H]: 0,
            [PositionCommunicationsEnum.MORE24H]: 0
        };

        currentState.list.forEach(device => {
            const stateEndTime = device.position && device.position.stateEndTime ? device.position.stateEndTime : null;
            const status = stateEndTime ? getStatusOfPositionCommunication(stateEndTime) : PositionCommunicationsEnum.MORE24H;
            summary[status] = summary[status] + 1;
        });

        return [summary, currentState.isLoading];

    }, [currentState.list, currentState.isLoading])
}

