import { Icon } from '@iconify/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import TabIcon from '@nvapps/common/components/ui/TabIcon';
import { useIsMobile } from '@nvapps/common/hooks';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ProfileChangePassword from '../../components/Profile/ProfileChangePassword';
import ProfileForm from '../../components/Profile/ProfileForm';
import ProfilePhotoUploader from '../../components/Profile/ProfilePhotoUploader';
import { useUserActions, useUserProfile } from '../../store/user';
import ReportsSettings from '../../components/Profile/ReportsSettings';


export default function Profile() {

    const [profile, fetching] = useUserProfile(true);
    const { updateProfile } = useUserActions();
    const isMobile = useIsMobile();
    const tabDirection = isMobile ? 'vertical' : 'horizontal';
    const { tab } = useParams();
    const { t } = useTranslation();

    return (
        <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h4" >
                    <Trans>profile</Trans>
                </Typography>
            </Stack>
            <TabContext value={tab || 'general'}>
                <Box mb={3} sx={{ borderColor: 'divider' }}>
                    <TabList>
                        <TabIcon label={t('general')} component={RouterLink} to="/app/profile/general" disableRipple icon={<Icon height="20" icon="bx:bxs-user-rectangle" />} value="general" direction={tabDirection} />
                        <TabIcon label={t('changePassword')} component={RouterLink} to="/app/profile/password" disableRipple icon={<Icon height="20" icon="bi:key-fill" />} value="password" direction={tabDirection} />
                        <TabIcon label={t('reports')} component={RouterLink} to="/app/profile/reports" disableRipple icon={<Icon height="20" icon="mdi:file-report-outline" />} value="reports" direction={tabDirection} />
                    </TabList>
                </Box>
                <TabPanel sx={{ p: 0 }} value="general">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3} >
                            <ProfilePhotoUploader pictureUrl={profile.photoUrl} />
                        </Grid>
                        <Grid item xs={12} md>
                            <ProfileForm profile={profile} fetching={fetching} onSaved={profile => updateProfile(profile)} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="password" >
                    <ProfileChangePassword />
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="reports" >
                    <ReportsSettings />
                </TabPanel>
            </TabContext>
        </Container>
    )
}
