import peopleFill from '@iconify/icons-eva/people-fill';
import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from '@nvtracker/common/components/Logo';
import MHidden from '@nvtracker/common/components/ui/MHidden';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuthenticationActions } from '../store/authentication';
import { useProfilePhoto, useUserProfile } from '../store/user';
import NavSection from './NavSection';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[200]
}));

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { pathname } = useLocation();
    const [profile] = useUserProfile();
    const [photo] = useProfilePhoto();
    const { t } = useTranslation();
    const { logout } = useAuthenticationActions();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const navConfig = [{
        title: 'dashboard',
        path: '/app/dashboard',
        icon: getIcon('mdi:view-dashboard')
    },
    {
        title: t('clients'),
        path: '/app/clients',
        icon: getIcon(peopleFill)
    },
    {
        title: t('devices'),
        path: '/app/devices',
        icon: getIcon('fluent:device-meeting-room-16-filled'),
        children: [
            {
                title: t('stock'),
                path: '/app/devices/stock',
            },
            {
                title: t('inUse'),
                path: '/app/devices/inuse'
            },
            {
                title: t('suspended'),
                path: '/app/devices/suspended'
            }
        ]
    },
    {
        title: t('purchases'),
        path: '/app/purchases',
        icon: getIcon('mdi:invoice')
    },
    ]

    const renderContent = (
        <React.Fragment>
            <Box sx={{ px: 2.5, py: 3 }}>
                <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                    <Logo />
                </Box>
            </Box>
            <Box sx={{ mb: 5, mx: 2.5 }}>
                <AccountStyle>
                    <Avatar src={photo} alt="photoURL" />
                    <Box sx={{ ml: 2, overflow: 'hidden' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            {profile.name || profile.userName}
                        </Typography>
                        {profile.name &&
                            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                {profile.userName}
                            </Typography>}
                    </Box>
                </AccountStyle>
            </Box>
            <NavSection navConfig={navConfig} />
            <Box sx={{ flexGrow: 1 }} />
            <Box p={2.5}>
                <Button variant='outlined' onClick={() => logout()} color='primary' fullWidth>
                    <Trans>logout</Trans>
                </Button>
            </Box>
        </React.Fragment>
    );

    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default'
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
