import { useFetchV2 } from "@nvapps/common/hooks";
import { arraySort } from "@nvtracker/common/utils";
import { useMemo } from "react";
import { getPlans, getPlansByGsmNumber } from "../api/plans";

// TODO: use redux
let lastFetch;

function getPlansCached() {
    lastFetch = lastFetch || getPlans();
    return lastFetch;
}

function getPlansByIdCached() {
    const plansCached = getPlansCached();
    return plansCached.then(res => {
        if (res.status === 200) {
            const data = Object.fromEntries((res.data || []).map(p => [p.id, p]));
            return { status: res.status, data };
        } else {
            return res;
        }
    });
}

export function usePlans() {
    return useFetchV2(() => getPlansCached(), []);
}

export function usePlansById() {
    return useFetchV2(() => getPlansByIdCached(), []);
}

export function usePlanById(id)
{
    const [data, isLoading] = usePlansById();
    return [(data || {})[id], isLoading];
}

export function usePlansOfGsmNumber(gsmNumber) {

    const [data, isLoading] = useFetchV2(gsmNumber ? () => getPlansByGsmNumber(gsmNumber) : null, [gsmNumber]);
    const sorted = useMemo(() => arraySort((data || []), { default : 'desc', months : 'asc' }), [data]);

    return [sorted, isLoading];
}
