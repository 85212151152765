import { connect } from "mqtt";

let subscriptions = {};
let baseTopic = "nvapps";
let startPromise;

function subscribeTopic(subTopic) {
    startPromise.then(mqttClient => {
        mqttClient.subscribe(`${baseTopic}/${subTopic}`, function (err) {
            if (err) console.error(err);
        });
    });
}

function unsubscribeTopic(subTopic) {
    startPromise && startPromise.then(mqttClient => {
        mqttClient.unsubscribe(`${baseTopic}/${subTopic}`, function (err) {
            if (err) console.error(err);
        });
    });
}

export function registerSubscription(topic, callback) {
    if (topic in subscriptions) {
        subscriptions[topic].push(callback);
    } else {
        subscriptions[topic] = [callback];
        subscribeTopic(topic);
    }
}

export function unregisterSubscription(topic, callback) {
    const callbacks = (subscriptions[topic] || []).filter(c => c !== callback);
    if (callbacks.length === 0) {
        delete subscriptions[topic];
        unsubscribeTopic(topic);
    } else {
        subscriptions[topic] = callbacks;
    }
}

// TODO: add complete pattern support
function matchesTopic(pattern, topic) {
    //console.log(pattern, topic);
    // if (pattern.endsWith("#"))
    //     return topic.startsWith(pattern.substr(0, pattern.length - 1));
    // else
    //     return pattern === topic;

    const regExTopicFilter = new RegExp(pattern.replace(/\+/g, "[^/]+").replace(/#$/g, ".+"));
    return regExTopicFilter.test(topic);
}

export function startMqttClient(token) {
    startPromise = startPromise || new Promise((resolve) => {
        const mqttClient = connect(`wss://mqtt.nvapps.novatronica.com/`, {
            username: "nvapps-admin-jwt",
            password: token
        });

        mqttClient.on('connect', function () {
            resolve(mqttClient);
        });

        mqttClient.on('message', function (topic, message) {
            console.log(topic, message.toString());
            const subEntries = Object.entries(subscriptions);
            for (const [subTopic, callbacks] of subEntries) {
                const pattern = `${baseTopic}/${subTopic}`;
                if (matchesTopic(pattern, topic)) {

                    callbacks.forEach(callback => {
                        callback(topic.substr(baseTopic.length), JSON.parse(message));
                    });
                }
            }
        });
    });
    return startPromise;
}


export function stopMqttClient() {
    if (!startPromise) return;
    startPromise.then(mqttClient => mqttClient.end());
    startPromise = null;
    subscriptions = {};
}


