import { Icon } from '@iconify/react';
import { Box, Card, CardContent, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSaveApi } from '../../hooks';
import { useUserReportsSettings } from '../../store/user';
import { updateReportsSettings } from '../../api/settings';

const ReportsTypeEnum =
{
    'DEVICE_EXPIRED': 'DeviceExpired',
    'DEVICE_EXPIRING' : 'DeviceExpiring'
}

function ReportsDeviceExpiredComponent({ settings, disabled, onChange }) {

    const { expiredHours } = settings || {};
    const hasError = !Boolean(disabled) && !ReportsType[ReportsTypeEnum.DEVICE_EXPIRED].validation(settings)
    const { t } = useTranslation();

    return <Box display='flex' alignItems='center' gap={2}>
        <Typography><Trans>
            devicesExpiredTime
        </Trans></Typography>
        <TextField
            id="outlined-size-small"
            value={expiredHours || 0}
            variant='standard'
            disabled={disabled}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'right' } }}
            size="small"
            error={hasError}
            color="success"
            onChange={ev => onChange({ expiredHours: Boolean(ev.target.value) ? parseInt(ev.target.value) : 0 })}
            sx={{ width: 50, }}
        /><Typography>{t('hour_s').toLowerCase()}</Typography>
        {hasError && <Typography variant='caption' color="error">
            {t('mustBeGreaterThanAndLessThan', { min: 0, max: 900 })}
        </Typography>}
    </Box>
}

function ReportsDeviceExpiringComponent({ settings, disabled, onChange }) {

    const { expiredDays } = settings || {};
    const hasError = !Boolean(disabled) && !ReportsType[ReportsTypeEnum.DEVICE_EXPIRING].validation(settings)
    const { t } = useTranslation();

    return <Box display='flex' alignItems='center' gap={2}>
        <Typography><Trans>
            devicesExpiringTime
        </Trans></Typography>
        <TextField
            id="outlined-size-small"
            value={expiredDays || 0}
            variant='standard'
            disabled={disabled}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'right' } }}
            size="small"
            error={hasError}
            color="success"
            onChange={ev => onChange({ expiredDays: Boolean(ev.target.value) ? parseInt(ev.target.value) : 0 })}
            sx={{ width: 50, }}
        /><Typography>{t('day_s').toLowerCase()}</Typography>
        {hasError && <Typography variant='caption' color="error">
            {t('mustBeGreaterThanAndLessThan', { min: 0, max: 365 })}
        </Typography>}
    </Box>
}

const ReportsType =
{
    [ReportsTypeEnum.DEVICE_EXPIRED]:
    {
        defaultValue: { expiredHours: 1 },
        component: ReportsDeviceExpiredComponent,
        validation: ({ expiredHours }) => !isNaN(parseInt(expiredHours)) && expiredHours > 0 && expiredHours <= 900
    },
    [ReportsTypeEnum.DEVICE_EXPIRING]:
    {
        defaultValue: { expiredDays: 15 },
        component: ReportsDeviceExpiringComponent,
        validation: ({ expiredDays }) => !isNaN(parseInt(expiredDays)) && expiredDays > 0 && expiredDays <= 900
    }
}

function getErrors(settings) {

    if ((settings || []).length === 0) return null;
    const err = settings.map(s => ReportsType[s.type].validation(s.settings) ? null : ({ id: s.type })).filter(s => Boolean(s));
    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

export default function ReportsSettings() {

    const [settings, fetching] = useUserReportsSettings(true);
    const [form, setForm] = useState(settings || []);
    const errors = useMemo(() => getErrors(form), [form]);
    const hasErrors = errors !== null;
    const { saveApi, isSaving } = useSaveApi();

    useEffect(() => setForm(settings || []), [settings]);

    const handleSave = async () => {
        await saveApi(updateReportsSettings(form));
    }

    const handleChange = row => setForm(state => {
        const has = state.find(e => e.type === row.type);
        return Boolean(has) ? state.map(e => e.type === row.type ? { ...row } : e) : [...state, { ...row }]
    });

    return (
        <Card >
            <CardContent sx={{ position: 'relative' }}>
                {(isSaving || fetching) && (<OverlapProgress progressProps={{ size: 75 }} />)}
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <List>
                            {Object.keys(ReportsType).map(c => {
                                const option = form.find(e => e.type === c) || { type: c, active: false, settings: { ...ReportsType[c].defaultValue } };
                                const labelId = `checkbox-list-label-${c}`;
                                const { active, settings } = option;
                                const Component = ReportsType[c].component;

                                return <ListItem
                                    key={c}
                                    disablePadding>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={active}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={ev => handleChange({ ...option, active: ev.target.checked })}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} >
                                        <Component settings={settings} disabled={!active} onChange={settings => handleChange({ ...option, settings })} />
                                    </ListItemText>
                                </ListItem>
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={12} >
                        <Box display="flex" justifyContent="flex-end">
                            <ButtonWithProgress
                                onClick={handleSave}
                                loading={isSaving || fetching}
                                disabled={isSaving || fetching || hasErrors}
                                variant="contained"
                                size="small"
                                startIcon={<Icon icon="ant-design:save-filled" />}>
                                <Trans>
                                    save
                                </Trans>
                            </ButtonWithProgress>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
