import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { appIdentifier } from '../../package.json';
import { getUsersPagedUrl } from "../api/users";
import { createSelector } from 'reselect'
import { getDevicesPagedUrl } from '../api/devices';
import { urlEncode } from '@nvapps/common/utils';


const search = createSlice({
    name: 'search',
    initialState: {

        users:
        {
            fetching: false,
            items: []
        },

        devices:
        {
            fetching: false,
            items: []
        },
        filters:
        {
            searchText: '',
            pageSize: 100,
        }
    },
    reducers: {
        fetchUsersStart(state) {
            state.users.fetching = true;
            state.users.hasError = false;
        },
        fetchUsersFailed(state) {
            state.users.hasError = true;
            state.users.fetching = false;
        },
        fetchUsersSuccess(state, { payload }) {
            state.users.items = payload.pageItems;
            state.users.fetching = false;
        },
        fetchDevicesStart(state) {
            state.devices.fetching = true;
            state.devices.hasError = false;
        },
        fetchDevicesFailed(state) {
            state.devices.hasError = true;
            state.devices.fetching = false;
        },
        fetchDevicesSuccess(state, { payload }) {
            state.devices.items = payload.pageItems;
            state.devices.fetching = false;
        },
        updateFilters(state, { payload }) {
            state.filters = { ...state.filters, ...payload };
        },

        clearSearch(state) {
            state.users.items = [];
            state.devices.items = [];
            state.filters.searchText = '';
        }
    }
});

function fetchUsers(filters) {
    const actions = search.actions;

    return (dispatch, getState) => {

        if (filters.searchText.length < 3) {
            dispatch(actions.fetchUsersSuccess({ pageItems: [] }));
            return;
        }

        dispatch(
            {
                type: actions.fetchUsersStart.type,
                meta:
                {
                    debounce: 200,
                    apiRequest:
                    {
                        method: "GET",
                        url: getUsersPagedUrl,
                        successActionType: actions.fetchUsersSuccess.type,
                        errorActionType: actions.fetchUsersFailed.type,
                        params: { ...filters, searchText : urlEncode(filters.searchText), apps: [appIdentifier] }
                    },
                    cancelApiRequest:
                    {
                        startedBy: actions.fetchUsersStart.type
                    }
                }
            });
    };
}

export function useSearchUsers() {
    const { fetching, items } = useSelector(state => state.search.users);
    const filters = useSearchFilters();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers(filters));
    }, [filters, dispatch]);

    return [items, fetching];
}

function fetchDevices(filters) {
    const actions = search.actions;

    return (dispatch, getState) => {

        if (filters.searchText.length < 3) {
            dispatch(actions.fetchDevicesSuccess({ pageItems: [] }));
            return;
        }

        dispatch({
            type: actions.fetchDevicesStart.type,
            meta:
            {
                debounce: 200,
                apiRequest:
                {
                    method: "GET",
                    url: getDevicesPagedUrl,
                    successActionType: actions.fetchDevicesSuccess.type,
                    errorActionType: actions.fetchDevicesFailed.type,
                    params: { ...filters, searchText : urlEncode(filters.searchText) }
                },
                cancelApiRequest:
                {
                    startedBy: actions.fetchDevicesSuccess.type
                }
            }
        });
    };
}

export function useSearchDevices() {
    const { fetching, items } = useSelector(state => state.search.devices);
    const filters = useSearchFilters();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDevices(filters));
    }, [filters, dispatch]);

    return [items, fetching];
}

const hasSearchResultsSelector = createSelector(
    state => state.search.users.items,
    state => state.search.devices.items,
    (users, devices) => users.length + devices.length);


export function useHasSearchResults() {
    const state = useSelector(hasSearchResultsSelector);
    return state > 0;
}

const isSearchingSelector = createSelector(
    state => state.search.users.fetching,
    state => state.search.devices.fetching,
    (users, devices) => users || devices);


export function useIsSearchFetching() {
    const state = useSelector(isSearchingSelector);
    return state;
}

export function useSearchFilters() {

    const state = useSelector(state => state.search.filters, shallowEqual);
    return state;
}

export function useSearchActions() {
    const dispatch = useDispatch();
    const actions = search.actions;

    return useMemo(() => ({
        updateFilters: value => dispatch(actions.updateFilters(value)),
        clearSearch: () => dispatch(actions.clearSearch())
    }), [actions, dispatch]);
}

export default search.reducer;