import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ThemeConfig from '@nvapps/common/theme';
import ptLocale from 'date-fns/locale/pt';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import store from './store';
import history from './store/history';
import { SnackbarProvider } from 'notistack';
import { ptPT } from '@mui/material/locale';


function App() {
    return (
        <Provider store={store}>
            <ThemeConfig type="light" language={ptPT}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                        <Router history={history}>
                            <Routes />
                        </Router>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeConfig>
        </Provider>
    );
}

export default App;
