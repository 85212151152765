import { Navigate, useRoutes } from 'react-router-dom';

import Layout from './layout';
import Dashboard from './pages/Dashboard';
import Devices from './pages/Devices';
import Profile from './pages/Profile';

import Login from './pages/Login';

import Users from './pages/Users';
//import RegisterUser from './pages/Users/Register';
import Purchases from './pages/Purchases';
import EditUser from './pages/Users/Edit';
import { useIsLoggedIn } from './store/authentication';

export default function Routes() {
    const isLoggedIn = useIsLoggedIn();
    return useRoutes([
        {
            path: '/app',
            element: isLoggedIn ? <Layout /> : <Navigate to="/" />,
            children: [
                { path: '', element: <Navigate to="/app/dashboard" replace /> },
                { path: 'dashboard', element: <Dashboard /> },
                {
                    path: 'clients',
                    children: [
                        { path: '', element: <Navigate to="/app/clients/list" replace /> },
                        { path: 'list', element: <Users /> },
                        //{ path: 'new', element: <RegisterUser /> },
                        { path: ':id/:tab/*', element: <EditUser /> },
                        { path: ':id/*', element: <EditUser /> }
                    ]
                },
                {
                    path: 'devices',
                    children: [
                        { path: '', element: <Navigate to="/app/devices/stock" replace /> },
                        { path: 'stock', element: <Devices tab='stock' /> },
                        { path: 'inuse', element: <Devices tab='inuse' /> },
                        { path: 'suspended', element: <Devices tab='suspended' /> },
                    ]
                },
                {
                    path: 'purchases', element: <Purchases />
                },
                {
                    path: 'profile',
                    children: [
                        { path: '', element : <Navigate to="/app/profile/general" replace /> },
                        { path: ':tab', element: <Profile /> }
                    ]
                },
            ]
        },
        {
            path: '/',
            element: !isLoggedIn ? <Login /> : <Navigate to="/app" />,
        },

        { path: '*', element: <Navigate to="/app" replace /> }
    ]);
}
