import { useFetchV2 } from "@nvapps/common/hooks";
import { urlEncode } from "@nvapps/common/utils";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import * as eventsApi from "../../api/events";

const initialState = {
    currentState: {
        isLoading: false,
        userId: null,
        list:
        {
            page: 0,
            pageItems: [],
            pageSize: 10,
            rowCount: 0,
            pageCount: 0
        },
        filters:
        {
            searchText: '',
            sortBy: 'DateTime',
            sortDesc: true,
            page: 0,
            pageSize: 10,
        }
    },
    actions: {
        updateFilters(data) { }
    }
};

const EventsContext = React.createContext(initialState);

export function EventsProvider({ children, userId }) {
    const [currentState, setCurrentState] = useState({ ...initialState.currentState, userId });
    const isSubscribed = useRef(false);

    const actions = useMemo(() => {
        return {
            updateFilters(filters) {
                setCurrentState(state => ({ ...state, filters: { ...state.filters, ...filters } }));
            }
        }
    }, []);

    // Due to change user without leaving the user screen (ex. search bar)
    useEffect(() => setCurrentState(state => ({ ...state, userId })), [userId]);

    useEffect(() => {

        const setEvents = (setFn) => setCurrentState(state => ({
            ...state,
            list: setFn(state.list)
        }));
        const setIsLoading = (isLoading) => setCurrentState(state => ({ ...state, isLoading }));
        isSubscribed.current = true;
        setIsLoading(true);
        eventsApi.getEventsPaged(userId, { ...currentState.filters, searchText: urlEncode(currentState.filters.searchText) }).then(res => {

            if (isSubscribed.current) {
                setEvents(_ => res.data);
                setIsLoading(false);
            }
        });

        return () => {
            isSubscribed.current = false;
        }

    }, [userId, currentState.filters]);

    return (
        <EventsContext.Provider value={{ currentState, actions }}>
            {children}
        </EventsContext.Provider>
    )
}

export function useEventsFilters() {

    const { currentState } = useContext(EventsContext);
    return useMemo(() => ({ ...currentState.filters, rowCount: currentState.list.rowCount }), [currentState.filters, currentState.list.rowCount]);
}

export function useEvents() {
    const { currentState } = useContext(EventsContext);
    return useMemo(() => [currentState.list.pageItems, currentState.isLoading], [currentState.list.pageItems, currentState.isLoading]);
}

export function useEventsActions() {
    const { actions } = useContext(EventsContext);
    return actions;
}

export function useEventsUserId() {
    const { currentState } = useContext(EventsContext);
    return useMemo(() => currentState.userId, [currentState.userId]);
}

export const useEvent = (eventId, userId) => {

    return useFetchV2(eventId ? () => eventsApi.getEvent(userId, eventId) : null, [userId, eventId]);
}
