import { Icon } from '@iconify/react';
import { Box, Card, CardContent, Grid, TextField } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import CountrySelect from '@nvapps/common/components/ui/CountrySelect';
import LanguageSelect from '@nvapps/common/components/ui/LanguageSelect';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import PhoneInput, { isValidPhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { updateProfile } from '../../api/profile';
import { useSaveApi } from '../../hooks';
import { getDefaultProfile, useProfileLanguage } from '../../store/user';

function getErrors(profile) {
    let err = [];
    if (!profile) return null;

    if ((profile.name || '').trim().length === 0) err.push({ id: "name", message: "requiredField" });
    if ((profile.phoneNumber || '').length > 0 && !isValidPhoneNumber(profile.phoneNumber)) err.push({ id: "phoneNumber", message: "invalidPhoneNumber" });

    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Form({ profile, errors, onChange }) {

    const { t } = useTranslation();
    const language = useProfileLanguage();

    const setField = (name, value) => {
        onChange && onChange({ ...profile, [name]: value });
    }

    function getMessage(key)
    {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : '';
    }

    return <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextField
                label={`${t('username')}/${t('email')}`}
                disabled
                fullWidth
                value={profile.userName} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label={t('name')}
                fullWidth
                value={profile.name}
                error={hasError(errors, "name")}
                helperText={getMessage("name")}
                onChange={e => setField("name", e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <CountrySelect
                label={t('country')}
                language={language}
                value={profile.country || 'PT'}
                onChange={(ev, opt) => opt && setField("country", opt.value)}
                textFieldProps={{ fullWidth : true }} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <LanguageSelect
                label={t('language')}
                value={profile.language}
                onChange={(ev, opt) => opt && setField("language", opt.value)}
                textFieldProps={{ fullWidth : true}} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <PhoneInput
                required
                name="phoneNumber"
                fullWidth
                label={t('phoneNumber')}
                value={profile.phoneNumber || ''}
                error={hasError(errors, "phoneNumber")}
                helperText={getMessage("phoneNumber")}
                onChange={e => setField("phoneNumber", e.value)}
            />
        </Grid>
    </React.Fragment>
}

export default function ProfileForm({ profile, fetching, onSaved }) {

    const [profileForm, setProfile] = useState(profile || getDefaultProfile());
    const errors = useMemo(() => getErrors(profileForm), [profileForm]);
    const hasErrors = errors !== null;
    const { saveApi, isSaving } = useSaveApi();

    useEffect(() => setProfile(profile || getDefaultProfile()), [profile]);

    const handleSave = async () => {
        await saveApi(updateProfile(profileForm).then(res =>
        {
            onSaved && onSaved(res.data);
        }));
    }
    const handleChange = profile => setProfile(profile);

    return (
        <Card >
            <CardContent sx={{ position : 'relative' }}>
                {(isSaving || fetching) && (<OverlapProgress progressProps={{ size : 75 }} />)}
                <Grid container spacing={3}>
                    <Form
                        profile={profileForm}
                        errors={errors}
                        onChange={handleChange} />
                    <Grid item xs={12} >
                        <Box display="flex" justifyContent="flex-end">
                            <ButtonWithProgress
                                onClick={handleSave}
                                loading={isSaving || fetching}
                                disabled={isSaving || fetching || hasErrors}
                                variant="contained"
                                size="small"
                                startIcon={<Icon icon="ant-design:save-filled" />}>
                                <Trans>
                                    save
                                </Trans>
                            </ButtonWithProgress>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
