import { Box, DialogTitle } from '@mui/material';
import CloseIconButton from '@nvapps/common/components/ui/CloseIconButton';
import FileManagerNvApps from '@nvapps/common/components/ui/FileManager';
import { getJwtTokenCookie } from '@nvapps/common/utils';
import Dialog from '@nvapps/common/components/ui/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallApi } from '../../hooks';
import { DocumentsProvider, useDocuments, useDocumentsActions } from '../../store/contexts/documents';

export function FileManager({ accept = '.png,.gif,.jpg,.jpeg,.bmp,.pdf,.doc,.docx,.xls,.xlsx,.pdf,.txt,.cvs', ...props }) {

    const { t } = useTranslation();

    return (
        <FileManagerNvApps
            accept={accept}
            authenticationToken={getJwtTokenCookie()}
            locales={{
                root: t('root'),
                newFolder: t('newFolder'),
                editFolder: t('open'),
                open: t('open'),
                delete: t('delete'),
                upload: t('upload'),
                download: t('download'),
                emptyFolder: t('emptyFolder'),
                folderName: t('folderName'),
                requiredField: t('requiredField'),
                confirm: t('confirm'),
                cancel: t('cancel'),
                clickOrDrag: t('clickOrDrag'),
                errorFileToBig: t('errorFileToBig'),
                edit: t('edit'),
                deleteQuestion: t('deleteQuestion'),
                yes: t('yes'),
                no: t('no'),
                close: t('close'),
                view: t('view'),
                options: t('options')
            }}
            {...props} />
    )
}


// Delete files on delete user

function FileManagerDialogContent({ onClose, title, ...props }) {
    const { addFile, createFolder, updateFolder, refresh, deleteDocument } = useDocumentsActions();
    const { callApi, isLoading } = useCallApi();
    const [list, isFetching] = useDocuments();
    const { t } = useTranslation();

    const parseParentId = parent => parent ? parseInt(parent) : null;

    const onUploadFile = async (file, parent, callBack) => {

        const res = await callApi(addFile(file, parseParentId(parent)));
        if (res === true) {
            if (callBack) callBack(true);
            refresh();
        }
    }

    const onCreateFolder = async (name, parent, callBack) => {
        var res = await callApi(createFolder(name, parseParentId(parent)));
        if (res === true) {
            if (callBack) callBack(true);
            refresh();
        }
    }

    const onUpdateFolder = async (id, name, parent, callBack) => {
        var res = await callApi(updateFolder(id, name, parseParentId(parent)));
        if (res === true) {
            if (callBack) callBack(true);
            refresh();
        }
    }

    const onDelete = async (id) => {
        var res = await callApi(deleteDocument(id));
        if (res === true) refresh();
    }

    return <>
        <DialogTitle sx={{ pb : 0}}>
            <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
                {t('files')}{title && ` - ${title}`}
                <CloseIconButton title={t('close')} onClick={onClose} />
            </Box>
        </DialogTitle>
        <Box height={'80vh'}>
            <FileManager
                items={list}
                loading={isFetching || isLoading}
                onUploadFile={onUploadFile}
                onCreateFolder={onCreateFolder}
                onUpdateFolder={onUpdateFolder}
                onDelete={onDelete} />
        </Box>
    </>
}

function FileManagerDialogProvider({ path, title, onClose, ...props }) {
    return <DocumentsProvider path={path}>
        <FileManagerDialogContent title={title} onClose={onClose} {...props} />
    </DocumentsProvider>
}

export default function FileManagerDialog({ open, title, path, onClose, ...props }) {

    return (
        <Dialog open={open} onClose={onClose} maxWidth='xl' >
            <FileManagerDialogProvider onClose={onClose} title={title} path={path} {...props} />
        </Dialog>
    );
}