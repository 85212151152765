
import { Card, Container, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import DevicesInUse from '../../components/Devices/DevicesInUse';
import DevicesStock from '../../components/Devices/DevicesStock';
import DevicesSuspended from '../../components/Devices/DevicesSuspended';

const tabs =
{
    'stock' :
    {
        title : 'stock',
        component : DevicesStock
    },
    'suspended' :
    {
        title : 'suspended',
        component :  DevicesSuspended,
    },
    'inuse' :
    {
        title : 'inUse',
        component : DevicesInUse
    }
}

export default function Devices({ tab = 'stock' })
{
    const { t } = useTranslation();
    const Tab = tabs[tab];
    if (Tab == null) return '';

    const Component = Tab.component;

    return <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" >
                <Trans>devices</Trans> {` ${t(Tab.title).toLowerCase()}`}
            </Typography>
        </Stack>
        <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
           <Component />
        </Card>
    </Container>
}