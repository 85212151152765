import {
    Box,
    Button, DialogActions, DialogContent, DialogTitle,
    FormControl,
    FormHelperText,
    Grid, InputLabel,
    MenuItem, OutlinedInput, Select, Stack, Typography
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import Dialog from "@nvapps/common/components/ui/Dialog";
import { getErrorMessage, hasError } from "@nvtracker/common/utils";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { linkInvoicesToPurchase } from "../../api/purchases";
import DateTime from "../../components/Ui/DateTime";
import { useSaveApi } from "../../hooks";
import { useInvoicesOfUser } from "../../store/contexts/userInvoices";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(purchaseId, purchases, theme) {
    return {
        fontWeight:
            purchases.indexOf(purchaseId) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function getErrors(form) {

    const err = [];
    if (!form) return null;

    if (form.devices.length === 0) err.push({ id: "devices", message: "requiredField" });
    if (form.invoices.length === 0) err.push({ id: "invoices", message:  "requiredField" });
    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Content({ userId, purchase, onClose }) {

    const { t } = useTranslation();
    const { id, purchaseDevices } = purchase || {};
    const [form, setForm] = useState({ devices: [], invoices: [] });
    const [invoices, isFetchingInvoices] = useInvoicesOfUser(userId);
    const theme = useTheme();
    const { saveApi, isSaving } = useSaveApi();
    const errors = useMemo(() => getErrors(form), [form]);
    const invoicesFiltered = useMemo(() => (invoices || []).filter(i => !i.purchases.includes(id) && form.devices.includes(i.deviceId)), [invoices, id, form.devices]);
    useEffect(() => setForm(state => ({...state, invoices: [] })) , [form.devices])

    const handleSave = async () => {
        const res = await saveApi(linkInvoicesToPurchase(id, form.invoices));
        if (res === true) onClose && onClose();
    }

    const handleChange = (event) => {

        const { target: { name, value }, } = event;
        const array = typeof value === 'string' ? value.split(',') : value;
        setForm(state => ({ ...state, [name]: array }));
    };

    function getMessage(key, defaultValue) {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : defaultValue;
    }

    return <>
        <DialogContent>
            <Grid container spacing={3} pt={2} >
                <Grid item xs={12}>
                    <FormControl fullWidth size="small" error={hasError(errors, "devices")}>
                        <InputLabel>
                            <Trans>devices</Trans>
                        </InputLabel>
                        <Select
                            multiple
                            value={form.devices}
                            name="devices"
                            onChange={handleChange}

                            input={
                                <OutlinedInput label={<Trans>devices</Trans>} />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) return <Trans>devices</Trans>;
                                return (purchaseDevices || []).filter(p => selected.includes(p.deviceId)).map(e => e.deviceName).join(', ');
                            }}
                            MenuProps={MenuProps}
                        >
                            {(purchaseDevices || []).map((d) => (
                                <MenuItem
                                    key={d.deviceId}
                                    value={d.deviceId}
                                    style={getStyles(d.deviceId, form.devices, theme)} >
                                    <Typography variant="subtitle2" sx={{ display: 'block' }}>
                                        {d.deviceName || d.deviceId}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{getMessage("devices")}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth size="small" error={!hasError(errors, "devices") && hasError(errors, "invoices")}>
                        <InputLabel>
                            <Trans>invoices</Trans>
                        </InputLabel>
                        <Select
                            multiple
                            value={form.invoices}
                            name="invoices"
                            disabled={hasError(errors, "devices")}
                            onChange={handleChange}
                            input={
                                <OutlinedInput label={<Trans>invoices</Trans>} />
                            }
                            renderValue={(selected) => {
                                if (selected.length === 0) return <Trans>invoices</Trans>;
                                return (invoices || []).filter(p => selected.includes(p.id)).map(e => e.number).join(', ');
                            }}
                            MenuProps={MenuProps}
                        >
                            {(invoicesFiltered || []).map((invoice) => (
                                <MenuItem
                                    key={invoice.id}
                                    value={invoice.id}
                                    style={getStyles(invoice.id, form.invoices, theme)}
                                >
                                    <Box>
                                        <Typography variant="subtitle2" sx={{ display: 'block' }}>
                                            {invoice.number}
                                        </Typography>
                                        <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
                                            <DateTime datetime={invoice.date} />
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                        {!hasError(errors, "devices") &&<FormHelperText>{getMessage("invoices")}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={isSaving} color="secondary">
                <Trans>cancel</Trans>
            </Button>
            <ButtonWithProgress
                onClick={handleSave}
                disabled={isSaving || isFetchingInvoices}
                color="primary"
                loading={isSaving || isFetchingInvoices}>
                <Trans>link</Trans>
            </ButtonWithProgress>
        </DialogActions>
    </>
}

export default function LinkPurchaseToInvoicesDialog({ open, userId, purchase, onClose }) {

    const { id } = purchase || {};

    return (
        <Dialog
            open={open}
            onClose={onClose} >
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Trans values={{purchase : id}}>linkPurchasetoInvoices</Trans>
                </Stack>
            </DialogTitle>
            <Content userId={userId} purchase={purchase} onClose={onClose} />
        </Dialog>
    );
}