import { authRequest } from "./utils";

const apiBaseUrl = "";

export const getDeviceTypesUrl = `${apiBaseUrl}/api/deviceTypes`;

export function getDeviceTypes() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/deviceTypes`
    });
}