import bellFill from '@iconify/icons-eva/bell-fill';
import { Icon } from '@iconify/react';
import { Badge, Box, Divider, IconButton, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import MenuPopover from '@nvtracker/common/components/MenuPopover';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../components/Ui/Scrollbar';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useAlerts } from '../store/alerts';
import CenteredContent from '@nvapps/common/components/ui/CenteredContent';

AlertItem.propTypes = {
    alert: PropTypes.object.isRequired
};

const alertsTypes =
{
    ExpiringDevices:
    {
        title: 'expiringDevices',
        description: 'youHaveContractsExpiring',
        link: '/app/dashboard'
    },
    ExpiredDevices:
    {
        title: 'expiredDevices',
        description: 'youHaveContractsExpired',
        link: '/app/dashboard'
    }
};

function AlertItem({ alert, onClick }) {

    const { t } = useTranslation();
    const { id, count } = alert;
    const { description, link } = alertsTypes[id];

    return (
        <ListItemButton
            to={link}
            disableGutters
            onClick={onClick}
            component={RouterLink}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px'
            }}
        >
            <ListItemText
                primary={t(description, { count })}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption', color: 'text.disabled' }}
                secondary={t('clickToSeeMore')}
            />
        </ListItemButton>
    );
}


export default function AlertsPopover() {

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [alerts] = useAlerts();
    const hasAlerts = (alerts || []).length > 0;
    const { t } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                size="large"
                color={open ? 'primary' : 'default'}
                onClick={handleOpen}
                sx={{
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
                    })
                }}
            >
                {hasAlerts ?
                <Badge badgeContent={' '} color="error" variant="dot">
                    <Icon icon={bellFill} width={20} height={20} />
                </Badge> : <Icon icon={bellFill} width={20} height={20} />}
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 360 }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">{t('alerts')}</Typography>
                    </Box>
                </Box>
                <Divider />
                {hasAlerts ?
                    <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                        <List disablePadding>
                            {(alerts || []).map(n =>
                                <AlertItem key={n.id} alert={n} onClick={handleClose} />)}
                        </List>
                    </Scrollbar> :
                    <CenteredContent style={{ minHeight: 100 }}>
                        <Typography variant="subtitle2">
                            {t('noAlerts')}
                        </Typography>
                    </CenteredContent>}
            </MenuPopover>
        </>
    );
}
