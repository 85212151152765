import { Grid } from '@mui/material';
import React from 'react';
import ComunicationsCard from './ComunicationsCard';
import ContractsCard from './ContractsCard';
import DevicesList from './DevicesList';

export default function TabDevices({ userId })
{
   // const devices = GetDevicesOfUser();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <ContractsCard />
            </Grid>
            <Grid item xs={12} sm={6} >
                <ComunicationsCard />
            </Grid>
            <Grid item xs={12} >
                <DevicesList userId={userId} />
            </Grid>
        </Grid>);
}
