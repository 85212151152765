import { DateTimePicker } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import Dialog from '@nvapps/common/components/ui/Dialog';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import BoxInfo from '@nvtracker/common/components/ui/BoxInfo';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import { isValid } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSaveApi } from '../../hooks';
import { useDeviceExtraInfo, useDevicesActions } from '../../store/devices';

function getErrors(formData) {
	console.log('🚀 ~ file: FinishDeviceInstallationDialog.jsx:16 ~ getErrors ~ formData:', formData);
	const err = [];

	if (!formData) return err;

    const {licencePlate, brand, model, installationDate } = formData;
    function checkValidField(value, min, max, key)
    {
        if ((value || '').length < min || (value || '').length > max) return err.push({ id: key, message: 'invalidField' });
    }

    checkValidField(licencePlate, 1, 16, 'licencePlate');
    checkValidField(brand, 1, 100, 'brand');
    checkValidField(model, 1, 100, 'model');
	if (installationDate == null || !isValid(installationDate instanceof Date ? installationDate : new Date(installationDate))) err.push({ id: 'installationDate', message: 'invalidDate' });


	// if ((formData.licencePlate || '').length > 16) err.push({ id: 'licencePlate', message: 'invalidField' });
	// if ((formData.brand || '').length > 100) err.push({ id: 'brand', message: 'invalidField' });
	// if ((formData.model || '').length > 100) err.push({ id: 'model', message: 'invalidField' });



	return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Form({ formData, errors, onChange }) {
	const { t } = useTranslation();

	const updateField = ev => {
		onChange({
			...formData,
			[ev.target.name]: ev.target.value,
		});
	};

	function getMessage(key) {
		const msg = getErrorMessage(errors, key);
		return msg ? t(msg) : '';
	}

	return (
		<>
			<Box display={'grid'} mt={2} gap={4}>
				<TextField
					label={t('licencePlate')}
					value={formData.licencePlate}
					name='licencePlate'
					error={hasError(errors, 'licencePlate')}
					helperText={`${t('charRange')}: [1-16]`}
					onChange={updateField}
					fullWidth
					autoFocus
                    required
					inputProps={{ maxLength: 16 }}
				/>

				<TextField
					label={t('brand')}
					value={formData.brand}
					name='brand'
					onChange={updateField}
					fullWidth
                    required
					error={hasError(errors, 'brand')}
					helperText={`${t('charRange')}: [1-100]`}
					inputProps={{ maxLength: 100 }}
				/>

				<TextField
					label={t('model')}
					value={formData.model}
					name='model'
					error={hasError(errors, 'brand')}
					helperText={`${t('charRange')}: [1-100]`}
					onChange={updateField}
					fullWidth
                    required
					inputProps={{ maxLength: 100 }}
				/>

				<DateTimePicker
					margin=''
					clearable
					label={<Trans>installationDate</Trans>}
					format='yyyy-MM-dd HH:mm:ss'
					value={formData.installationDate}
					id='newDate'
					name='newDate'
					autoComplete='newDate'
					onChange={date => onChange({ ...formData, installationDate: isValid(date) ? date.toISOString() : date })}
					renderInput={params => (
						<TextField
							{...params}
							fullWidth
							variant='outlined'
                            required
							error={hasError(errors, 'installationDate')}
							helperText={getMessage('installationDate')}
						/>
					)}
				/>
			</Box>
		</>
	);
}

const getEmptyForm = ({ licencePlate, brand, model, installationDate }) => ({
	licencePlate: licencePlate || '',
	brand: brand || '',
	model: model || '',
	installationDate: installationDate || new Date().toISOString(),
});

function Content({ device, onSaved, onClose }) {

	const { finishDeviceInstallation } = useDevicesActions();
	const { id, name, serialNumber } = device || {};

	const [extraInfo, fetchingExtraInfo] = useDeviceExtraInfo(id);
	const [updateDeviceForm, setUpdateDeviceForm] = useState(getEmptyForm({}));
	const errors = useMemo(() => getErrors(updateDeviceForm), [updateDeviceForm]);
	const hasErrors = errors !== null;
	const { saveApi, isSaving } = useSaveApi();

	useEffect(() => setUpdateDeviceForm(getEmptyForm(extraInfo || {})), [extraInfo]);

	const handleSave = async () => {
		const res = await saveApi(finishDeviceInstallation(device.id, updateDeviceForm));
		if (res === true) {
			onSaved && onSaved();
			onClose && onClose();
		}
	};

    const loading = fetchingExtraInfo || isSaving;

	return (
		<React.Fragment>
			<DialogTitle>
				<Trans>finishInstallation</Trans>
			</DialogTitle>
			<DialogContent sx={{ position: 'relative', display: 'grid', gap: 2 }}>
				{loading && <OverlapProgress progressProps={{ size: 75 }} />}
				<BoxInfo title={name || '-'} subtitle={serialNumber || '-'} />
				<Divider />
				<Form formData={updateDeviceForm} errors={errors} onChange={value => setUpdateDeviceForm(value)} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} disabled={loading} color='secondary'>
					<Trans>cancel</Trans>
				</Button>
				<ButtonWithProgress onClick={handleSave} color='primary' loading={loading} disabled={hasErrors}>
					<Trans>save</Trans>
				</ButtonWithProgress>
			</DialogActions>
		</React.Fragment>
	);
}

export default function FinishDeviceInstallationDialog({ open, device, onSaved, onClose }) {
	return (
		<Dialog open={open} onClose={onClose}>
			<Content device={device} onClose={onClose} onSaved={onSaved} />
		</Dialog>
	);
}
