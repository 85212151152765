import { Icon } from '@iconify/react';
import { Box, Button, Card, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Label from '@nvapps/common/components/ui/Label';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import SmsInfo from '@nvapps/common/components/ui/SmsInfo';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import useDialogOpener, { useDebounce, useSessionStorage } from '@nvapps/common/hooks';
import { canRenewDevice } from '@nvtracker/common/devices';
import { ContractStatusEnum, PositionCommunications, getStatusColorOfContract, getStatusOfContract, getStatusOfPosition, getStatusOfPositionCommunication } from '@nvtracker/common/helpers';
import { applyFilters } from '@nvtracker/common/utils';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { removeDevice } from '../../../../api/devices';
import { useDeleteApi } from '../../../../hooks';
import CreateDeviceDialog from '../../../../modals/devices/CreateDeviceDialog';
import FinishDeviceInstallationDialog from '../../../../modals/devices/FinishDeviceInstallationDialog';
import RenewalDeviceDialog from '../../../../modals/devices/RenewalDeviceDialog';
import ReplaceDeviceRenewalDateDialog from '../../../../modals/devices/ReplaceDeviceRenewalDateDialog';
import { ViewDeviceDialog } from '../../../../modals/devices/ViewDeviceDialog';
import { useUserDevices } from '../../../../store/contexts/userDevices';
import { usePlansById } from '../../../../store/plans';
import ActionMenu from '../../../Ui/ActionMenu';
import BlinkIcon from '../../../Ui/BlinkIcon';
import DateTime from '../../../Ui/DateTime';
import { GsmState, GsmStateMenuItem } from '../../../Ui/GsmState';
import RepairSerialNumberMenuItem from '../../../Ui/RepairSerialNumberMenuItem';
import DeviceMapDialog from './DeviceMapDialog';

function Toolbar({ userId, searchQuery, onSearch }) {

    const [createDeviceDialogOpen, setCreateDeviceDialogOpen] = useState(false);
    const { t } = useTranslation();
    const handleSaved = () => { }

    return (
        <TableToolbar>
            <SearchBox
                value={searchQuery}
                size="small"
                onChange={event => onSearch(event.target.value)}
                placeholder={`${t('search')}...`}
            />
            <Button variant="outlined" onClick={() => setCreateDeviceDialogOpen(true)}>
                <Trans>registerDevice</Trans>
            </Button>
            <CreateDeviceDialog open={createDeviceDialogOpen} userId={userId} onSaved={handleSaved} onClose={() => setCreateDeviceDialogOpen(false)} />
        </TableToolbar>
    )
}

function BoxLineReset({ children, ...props }) {
    return <Box style={{ lineHeight: 1.5 }} {...props}>
        {children}
    </Box>
}

function ContractCell({ renewalDate, hasPendingPurchase }) {

    const { t } = useTranslation();
    const statusContract = getStatusOfContract(renewalDate);
    const translation = statusContract === ContractStatusEnum.EXPIRED ? 'expired' : 'expires';
    const text = renewalDate ? t(`dates.${translation}`, { date: new Date(renewalDate) }) : '-';

    return <Box display="flex" flexDirection="row" gap={1}>
        <Tooltip title={text}>
            <Box>
                <Label variant="ghost" color={getStatusColorOfContract(renewalDate)}>
                    <Typography variant="subtitle2">
                        <DateTime datetime={renewalDate} format="PPP" />
                    </Typography>
                </Label>
            </Box>
        </Tooltip>
        {hasPendingPurchase && <Tooltip title={<Trans>pendingPurchase</Trans>}>
            <Box color="warning.dark">
                <BlinkIcon icon="ant-design:warning-outlined" height={24} width={24} />
            </Box>
        </Tooltip>}
    </Box>
}


export default function DevicesList({ userId }) {

    const [list, isLoading] = useUserDevices();

    const viewDialog = useDialogOpener({ device: null });
    const mapDialog = useDialogOpener({ device: null });
    const renewDialog = useDialogOpener({ device: null });
    const replaceDeviceRenewalDateDialog = useDialogOpener({ device: null });
    const finishDeviceInstallationDialog = useDialogOpener();

    const [filters, setFilters] = useSessionStorage('usersTabDevicesFilters', { text: '', sortModel: [{ field: 'name', sort: 'asc' }] });
    const [searchQuery, setSearchQuery] = useState(filters.text);

    const { t } = useTranslation();
    const { deleteApi, isDeleting } = useDeleteApi();
    const [plans] = usePlansById();

    useDebounce(() => {
        setFilters(state => ({ ...state, text: searchQuery }));
    }, 200, [searchQuery])


    const handleDeviceDelete = deviceId => deleteApi(removeDevice(deviceId));

    const devicesFiltered = useMemo(() => {
        return applyFilters(list, ['name', 'brand', 'model', 'serialNumber', 'gsmNumber'], filters.text)
    }, [list, filters.text]);

    const handleRenewed = ({ planId, renewalDate }) => {
        const device = list.find(e => e.id === viewDialog.props.device.id);
        if (device && viewDialog.open) viewDialog.handleOpenDialog({ device: { ...viewDialog.props.device, renewalDate, planId } })
    }

    const handleOnChangeSmsState = ({ gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername }) => {
        const device = list.find(e => e.id === viewDialog.props.device.id);
        if (device && viewDialog.open) viewDialog.handleOpenDialog({ device: { ...viewDialog.props.device, gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername } })
    }

    const getPlan = planId => plans && plans[planId] ? plans[planId] : null;

    const handlePositionClick = (e, row) => {
        e.stopPropagation();
        row.position.stateEndTime && mapDialog.handleOpenDialog({ device: row.id });
    }

    const columns = [
        {
            field: 'name',
            headerName: t('name'),
            flex: 0.3,
            minWidth: 150,
            renderCell: ({ row: { name, brand, model } }) =>
                <BoxLineReset ml={1}>
                    <Typography variant="subtitle2" noWrap>
                        {`${name}`}
                    </Typography>
                    <Typography variant="caption" noWrap >
                        {[brand, model].filter(e => e).join(' ')}
                    </Typography>
                </BoxLineReset>
        },
        {
            field: 'serialNumber',
            headerName: t('info'),
            minWidth: 150,
            flex: 0.3,
            renderCell: ({ row: { serialNumber, gsmNumber, smNumber, gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername } }) =>
                <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2" noWrap>
                        {serialNumber}
                    </Typography>
                    <GsmState gsmNumber={gsmNumber} gsmNumberActive={gsmNumberActive} gsmNumberStateDate={gsmNumberStateDate} gsmNumberStateUsername={gsmNumberStateUsername} typographyProps={{ variant: "subtitle2", noWrap: true }} pt={0.5} />
                </Box>
        },
        {
            field: 'smsCredits',
            headerName: t('smsBalance'),
            width: 130,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row: { smsCredits, smsLimit, smsDate } }) => <SmsInfo credits={smsCredits} limit={smsLimit} date={smsDate} placement="top" tooltip={<div>
                <Trans>lastUpdate</Trans>
                <br />
                <DateTime datetime={smsDate} format="PPPpp" />
            </div>} />
        },
        {
            field: 'renewalDate',
            headerName: t('contractValidity'),
            minWidth: 200,
            flex: 0.3,
            renderCell: ({ row: { renewalDate, hasPendingPurchase, planId } }) =>
                <Box display="flex" flexDirection="column" gap={0.5}>
                    {getPlan(planId) &&
                        <Box display="flex" flexDirection="row" gap={0.5}>
                            <Typography variant="subtitle2" noWrap>
                                <Trans>plan</Trans>:
                            </Typography>
                            <Typography variant="body2" noWrap>
                                {getPlan(planId).name}
                            </Typography>
                        </Box>}
                    <ContractCell renewalDate={renewalDate} hasPendingPurchase={hasPendingPurchase} />
                </Box>
        },
        {
            field: 'position',
            headerName: t('status'),
            valueGetter: ({ row }) => t(getStatusOfPosition(row.position.status).text),
            align: 'center',
            headerAlign: 'center',
            width: 150,
            renderCell: ({ row }) => <Tooltip title={row.position.stateEndTime ? t('openMap') : ''} placement="top">
                <Box display="flex" flexDirection="column" gap={1} alignItems="center" onClick={e => handlePositionClick(e, row)} width="100%">
                    {row.position.stateEndTime && <Label color={PositionCommunications[getStatusOfPositionCommunication(row.position.stateEndTime)].color} sx={{ width: "100%", cursor: 'pointer', fontWeight: 600 }}>
                        <DateTime datetime={row.position.stateEndTime} />
                    </Label>}
                    <Label color={'info'} sx={{ width: "100%", cursor: 'pointer' }}>
                        {t(getStatusOfPosition(row.position.status).text)}
                    </Label>
                </Box>
            </Tooltip>
        },
        {
            field: 'options',
            sortable: false,
            renderHeader: _ => <div></div>,
            renderCell: ({ row }) => <ActionMenu onDelete={() => handleDeviceDelete(row.id)} width={250}>
                <MenuItem sx={{ color: 'text.secondary' }} onClick={() => viewDialog.handleOpenDialog({ device: row })}>
                    <ListItemIcon>
                        <Icon icon="eva:search-outline" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary={t('view')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                {canRenewDevice(row) &&
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={() => renewDialog.handleOpenDialog({ device: row })} >
                        <ListItemIcon>
                            <Icon icon="ant-design:calendar-outlined" width={24} height={24} />
                        </ListItemIcon>
                        <ListItemText primary={t('renew')} primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>}
                <GsmStateMenuItem deviceId={row.id} gsmNumberActive={row.gsmNumberActive} />
                <RepairSerialNumberMenuItem deviceId={row.id} />
                <MenuItem sx={{ color: 'text.secondary' }} onClick={() => replaceDeviceRenewalDateDialog.handleOpenDialog(row)} >
                    <ListItemIcon>
                        <Icon icon="ant-design:calendar-outlined" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary={<Trans>changeRenewalDate</Trans>} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                <MenuItem sx={{ color: 'text.secondary' }} onClick={() => finishDeviceInstallationDialog.handleOpenDialog(row)} >
                    <ListItemIcon>
                        <Icon icon="ep:finished" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary={<Trans>finishInstallation</Trans>} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </ActionMenu>,
            align: 'right',
            width: 70
        }];

    return (
        <Card>
            <Toolbar searchQuery={searchQuery} userId={userId} onSearch={text => setSearchQuery(text)} />
            <Box sx={{ height: 400 }}>
                <DataGrid
                    columns={columns}
                    rows={devicesFiltered}
                    loading={isLoading || isDeleting}
                    density="comfortable"
                    pagination
                    onRowClick={({ row }) => viewDialog.handleOpenDialog({ device: row })}
                    getRowId={d => d.id}
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    sortModel={filters.sortModel}
                    rowsPerPageOptions={[5]}
                    onSortModelChange={sortModel => setFilters({ ...filters, sortModel })}
                    pageSize={5} />
            </Box>
            <ViewDeviceDialog
                open={viewDialog.open}
                device={viewDialog.props.device}
                onClose={viewDialog.handleCloseDialog}
                onRenewed={handleRenewed}
                onChangeGsmState={handleOnChangeSmsState}
            />
            <RenewalDeviceDialog open={renewDialog.open} device={renewDialog.props.device} onClose={renewDialog.handleCloseDialog} />
            <DeviceMapDialog open={mapDialog.open} deviceId={mapDialog.props.device} onClose={mapDialog.handleCloseDialog} />
            <ReplaceDeviceRenewalDateDialog device={replaceDeviceRenewalDateDialog.props} open={replaceDeviceRenewalDateDialog.open} onClose={replaceDeviceRenewalDateDialog.handleCloseDialog} />
            <FinishDeviceInstallationDialog device={finishDeviceInstallationDialog.props} open={finishDeviceInstallationDialog.open} onClose={finishDeviceInstallationDialog.handleCloseDialog} />
        </Card>);
}
