import { authRequest, getAuthenticatedImage, usersApiBaseUrl as apiBaseUrl } from "./utils";


export function changePassword(changePasswordForm) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/profile/changePassword`,
        data: changePasswordForm
    });
}

export const getProfileUrl = `${apiBaseUrl}/api/profile`;

export function getProfile() {
    return authRequest({
        method: "GET",
        url: getProfileUrl
    });
}

export function updateProfile(profileForm) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/profile`,
        data: profileForm
    });
}

export function updatePicture(picture) {

    var formData = new FormData();
    formData.append("image", picture);

    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/profile/photo`,
        data : formData,
        headers :
        {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const profilePhotoUrl = `${apiBaseUrl}/api/profile`;

export async function getProfilePhoto(picture)
{
    return picture ? getAuthenticatedImage(`${profilePhotoUrl}/${picture}`) : null;
}

export function getPermissions() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/profile/permissions`,
    });
}

// export function getTimeZones() {
//     return authRequest({
//         method: "GET",
//         url: `${apiBaseUrl}/api/profile/timezones`
//     });
// }