import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import { Icon } from '@iconify/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Container, Skeleton, Stack } from '@mui/material';
import { PhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import TabIcon from '@nvapps/common/components/ui/TabIcon';
import { useIsMobile } from '@nvapps/common/hooks';
import BoxInfo from '@nvtracker/common/components/ui/BoxInfo';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import TabDevices from '../../../components/Users/Edit/TabDevices';
import TabDevicesRegistered from '../../../components/Users/Edit/TabDevicesRegistered';
import TabEvents from '../../../components/Users/Edit/TabEvents';
import TabFiles from '../../../components/Users/Edit/TabFiles';
import TabGeneral from '../../../components/Users/Edit/TabGeneral';
import TabInvoices from '../../../components/Users/Edit/TabInvoices';
import TabPurchases from '../../../components/Users/Edit/TabPurchases';
import { UserDevicesProvider } from '../../../store/contexts/userDevices';
import { EventsProvider } from '../../../store/contexts/userEvents';
import { InvoicesProvider } from '../../../store/contexts/userInvoices';
import { PurchasesProvider } from '../../../store/contexts/userPurchases';
import { useUserCanSeeDevicesRegistered } from '../../../store/user';
import { useUser } from '../../../store/users';

function Content({ userId }) {

    const [user, isLoading, error] = useUser(userId);
    const [profile, setProfile] = useState(user.profile);
    const [permissions] = useUserCanSeeDevicesRegistered();
    const isMobile = useIsMobile();
    const tabDirection = isMobile ? 'vertical' : 'horizontal';
    const { t } = useTranslation();
    const { tab } = useParams();
    const userUrl = `/app/clients/${userId}`;
    const navigate = useNavigate();
    const showDevicesRegistered = permissions && permissions.accessTokens;

    useEffect(() => setProfile(user.profile), [user.profile]);

    error && error.response && error.response.status === 404 && navigate('/app/clients/list');

    return (
        <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <BoxInfo
                    title={isLoading ? <Skeleton /> : profile.name || '-'}
                    subtitle={isLoading ? <Skeleton /> : profile.userName || '-'}
                    subtitle2={isLoading ? <Skeleton /> : <PhoneNumber value={profile.phoneNumber} />} />
                <Button
                    variant="outlined"
                    component={RouterLink}
                    to="/app/clients/list"
                    startIcon={<Icon icon={arrowBackFill} />}
                >
                    <Trans>
                        back
                    </Trans>
                </Button>
            </Stack>
            <TabContext value={tab || 'general'}>
                <Box mb={3} sx={{ borderColor: 'divider' }}>
                    <TabList>
                        <TabIcon label={t('data')} component={RouterLink} to={`${userUrl}/general`} disableRipple icon={<Icon height="20" icon="bx:bxs-user-rectangle" />} value="general" direction={tabDirection} />
                        <TabIcon label={t('devices')} component={RouterLink} to={`${userUrl}/devices`} disableRipple icon={<Icon height="20" icon="fluent:device-meeting-room-16-filled" />} value="devices" direction={tabDirection} />
                        <TabIcon label={t('purchases')} component={RouterLink} to={`${userUrl}/billing`} disableRipple icon={<Icon height="20" icon="icon-park-outline:buy" />} value="billing" direction={tabDirection} />
                        <TabIcon label={t('invoices')} component={RouterLink} to={`${userUrl}/invoices`} disableRipple icon={<Icon height="20" icon="mdi:invoice" />} value="invoices" direction={tabDirection} />
                        <TabIcon label={t('notifications')} component={RouterLink} to={`${userUrl}/notifications`} disableRipple icon={<Icon height="20" icon="material-symbols:notifications-outline" />} value="notifications" direction={tabDirection} />
                        <TabIcon label={t('files')} component={RouterLink} to={`${userUrl}/files`} disableRipple icon={<Icon height="20" icon="eva:file-fill" />} value="files" direction={tabDirection} />
                        {showDevicesRegistered && <TabIcon label={t('devicesRegistered')} component={RouterLink} to={`${userUrl}/devicesRegistered`} disableRipple icon={<Icon height="20" icon="material-symbols:smartphone" />} value="devicesRegistered" direction={tabDirection} />}
                    </TabList>
                </Box>
                <TabPanel sx={{ p: 0 }} value="general">
                    <TabGeneral userId={userId} onSaved={p => setProfile(p)} />
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="devices" >
                    <TabDevices userId={userId} />
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="billing">
                    <TabPurchases />
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="invoices">
                    <TabInvoices userId={userId} />
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="notifications">
                    <TabEvents userId={userId} />
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="files">
                    <TabFiles userId={userId} />
                </TabPanel>
                {showDevicesRegistered &&
                    <TabPanel sx={{ p: 0 }} value="devicesRegistered">
                        <TabDevicesRegistered userId={userId} />
                    </TabPanel>}
            </TabContext>
        </Container>
    )
}

export default function EditUser() {

    const { id } = useParams();

    return (
        <UserDevicesProvider userId={id}>
            <PurchasesProvider userId={id}>
                <InvoicesProvider userId={id}>
                    <EventsProvider userId={id}>
                        <Content userId={id} />
                    </EventsProvider>
                </InvoicesProvider>
            </PurchasesProvider>
        </UserDevicesProvider>);
}