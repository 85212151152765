import { Card } from '@mui/material';
import { useCallApi } from '../../../../hooks';
import { DocumentsProvider, useDocuments, useDocumentsActions } from '../../../../store/contexts/documents';
import { FileManager } from '../../../Ui/FileManager';

function Content({ userId }) {

    const { addFile, createFolder, updateFolder, refresh, deleteDocument } = useDocumentsActions();
    const { callApi, isLoading } = useCallApi();
    const [list, isFetching] = useDocuments();

    const parseParentId = parent => parent ? parseInt(parent) : null;

    const onUploadFile = async (file, parent, callBack) => {

        const res = await callApi(addFile(file, parseParentId(parent)));
        if (res === true) {
            if (callBack) callBack(true);
            refresh();
        }
    }

    const onCreateFolder = async (name, parent, callBack) => {
        var res = await callApi(createFolder(name, parseParentId(parent)));
        if (res === true) {
            if (callBack) callBack(true);
            refresh();
        }
    }

    const onUpdateFolder = async (id, name, parent, callBack) => {
        var res = await callApi(updateFolder(id, name, parseParentId(parent)));
        if (res === true) {
            if (callBack) callBack(true);
            refresh();
        }
    }

    const onDelete = async (id) => {
        var res = await callApi(deleteDocument(id));
        if (res === true) refresh();
    }

    return <Card sx={{ height: 650 }}>
        <FileManager
            items={list}
            loading={isFetching || isLoading}
            onUploadFile={onUploadFile}
            onCreateFolder={onCreateFolder}
            onUpdateFolder={onUpdateFolder}
            onDelete={onDelete} />
    </Card>
}

export default function TabFiles({ userId, onSaved }) {

    return <DocumentsProvider path={`clients/${userId}`}>
        <Content />
    </DocumentsProvider>
}