import { Icon } from '@iconify/react';
import {
    Avatar, Box, Button, Card, Container, IconButton, ListItemIcon, ListItemText, MenuItem, Stack, Tooltip, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Flag } from '@nvapps/common/components/ui/Flags';
import Label from '@nvapps/common/components/ui/Label';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import { PhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import { getCountryOptionsById } from '@nvapps/common/utils';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MoreMenu from '../../components/Ui/MoreMenu';
import { useUserPhoto, useUsers, useUsersLoading } from '../../store/users';
import { useUsersGridActions } from './UsersLogic';

function UserAvatar({ id, src, alt })
{
    const [photo, isLoading] = useUserPhoto(id, src);

    return <Box position={"relative"} >
        {isLoading && <OverlapProgress />}
        <Avatar alt={src} src={photo} />
    </Box>
}

export default function Clients() {

    const { users, rowCount } = useUsers();
    const loading = useUsersLoading();
    const { pageSize, sortModel, searchQuery, selectedRows, changePage, changePageSize, selectRows, search, sort, fetchUsers, exportUsers, isExporting, page } = useUsersGridActions();
    const countryOptionsById = getCountryOptionsById();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const columns = useMemo(() => [{
        field: 'Name',
        headerName: t('username'),
        minWidth: 200,
        flex: 0.4,
        renderCell : ({ row }) => <Stack direction="row" alignItems="center" spacing={2}>
            <UserAvatar id={row.id} alt={row.name} src={row.picture} />
            <Box sx={{ lineHeight : 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${row.name || row.username}`}
                </Typography>
                {row.name &&
                 <Typography variant="caption" noWrap>
                    {`${row.username}`}
                </Typography>}
            </Box>
        </Stack>
    },
    {
        field: 'PhoneNumber',
        headerName: t('phoneNumber'),
        width: 150,
        renderCell: ({ row }) => <PhoneNumber value={row.phoneNumber} />
    },
    {
        field: 'NumberOfDevices',
        headerName: t('devices'),
        width: 150,
        align : 'center',
        headerAlign : 'center',
        renderCell: ({ row }) => row.numberOfDevices
    },
    {
        field: 'Validated',
        sortable: true,
        headerName: t('validated'),
        align: 'center',
        headerAlign: 'center',
        width : 120,
        renderCell: ({ row }) => <Label variant="ghost" color={row.validated ? 'success' : 'error'} >
            {t(row.validated ? 'yes' : 'no')}
        </Label>
    },
    {
        field: 'Country',
        sortable: false,
        headerName: t('country'),
        align: 'center',
        headerAlign: 'center',
        width: 100,
        renderCell: ({ row }) => <Tooltip title={countryOptionsById[row.country || 'PT'].label} placement="top">
                <Box><Flag country={row.country} /></Box>
        </Tooltip>
    },
    {
        field: 'Options',
        renderHeader: () => <div></div>,
        sortable: false,
        renderCell: ({ row }) => <Tooltip title={t('seeDetails')} placement="top">
            <IconButton>
                <Icon icon="eva:search-outline" width={24} height={24} />
            </IconButton>
        </Tooltip>,
        align: 'right',
        width: 70
    }
    ], [countryOptionsById, t]);

    return (
        <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h4" >
                    <Trans>clients</Trans>
                </Typography>
            </Stack>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TableToolbar>
                     <SearchBox
                        value={searchQuery}
                        size="small"
                        onChange={event => search(event.target.value)}
                        placeholder={`${t('search')}...`}
                    />
                    <Button
                        sx={{ ml: 'auto', mr : 1 }}
                        variant="outlined"
                        onClick={() => fetchUsers()}
                        startIcon={<Icon icon="eva:refresh-fill" />}>
                        <Trans>refresh</Trans>
                    </Button>
                     <MoreMenu>
                        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => exportUsers()} >
                            <ListItemIcon>
                                <Icon icon="ic:round-print" width={24} height={24} />
                            </ListItemIcon>
                            <ListItemText primary={t('export')} primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>
                    </MoreMenu>
                </TableToolbar>
                <Box sx={{ flex: 1, width: '100%' }}>
                    <DataGrid
                        rows={users}
                        columns={columns}
                        pageSize={pageSize}
                        page={page}
                        rowsPerPageOptions={[5, 10, 25]}
                        rowCount={rowCount}
                        loading={loading || isExporting}
                        sortModel={sortModel}
                        selectionModel={selectedRows}
                        density="comfortable"
                        paginationMode="server"
                        sortingMode="server"
                        disableColumnMenu
                        onRowClick={row => navigate(`/app/clients/${row.id}`) }
                        hideFooterSelectedRowCount
                        pagination
                        onSortModelChange={sort}
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        onSelectionModelChange={selectRows}
                    />
                </Box>
            </Card>
        </Container>

    )
}