import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Box, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from "@mui/material";
import Label from "@nvapps/common/components/ui/Label";
import { PhoneNumber } from "@nvapps/common/components/ui/PhoneInput";
import React, { useRef, useState } from "react";
import { Trans } from "react-i18next";
import { useCallApi } from "../../hooks";
import { useDevicesActions } from "../../store/devices";
import ConfirmPopover from "./ConfirmPopover";
import DateTime from "./DateTime";

/*function NotificationsSettingsButton({ device }) {

    const { open, handleOpenDialog, handleCloseDialog } = useDialogOpener();

    return '';

    return <>
        <Button onClick={() => handleOpenDialog()} startIcon={<Icon icon="ic:baseline-notifications-none" width={24} height={24} />}>
            <Trans>notificationsSettings</Trans>
        </Button>
        <NotificationsSettingsDialog
            open={open}
            device={device}
            onClose={handleCloseDialog} />
    </>
}*/

export function GsmStateButton({ deviceId, gsmNumberActive, onChangeGsmState }) {
    const ref = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const active = Boolean(gsmNumberActive);
    const { activateDeviceGsm, suspendDeviceGsm } = useDevicesActions();
    const { callApi, isLoading } = useCallApi();

    const toggleGsmState = () => {

        callApi((active ? suspendDeviceGsm(deviceId) : activateDeviceGsm(deviceId)).then(r => {

            onChangeGsmState && onChangeGsmState(r.data)
        }));
    }

    return <>
        <ConfirmPopover
            text={Boolean(gsmNumberActive) ? <Trans>suspendGsmQuestion</Trans> : <Trans>activeGsmQuestion</Trans>}
            open={confirmOpen}
            anchorEl={ref.current}
            onClose={() => setConfirmOpen(false)} onResolve={v => Boolean(v) && toggleGsmState()}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
        <LoadingButton ref={ref} loading={isLoading} loadingPosition="start" onClick={() => setConfirmOpen(true)} startIcon={<Icon icon="ic:baseline-cell-tower" width={24} height={24} />}>
            {Boolean(gsmNumberActive) ? <Trans>suspendGsm</Trans> : <Trans>activateGsm</Trans>}
        </LoadingButton>
    </>
}

export function GsmStateMenuItem({ deviceId, gsmNumberActive, onChangeGsmState }) {
    const ref = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const active = Boolean(gsmNumberActive);
    const { activateDeviceGsm, suspendDeviceGsm } = useDevicesActions();
    const { callApi } = useCallApi();

    const toggleGsmState = () => {

        callApi((active ? suspendDeviceGsm(deviceId) : activateDeviceGsm(deviceId)).then(r => {

            onChangeGsmState && onChangeGsmState(r.data)
        }));
    }

    const handleClick = ev =>
    {
        ev.stopPropagation();
        setConfirmOpen(true)
    }

    return <>
        <ConfirmPopover
            text={Boolean(gsmNumberActive) ? <Trans>suspendGsmQuestion</Trans> : <Trans>activeGsmQuestion</Trans>}
            open={confirmOpen}
            anchorEl={ref.current}
            onClose={() => setConfirmOpen(false)} onResolve={v => Boolean(v) && toggleGsmState()}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
        <MenuItem sx={{ color: 'text.secondary' }} ref={ref} onClick={handleClick} >
            <ListItemIcon>
                <Icon icon="ic:baseline-cell-tower" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={Boolean(gsmNumberActive) ? <Trans>suspendGsm</Trans> : <Trans>activateGsm</Trans>} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
    </>
}

export function GsmState({ gsmNumber, gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername, typographyProps, ...props }) {
    const active = Boolean(gsmNumberActive);
    const typeProps = typographyProps || {}

    return <Tooltip title={active ? '' : <Box textAlign={"center"}>
        <Trans>gsmSuspended</Trans><br />
        <DateTime datetime={gsmNumberStateDate} /><br />
        {gsmNumberStateUsername}
    </Box>}>
        <Box {...props} >
            <Label color={active ? 'success' : 'error'}>
                <Icon style={{ marginRight: 5 }} icon={active ? "mdi:cellphone-check" : 'mdi:cellphone-remove'} width={18} height={18} />
                <Typography variant="subtitle2" {...typeProps}>
                    <PhoneNumber value={gsmNumber} />
                </Typography>
            </Label>
        </Box>
    </Tooltip>;
}
