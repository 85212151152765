import { Icon } from "@iconify/react";
import { Box, Button, DialogActions, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { SearchBox, SearchTextField } from "@nvapps/common/components/ui/SearchBox";
import { useIsMobile } from "@nvapps/common/hooks";
import Dialog from '@nvapps/common/components/ui/Dialog';
import { applyFilters } from "@nvtracker/common/utils";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDevicesAvailable } from "../../store/devices";
import DateTime from "../../components/Ui/DateTime";
import Label from "@nvapps/common/components/ui/Label";
import { ContractStatusEnum, getStatusColorOfContract, getStatusOfContract } from "@nvtracker/common/helpers";

function ContractCell({ renewalDate, hasPendingPurchase }) {

    const { t } = useTranslation();
    const statusContract = getStatusOfContract(renewalDate);
    const translation = statusContract === ContractStatusEnum.EXPIRED ? 'expired' : 'expires';
    const text = renewalDate ? t(`dates.${translation}`, { date : new Date(renewalDate) }) : '-';

    return <Box display="flex" flexDirection="row" gap={1}>
        <Tooltip title={text}>
            <Box>
                <Label variant="ghost" color={getStatusColorOfContract(renewalDate)}>
                    <Typography variant="subtitle2">
                        <DateTime datetime={renewalDate} format="PPP" />
                    </Typography>
                </Label>
            </Box>
        </Tooltip>
    </Box>
}

function List({ devices = [], deviceTypeId, isLoading, isMobile, onSelect }) {
    const { t } = useTranslation();

    const columns =
        [{
            field: 'serialNumber',
            headerName: t('serialNumber'),
            flex: 0.3,
            minWidth: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'gsmNumber',
            headerName: t('gsmNumber'),
            flex: 0.3,
            minWidth: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'equipmentVersion',
            headerName: t('version'),
            flex: 0.3,
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => row.equipmentVersion || '-'
        },
        {
            field: 'renewalDate',
            headerName: t('oldContract'),
            minWidth: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => row.renewalDate,
            renderCell: ({ row: { renewalDate, planName } }) => Boolean(renewalDate) === false && Boolean(planName) === false ? <Trans>n_A</Trans> :
            <Box display="flex" alignItems='center' flexDirection="column" gap={0.5}>
                {Boolean(planName) === true &&
                <Box display="flex" flexDirection="row" gap={0.5}>
                    <Typography variant="subtitle2" noWrap>
                        <Trans>plan</Trans>:
                    </Typography>
                    <Typography variant="body2" noWrap>
                        {planName}
                    </Typography>
                </Box>}
                {Boolean(renewalDate) && <ContractCell renewalDate={renewalDate} />}
            </Box>
        },
        {
            field: 'options',
            sortable: false,
            renderHeader: _ => <div></div>,
            align: 'center',
            renderCell: ({ row }) => <Tooltip title={t('selectDevice')} placement="top">
                <IconButton onClick={() => onSelect(row)}  >
                    <Icon icon='eva:arrow-forward-fill' width={24} height={24} />
                </IconButton>
            </Tooltip>,
            width: 70
        }];

    return (
        <Box sx={{ height: isMobile ? '100%' : 600 }}>
            <DataGrid
                columns={columns}
                rows={devices || []}
                loading={isLoading}
                density="comfortable"
                onRowClick={({ row }) => onSelect(row)}
                isRowSelectable={({ row }) => row.equipmentVersion}
                pagination
                getRowId={d => d.serialNumber}
                hideFooterSelectedRowCount
                disableColumnMenu
                pageSize={10} />
        </Box>
    );

}

function Filters({ searchText, isMobile, onChangeSearchText, onRefresh }) {

    const { t } = useTranslation();

    return (
        <Box pb={1} px={3} display="flex" alignItems="center">
            {isMobile ?
            <SearchTextField
                value={searchText}
                placeholder={`${t('search')}...`}
                sx={{ mr : 2 }}
                onChange={ev => onChangeSearchText(ev.target.value)}/>
            :
            <SearchBox
                value={searchText}
                placeholder={`${t('search')}...`}
                onChange={ev => onChangeSearchText(ev.target.value)}
            />}
            <Box sx={{ ml : 'auto'}}>
                <Button variant="outlined" size="medium" onClick={onRefresh} startIcon={<Icon icon='eva:refresh-fill' />}>
                    <Trans>refresh</Trans>
                </Button>
            </Box>
        </Box>
    )
}

function Content({ deviceTypeId, onSelect }) {

    const [searchText, setSearchText] = useState('');
    const isMobile = useIsMobile();
    const [data, isLoading,, reFetch] = useDevicesAvailable();
    const filteredData = useMemo(() => applyFilters(data, ["serialNumber", "gsmNumber", "version"], searchText), [searchText, data]);

    const handleSelect = device => onSelect(device);

    return (
        <>
            <Filters
                searchText={searchText}
                isMobile={isMobile}
                onChangeSearchText={value => setSearchText(value)}
                onRefresh={() => reFetch()} />
            <List
                devices={filteredData}
                isMobile={isMobile}
                isLoading={isLoading}
                onSelect={d => handleSelect(d)} />
        </>
    );
}

export default function SelectDeviceDialog({ open, deviceTypeId, onSelect, onClose }) {

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>
                <Trans>selectDevice</Trans>
            </DialogTitle>
            <Content deviceTypeId={deviceTypeId} onSelect={onSelect} />
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    <Trans>close</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
}