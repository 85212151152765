import { Box, Card, CardHeader, Divider, Stack } from '@mui/material';
import { useIsMobile } from '@nvapps/common/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberTextCard from '../../../Ui/NumberTextCard';
import { useDevicesContractsSummary } from '../../../../store/contexts/userDevices';
import { ContractStatus } from '@nvtracker/common/helpers';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';

export default function ContractsCard() {

    const { t } = useTranslation();
    const [summary, isLoading] = useDevicesContractsSummary();
    const isMobile = useIsMobile('sm');
    const orientation = !isMobile ? 'vertical' : 'horizontal';

    return (
        <Card>
            <CardHeader title={t('contracts')} />
            <Box p={2} position={"relative"}>
                {isLoading && <OverlapProgress />}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    divider={<Divider orientation={orientation} flexItem />}
                    justifyContent="space-evenly"
                    alignItems="center"
                    textAlign="center"
                    spacing={2}>
                    <NumberTextCard value={summary.Expired} text={t(ContractStatus.Expired.text)} color='error' />
                    <NumberTextCard value={summary.Expiring} text={t(ContractStatus.Expiring.text)} color='warning' />
                    <NumberTextCard value={summary.Active} text={t(ContractStatus.Active.text)} color='success' />
                </Stack>
            </Box>
        </Card>
    );
}
