import { useDebounce } from "@nvapps/common/hooks";
import { useState } from "react";


export function useDevicesGridActions(actions, filters)
{
    const [selectedRows, setSelectedRows] = useState([]);
    const { searchText, page, pageSize, sortBy, sortDesc } = filters();
    const [searchQuery, setSearchQuery] = useState(searchText);
    const { updateFilters, fetchDevices } = actions();

    const onSearch = t => setSearchQuery(t);
    const sort = sortModel =>
    {
        if (sortModel && sortModel.length > 0) updateFilters({ sortBy : sortModel[0].field, sortDesc : sortModel[0].sort === 'desc' });
        else updateFilters({ sortBy : null, sortDesc : false });
    };
    const changePage = page => updateFilters({ page });
    const changePageSize = pageSize => updateFilters({ pageSize });
    const selectRows = rows => setSelectedRows(rows);

    useDebounce(() => {
        updateFilters({ searchText: searchQuery, page : 0 });
    }, 200, [searchQuery])

    return {
        page,
        pageSize,
        sortModel : sortBy ? [{ field : sortBy, sort : sortDesc ? 'desc' : 'asc' }] : [],
        searchQuery,
        selectedRows,

        changePage,
        changePageSize,
        selectRows,
        onSearch,
        sort,
        fetchDevices
    }
}