import { Icon } from "@iconify/react";
import {
    Box, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, Stack, Typography
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ButtonPopover from "@nvapps/common/components/ui/ButtonPopover";
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import Dialog from "@nvapps/common/components/ui/Dialog";
import { Field, FieldList } from "@nvapps/common/components/ui/Fields";
import Label from "@nvapps/common/components/ui/Label";
import useDialogOpener from "@nvapps/common/hooks";
import { PaymentTypes } from "@nvtracker/common/helpers";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { updateInvoice } from "../../api/invoices";
import DateTime from '../../components/Ui/DateTime';
import FileManagerDialog from "../../components/Ui/FileManager";
import { useSaveApi } from "../../hooks";
import { usePaidPurchasesOfDevice } from "../../store/contexts/userPurchases";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(purchaseId, purchases, theme) {
    return {
        fontWeight:
            purchases.indexOf(purchaseId) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function Content({ userId, invoice, onClose }) {

    const { t } = useTranslation();
    const fileManagerDialog = useDialogOpener();
    const [purchases, setPurchases] = useState(invoice.purchases || []);
    const [purchasesAvailable, isFetchingPurchases] = usePaidPurchasesOfDevice(userId, invoice.deviceId);
    const theme = useTheme();
    const { saveApi, isSaving } = useSaveApi();

    const handleSave = async () => {
        const res = await saveApi(updateInvoice(invoice.id, { purchases }));
        if (res === true) onClose && onClose();
    }

    const purchasesOrdered = useMemo(() => (purchasesAvailable || []).sort((a, b) => {

        if (a.statusDate < b.statusDate) return 1;
        else if (a.statusDate > b.statusDate) return -1;
        return 0;
    }), [purchasesAvailable])

    const handleChange = (event) => {

        const { target: { value }, } = event;

        setPurchases(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return <>
        <DialogContent>
            <FieldList >
                <Field px={0} title={t('number')} value={invoice.number} />
                <Field px={0} title={t('device')} value={invoice.deviceSerialNumber} />
                <Field px={0} title={t('date')} value={<DateTime datetime={invoice.date} format="PPP p" />} />
                <Field px={0} title={t('created')} value={<DateTime datetime={invoice.creationDate} format="PPP p" />} />
                <Field px={0} title={t('obs')} value={invoice.obs || t('n_A')} />
            </FieldList>
            <Divider sx={{ mt: 3, mb: 2 }}>
                <Typography variant="h6" >
                    <Trans>purchases</Trans>
                </Typography>
            </Divider>
            <Box display="grid" gridTemplateColumns="1fr auto" gap={1}>
                <FormControl fullWidth size="small">
                    <InputLabel>
                        <Trans>purchases</Trans>
                    </InputLabel>
                    <Select
                        multiple
                        value={purchases}
                        onChange={handleChange}
                        input={
                            <OutlinedInput label={<Trans>purchases</Trans>} />
                        }
                        renderValue={(selected) => {
                            if (selected.length === 0) return <Trans>purchases</Trans>;
                            return selected.join(', ');
                        }}
                        MenuProps={MenuProps}
                    >
                        {purchasesOrdered.map((purchase) => (
                            <MenuItem
                                key={purchase.id}
                                value={purchase.id}
                                style={getStyles(purchase.id, purchases, theme)}
                            >
                                <Box>
                                    <Typography variant="subtitle2" sx={{ display: 'block' }}>
                                        {purchase.id}
                                    </Typography>
                                    <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
                                        <DateTime datetime={purchase.statusDate} />
                                    </Typography>
                                </Box>
                                <Label color='info' style={{ position: 'absolute', right: 5 }}>{t((PaymentTypes[purchase.paymentType] || PaymentTypes.Other).text)}</Label>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button size="small" variant="outlined" onClick={() => setPurchases([])} >
                    <Trans>clear</Trans>
                </Button>
            </Box>
        </DialogContent>
        <DialogActions>
            <ButtonPopover variant="outlined" size="small" position="top" text={t('options')} sx={{ mr: 'auto' }}>
                <MenuItem sx={{ color: 'text.secondary' }} onClick={fileManagerDialog.handleOpenDialog} >
                    <ListItemIcon sx={{ mr: 1.5 }}>
                        <Icon icon="eva:file-fill" width={14} />
                    </ListItemIcon>
                    <ListItemText primary={t('files')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </ButtonPopover>
            <Button onClick={onClose} disabled={isSaving} color="secondary">
                <Trans>cancel</Trans>
            </Button>
            <ButtonWithProgress
                onClick={handleSave}
                disabled={isSaving || isFetchingPurchases}
                color="primary"
                loading={isSaving || isFetchingPurchases}>
                <Trans>save</Trans>
            </ButtonWithProgress>
        </DialogActions>
        <FileManagerDialog
            open={fileManagerDialog.open}
            title={`${t('invoice')} ${invoice.id}`}
            path={`clients/${userId}/invoices/${invoice.id}`}
            onClose={fileManagerDialog.handleCloseDialog} />
    </>
}

export default function InvoiceDetailsDialog({ open, userId, onClose, invoice }) {

    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose} >
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {t('invoice')} - {invoice.id}
                </Stack>
            </DialogTitle>
            <Content userId={userId} invoice={invoice} onClose={onClose} />
        </Dialog>
    );
}