import axios from 'axios';
import { authRequest } from '../../api/utils';

const cancelTokens = {};

function normalizeData(data, idProp) {
    return {
        byId: Object.fromEntries(data.map(d => [d[idProp], d])),
        allIds: data.map(d => d[idProp])
    }
}

export function groupData(data, idProp)
{
    let group = data.reduce((r, a) =>
    {
        r[a[idProp]] = [...r[a[idProp]] || [], a];
        return r;
    }, {});

    return {
        byId: group,
        allIds: Object.keys(group)
    }
}


function cancelApiRequest(actionType) {
    if (cancelTokens[actionType]) cancelTokens[actionType].cancel();
}

export const parseParams = (params) =>
{
    if (!params) return '';

    const keys = Object.keys(params);
    let options = '';

    keys.forEach((key) =>
    {
        if (params[key] != null)
        {
            const isParamTypeObject = typeof params[key] === 'object';
            const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

            if (!isParamTypeObject) {
                options += `${key}=${params[key]}&`;
            }

            if (isParamTypeObject && isParamTypeArray) {
                params[key].forEach((element) => {
                    options += `${key}=${element}&`;
                });
            }
        }

    });

  return options ? options.slice(0, -1) : options;
};

function handleApiRequest(actionType, apiRequest, dispatch) {
    const { method = "GET", url, data, params, startActionType, successActionType, errorActionType, normalizeBy, groupBy } = apiRequest;
    if (startActionType) dispatch({ type: startActionType });

    const source = axios.CancelToken.source();

    cancelTokens[actionType] = source;

    authRequest({ method, url, data, params, cancelToken: source.token, paramsSerializer : params => parseParams(params) })
        .then(result => {
            if (successActionType) {
                dispatch({
                    type: successActionType,
                    payload: normalizeBy ? normalizeData(result.data, normalizeBy) : groupBy ? groupData(result.data, groupBy) : result.data
                });
            }
        })
        .catch(error => {
            console.error(error);
            if (!axios.isCancel(error) && errorActionType) dispatch({ type: errorActionType });
        })
        .finally(() => {
            if (source === cancelTokens[actionType]) delete cancelTokens[actionType];
        });
}

const apiMiddleware = ({ dispatch }) => next => action => {
    next(action);
    if (!action.meta) return;

    if (action.meta.apiRequest) {
        cancelApiRequest(action.type);
        handleApiRequest(action.type, action.meta.apiRequest, dispatch);
    } else if (action.meta.cancelApiRequest) {
        cancelApiRequest(action.meta.cancelApiRequest.startedBy);
    }
}

export default apiMiddleware;
