import { useFetchV2 } from "@nvapps/common/hooks";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import * as purchasesApi from "../../api/purchases";
import { registerSubscription, unregisterSubscription } from "../../mqtt";
import { PaymentTypesEnum, PurchasesStatusEnum } from "@nvtracker/common/helpers";

const initialState = {
    currentState: {
        isLoading: false,
        userId: null,
        list: []
    },
    actions: {
        createPurchase(data) { },
        cancelPurchase(id) { }
    }
};

const PurchasesContext = React.createContext(initialState);

const comparePurchaseDate = (a, b) => {
    return a.creationDate > b.creationDate ? -1 : 1;
}

export function PurchasesProvider({ children, userId }) {
    const [currentState, setCurrentState] = useState({ ...initialState.currentState, userId });
    const isSubscribed = useRef(false);

    const actions = useMemo(() => {
        return {
            createPurchase(deviceIds) {
                return purchasesApi.createPurchase(userId, deviceIds);
            },
            cancelPurchase(id) {
                return purchasesApi.cancelPurchase(id);
            },
            annulPurchase(id, password) {
                return purchasesApi.annulPurchase(id, password);
            }
        }
    }, [userId]);

    // Due to change user without leaving the user screen (ex. search bar)
    useEffect(() => setCurrentState(state => ({ ...state, userId })), [userId]);

    useEffect(() => {
        const subTopic = `users/${userId}/nvtracker/purchases/#`;
        const setPurchases = (setFn) => setCurrentState(state => ({
            ...state,
            list: setFn(state.list).slice().sort(comparePurchaseDate)
        }));
        const setIsLoading = (isLoading) => setCurrentState(state => ({ ...state, isLoading }));
        const mqttSub = function (topic, data) {
            const [, id] = topic.match(/.+\/(\w+)$/);
            switch (data.action) {
                case "add":
                    return setPurchases(purchases => [...purchases, data.payload]);
                case "update":
                    return setPurchases(purchases => purchases.map(d => d.id === data.payload.id ? data.payload : d));
                case "delete":
                    return setPurchases(purchases => purchases.filter(d => d.id !== id));
                default:
                    throw new Error("Invalid action");
            }
        };

        isSubscribed.current = true;
        setIsLoading(true);
        purchasesApi.getPurchases(userId).then(res => {
            if (isSubscribed.current) {
                setPurchases(_ => res.data);
                setIsLoading(false);
                registerSubscription(subTopic, mqttSub);
            }
        });

        return () => {
            isSubscribed.current = false;
            unregisterSubscription(subTopic, mqttSub);
        }
    }, [userId]);

    return (
        <PurchasesContext.Provider value={{ currentState, actions }}>
            {children}
        </PurchasesContext.Provider>
    )
}

export function usePurchases() {
    const { currentState } = useContext(PurchasesContext);
    return [currentState.list, currentState.isLoading];
}

export function usePurchaseActions() {
    const { actions } = useContext(PurchasesContext);
    return actions;
}

export function usePurchaseUserId() {
    const { currentState } = useContext(PurchasesContext);
    return useMemo(() => currentState.userId, [currentState.userId]);
}

export function usePaidPurchasesOfDevice(userId, deviceId) {
    return useFetchV2(() => Boolean(userId) && Boolean(deviceId) ? purchasesApi.getPaidPurchasesOfDevice(userId, deviceId) : Promise.resolve([]), [userId, deviceId]);
}

export function usePurchaseAnnulmentState() {
    const [list, loading] = usePurchases();
    return useMemo(() => {
        const sorted = list.sort((a, b) => b.id - a.id);

        const lastValidPurchase = sorted.filter(e => e.status === PurchasesStatusEnum.PAID || e.status === PurchasesStatusEnum.PENDING)[0];

        const canAnnul = lastValidPurchase != null && lastValidPurchase.status !== PurchasesStatusEnum.PENDING && lastValidPurchase.paymentType === PaymentTypesEnum.OTHER;

        return [canAnnul ? sorted.filter(e => e.status === PurchasesStatusEnum.PAID)[0] : null, loading];

    }, [list, loading]);
}