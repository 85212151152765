import { urlEncode } from "@nvapps/common/utils";
import { authRequest, getAuthenticatedImage, usersApiBaseUrl as apiBaseUrl } from "./utils";

export function getUsers(filter, page) {
    let url = `${apiBaseUrl}/api/users?page=${page}`;
    if (filter) url += `&filter=${urlEncode(filter)}`;
    return authRequest({
        method: "GET",
        url
    });
}

export const getUsersPagedUrl = `${apiBaseUrl}/api/users/paged`

export function getUsersPaged({ searchText, page = 0, pageSize, orderBy, orderDesc }) {
    let url = `${getUsersPagedUrl}?page=${page}`;
    if (searchText) url += `&filter=${urlEncode(searchText)}`;
    if (pageSize) url += `&pageSize=${pageSize}`;
    if (orderBy) url += `&orderBy=${orderBy}`;
    if (orderDesc) url += `&orderDesc=${orderDesc}`;

    return authRequest({
        method: "GET",
        url
    });
}

export function exportUsers({ searchText, sortBy, sortDesc })
{
    let url = `${apiBaseUrl}/api/users/users.xls?`;

    if (searchText) url += `&searchText=${urlEncode(searchText)}`;
    if (sortBy) url += `&sortBy=${sortBy}`;
    if (sortDesc) url += `&sortDesc=${sortDesc}`;

    return authRequest({
        method: "GET",
        url,
        responseType : 'blob'
    });
}

export function getUser(id) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/users/${id}`
    });
}

export function getProfile(id) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/users/${id}/profile`
    });
}

export function updateProfile(id, profile) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/users/${id}/profile`,
        data: profile
    });
}

export function getBillingInformation(id) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/users/${id}/billingInformation`
    });
}

export function updateBillingInformation(id, billingInformation) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/users/${id}/billingInformation`,
        data: billingInformation
    });
}


export function changePassword(id, changePasswordForm) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/users/${id}/changePassword`,
        data: changePasswordForm
    });
}

export function registerUser(user) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/users`,
        data: user
    });
}


export function getAdditionalContacts(userId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/users/${userId}/additionalContacts`
    });
}

export function updateAdditionalContact(userId, contactId, contact) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/users/${userId}/additionalContacts/${contactId}`,
        data: contact
    });
}

export function createAdditionalContact(userId, contact) {
    return authRequest({
        method: "PUT",
        url: `${apiBaseUrl}/api/users/${userId}/additionalContacts`,
        data: contact
    });
}


export function getAdditionalContact(userId, contactId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/users/${userId}/additionalContacts/${contactId}`
    });
}

export function deleteAdditionalContact(userId, contactId) {
    return authRequest({
        method: "DELETE",
        url: `${apiBaseUrl}/api/users/${userId}/additionalContacts/${contactId}`
    });
}

export async function getUserPhoto(userId, picture)
{
    return picture ? getAuthenticatedImage(`${apiBaseUrl}/api/users/${userId}/${picture}`) : null;
}