import { Card, CardContent } from '@mui/material';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import UploadPhoto from '@nvapps/common/components/ui/UploadPhoto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallApi } from '../../hooks';
import { useProfilePhoto, useUserActions } from '../../store/user';

export default function ProfilePhotoUploader() {
    const { t } = useTranslation();
    const { updatePhoto } = useUserActions();
    const [photo, fetching] = useProfilePhoto();
    const { callApi, isLoading } = useCallApi();

    const handleSave = async photoData => await callApi(updatePhoto(photoData));
    const handleRemove = async () => {
        if (photo) await callApi(updatePhoto());
    };

    return (
        <Card >
            <CardContent sx={{ position : 'relative', height: 320, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                {(isLoading || fetching) && (<OverlapProgress progressProps={{ size : 75 }} />)}
                <UploadPhoto
                    size={100}
                    src={photo}
                    saveButtonText={t('save')}
                    removeButtonText={t('remove')}
                    subtitleText={t('allowedImagemFiles')}
                    updatePhotoText={t('updatePhoto')}
                    onSave={handleSave}
                    onRemove={handleRemove}
                />
            </CardContent>
        </Card>
    )
}
