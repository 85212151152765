import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import CloseIconButton from '@nvapps/common/components/ui/CloseIconButton';
import Dialog from "@nvapps/common/components/ui/Dialog";
import OverlapProgress from "@nvapps/common/components/ui/OverlapProgress";
import { useFetchV2 } from "@nvapps/common/hooks";
import { indexDataBy } from "@nvapps/common/utils";
import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { getNotificationsSettings, updateNotificationSettings } from "../../../../api/settings";
import { useCallApi } from "../../../../hooks";
import { useUserDevices } from "../../../../store/contexts/userDevices";

function Content({ userId, onClose }) {

    const [settings, isFetchingSettings] = useFetchV2(() => getNotificationsSettings(userId), [userId]);
    const [devices, isFetchingDevices] = useUserDevices();
    const isFetching = isFetchingSettings || isFetchingDevices;
    const [settingsState, setSettingsState] = useState([]);
    const { callApi, isLoading } = useCallApi();
    const settingsIndexed = useMemo(() =>  indexDataBy(settings || [], 'deviceId') , [settings]);

    useEffect(() =>
    {
        setSettingsState((devices || []).map(e=>
        {
            const inputs = (settingsIndexed[e.id] && settingsIndexed[e.id].inputsNames) || ['', '', '', '']
            return { deviceId : e.id, deviceName : e.name, deviceBrand : e.brand, deviceModel : e.model, input1 : inputs[0], input2 : inputs[1], input3 : inputs[2], input4 : inputs[3] }
        }));
    }, [settingsIndexed, devices]);

    const handleTextChange = (id, field, value) => setSettingsState(state => state.map(s => s.deviceId === id ? {...s, [field] : (value || '')} : s));

    const handleSave = async row => {

        const trimText = t => (t || '').trim();

        const settingsToSave = settingsState.map(s => ({ userId : parseInt(userId), deviceId: s.deviceId, inputsNames : [ trimText(s.input1), trimText(s.input2), trimText(s.input3), trimText(s.input4)]}));

        const res = await callApi(updateNotificationSettings(settingsToSave));
        if (res === true) onClose();
    }

    const columns = [{
        field: 'deviceName',
        headerName: <Trans>device</Trans>,
        flex: 0.3,
        renderCell: ({ row: { deviceName, deviceBrand, deviceModel } }) =>
            <Box style={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {deviceName}
                </Typography>
                <Typography variant="caption" noWrap >
                    {[deviceBrand, deviceModel].filter(e => e).join(' ')}
                </Typography>
            </Box>
    },
    {
        field: 'input1',
        headerName: <Trans>input1Name</Trans>,
        headerAlign : 'center',
        flex: 0.2,
        sortable : false,
        minWidth: 150,
        renderCell: ({ row: { deviceId, input1 } }) => <TextField fullWidth value={input1} size="small" onChange={e => handleTextChange(deviceId, 'input1', e.target.value)} />
    },
    {
        field: 'input2',
        headerName: <Trans>input2Name</Trans>,
        headerAlign : 'center',
        flex: 0.2,
        sortable : false,
        minWidth: 150,
         renderCell: ({ row: { deviceId, input2 } }) => <TextField fullWidth value={input2} size="small" onChange={e => handleTextChange(deviceId, 'input2', e.target.value)} />
    },
    {
        field: 'input3',
        headerName: <Trans>input3Name</Trans>,
        headerAlign : 'center',
        flex: 0.2,
        sortable : false,
        minWidth: 150,
        renderCell: ({ row: { deviceId, input3 } }) => <TextField fullWidth value={input3} size="small" onChange={e => handleTextChange(deviceId, 'input3', e.target.value)} />
    },
    {
        field: 'input4',
        headerName: <Trans>input4Name</Trans>,
        headerAlign : 'center',
        flex: 0.2,
        sortable : false,
        minWidth: 150,
         renderCell: ({ row: { deviceId, input4 } }) => <TextField fullWidth value={input4} size="small" onChange={e => handleTextChange(deviceId, 'input4', e.target.value)} />
    }];

    return <React.Fragment>
        {isFetching && <OverlapProgress progressProps={{ size: 100 }} />}
        <DialogTitle>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Trans>notificationsSettings</Trans>
                <CloseIconButton title={<Trans>close</Trans>} onClick={onClose} />
            </Stack>
        </DialogTitle>
        <DialogContent sx={{ mt: -2, mb : -2 }}>
            <Box sx={{ height : 400, mx : -3 }}>
                <DataGrid
                    rows={settingsState}
                    getRowId={d => d.deviceId}
                    columns={columns}
                    pageSize={10}
                    loading={isFetching}
                    density="standard"
                    paginationMode="client"
                    sortingMode="client"
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    pagination />
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="secondary">
                <Trans>close</Trans>
            </Button>
            <ButtonWithProgress disabled={isFetching || isLoading} loading={isLoading} onClick={handleSave}>
                <Trans>save</Trans>
            </ButtonWithProgress>
        </DialogActions>
    </React.Fragment>
}

export default function NotificationsSettingsModal({ open, userId, onClose }) {

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            onClose={onClose}>
            <Content userId={userId} onClose={onClose} />
        </Dialog>
    );
}