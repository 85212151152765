import { downloadBlob } from '@nvapps/common/utils';
import { applyFilters } from '@nvtracker/common/utils';
import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as devicesApi from "../api/devices";

const devicesSuspended = createSlice({
    name: 'devicesSuspended',
    initialState: {
        list: [],
        filters:
        {
            searchText: '',
            sortBy: 'serialNumber',
            sortDesc: false,
            page: 0,
            pageSize: 10,
        },
        loading: false,
        hasError: true
    },
    reducers: {
        fetchStart(state) {
            state.loading = true;
            state.hasError = false;
        },
        fetchFailed(state) {
            state.hasError = true;
            state.loading = false;
        },
        fetchSuccess(state, { payload }) {
            state.list = payload;
            state.loading = false;
        },
        updateFilters(state, { payload }) {
            state.filters = { ...state.filters, ...payload };
        },
    }
});

function mergeDevicesWithPositions(devices, positions) {
    const positionsGrouped = Object.fromEntries(positions.map(d => [d.deviceId, d]));
    return devices.map(d => ({ ...d, position: positionsGrouped[d.id] || {} }));
}

async function getDevicesData(userId) {
    return Promise.all([devicesApi.getDevicesSuspended(), devicesApi.getDevicesPositions()])
        .then(([devices, positions]) => mergeDevicesWithPositions(devices.data, positions.data));
}

function fetchDevices() {

    const actions = devicesSuspended.actions;

    return (dispatch, getState) => {

        const state = getState();
        if (state.devicesSuspended.isFetching) return;

        dispatch(actions.fetchStart());
        return getDevicesData().then(
            res => {
                dispatch(actions.fetchSuccess(res));
            },
            err => dispatch(actions.fetchFailed(err))
        );
    }
}

const tableOptionsSelector = createSelector(
    state => state.devicesSuspended.filters,
    ({ page, pageSize }) => ({ page, pageSize }))


const devicesFilteredSelector = createSelector(
    state => state.devicesSuspended.filters.searchText,
    state => state.devicesSuspended.list,
    (searchText, list) => applyFilters(list, ['serialNumber', 'gsmNumber', 'name', 'brand', 'model', 'userName', 'userRealName', 'planName'], searchText));

export function useDevices() {
    const list = useSelector(devicesFilteredSelector);
    const { page, pageSize } = useSelector(tableOptionsSelector);
    const loading = useSelector(state => state.devicesSuspended.loading);
    const { fetchDevices } = useDevicesActions();

    useEffect(() => {
        fetchDevices();
    }, [fetchDevices]);

    return [list, loading, page, pageSize];
}

export function useDevicesLoading() {

    const state = useSelector(state => state.devicesSuspended.loading);
    return state;
}

export function useDevicesFilters() {

    const state = useSelector(state => state.devicesSuspended.filters, shallowEqual);
    return state;
}

export function useDevicesActions() {
    const dispatch = useDispatch();
    const actions = devicesSuspended.actions;

    return useMemo(() => ({
        fetchDevices: () => dispatch(fetchDevices()),
        updateFilters: value => dispatch(actions.updateFilters(value))
    }), [dispatch, actions]);
}

export function useDevicesSuspendedExport() {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const exportList = async (searchText, sortModel) => {

        let sortBy = null;
        let sortDesc = false;

        if (sortModel && sortModel.length > 0) {
            sortBy = sortModel[0].field;
            sortDesc = sortModel[0].sort === 'desc';
        }

        setIsLoading(true);
        return devicesApi.exportDevicesSuspended({ searchText, sortBy, sortDesc }).then(res => {
            const fileName = `${t('devices')} ${t('suspended').toLowerCase()}.xlsx`
            downloadBlob(new File([res.data], fileName), fileName);
            setIsLoading(false);
        }).catch(res => setIsLoading(false));
    }

    return [exportList, isLoading];
}

export default devicesSuspended.reducer;