import { Icon } from '@iconify/react';
import {
    Box, DialogActions, DialogContent, DialogTitle, Divider, List, ListItemIcon, ListItemText, MenuItem,
    Stack, Typography
} from "@mui/material";
import { MultibancoPaymentInfo } from '@nvapps/common/components/modals/MultibancoPaymentModal';
import PaymentModalBase from "@nvapps/common/components/modals/PaymentModalBase";
import ButtonPopover from "@nvapps/common/components/ui/ButtonPopover";
import CloseIconButton from '@nvapps/common/components/ui/CloseIconButton';
import ConfirmDialog from "@nvapps/common/components/ui/ConfirmDialog";
import Dialog from "@nvapps/common/components/ui/Dialog";
import { Field, FieldList } from "@nvapps/common/components/ui/Fields";
import Label from '@nvapps/common/components/ui/Label';
import useDialogOpener from '@nvapps/common/hooks';
import { getCurrencySymbol } from "@nvapps/common/utils";
import DeviceListItem from "@nvtracker/common/components/DeviceListItem";
import { PaymentTypes, PaymentTypesEnum, PurchasesStatusEnum, getPurchaseStatus } from '@nvtracker/common/helpers';
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { cancelPurchase, getPaymentDetails } from "../../api/purchases";
import DateTime from '../../components/Ui/DateTime';
import FileManagerDialog from '../../components/Ui/FileManager';
import { useCallApi } from "../../hooks";
import CreateInvoiceDialog from '../invoices/CreateInvoiceDialog';
import LinkPurchaseToInvoicesDialog from './LinkPurchaseToInvoicesDialog';

function PaymentModal({ purchase, ...props }) {

    const { t } = useTranslation();

    const translations =
    {
        mbPayment: t('MBPayment'),
        entity: t('entity'),
        reference: t('reference'),
        value: t('value'),
        paymentDeadline: t('paymentDeadline'),
        cancel: t('cancel'),
        creditDebitCardPayment: t('creditDebitCardPayment'),
        pay: t('pay'),
        cardNumber: t('cardNumber'),
        expiryDate: t('expiryDate')
    }

    if (!purchase) return false;
    return (
        <PaymentModalBase
            paymentType={purchase.paymentType}
            currency={purchase.currency}
            translations={translations}
            fetchPaymentDetails={() => getPaymentDetails(purchase)}
            {...props} />
    );
}

function MbInfo({ purchase }) {

    const { t } = useTranslation();

    const translations =
    {
        mbPayment: t('MBPayment'),
        entity: t('entity'),
        reference: t('reference'),
        value: t('value'),
        paymentDeadline: t('paymentDeadline'),
        cancel: t('cancel'),
        creditDebitCardPayment: t('creditDebitCardPayment'),
        pay: t('pay'),
        cardNumber: t('cardNumber'),
        expiryDate: t('expiryDate')
    }

    if (!purchase || purchase.paymentType !== PaymentTypesEnum.MULTIBANCO) return false;

    return <Box display="flex" flexDirection="column" alignItems="center" pt={1}>
        <MultibancoPaymentInfo
            paymentType={purchase.paymentType}
            currency={purchase.currency}
            translations={translations}
            fetchPaymentDetails={() => getPaymentDetails(purchase, true)} />
    </Box>
}

export function PurchaseOptions({ purchase, onClose }) {

    const [payModalVisible, setPayModalVisible] = useState(false);
    const fileManagerDialog = useDialogOpener();
    const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);
    const { t } = useTranslation();
    const { callApi } = useCallApi();
    const { userId } = purchase || {};
    const createInvoiceDialog = useDialogOpener({ purchaseId: null, devices: [] });
    const linkInvoiceDialog = useDialogOpener({});

    const handlePayPurchase = () => {
        setPayModalVisible(true);
    };

    const handleCancelPurchase = () => {
        setConfirmCancelDialogOpen(true);
    };

    const handleConfirmCancelPurchase = async () => {
        setConfirmCancelDialogOpen(false);
        var res = await callApi(cancelPurchase(purchase.id));
        if (res === true) onClose && onClose();
    };

    return <>
        <DialogActions>
            <ButtonPopover variant="outlined" size="small" position="top" text={<Trans>options</Trans>} sx={{ mr: 'auto' }}>
                {purchase.status === PurchasesStatusEnum.PENDING && purchase.paymentType !== 'Other' &&
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={handlePayPurchase}>
                        <ListItemIcon sx={{ mr: 1.5 }}>
                            <Icon icon="bi:wallet-fill" width={14} />
                        </ListItemIcon>
                        <ListItemText primary={t('pay')} primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                }
                {purchase.status === PurchasesStatusEnum.PENDING && <MenuItem sx={{ color: 'text.secondary' }} onClick={handleCancelPurchase} >
                    <ListItemIcon sx={{ mr: 1.5 }}>
                        <Icon icon="fa-solid:times" width={14} />
                    </ListItemIcon>
                    <ListItemText primary={t('cancel')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>}
                {purchase.status === PurchasesStatusEnum.PENDING && <Divider />}
                {purchase.status === PurchasesStatusEnum.PAID &&
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={() => createInvoiceDialog.handleOpenDialog({ purchaseId: purchase.id, devices: purchase.purchaseDevices.map(e => ({ id: e.deviceId, name: e.deviceName })) })} >
                        <ListItemIcon sx={{ mr: 1.5 }}>
                            <Icon icon="ant-design:plus-outlined" width={14} />
                        </ListItemIcon>
                        <ListItemText primary={t('createInvoice')} primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                }
                {purchase.status === PurchasesStatusEnum.PAID &&
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={() => linkInvoiceDialog.handleOpenDialog(purchase)} >
                        <ListItemIcon sx={{ mr: 1.5 }}>
                            <Icon icon="material-symbols:link" width={14} />
                        </ListItemIcon>
                        <ListItemText primary={t('linkInvoice')} primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                }
                {purchase.status === PurchasesStatusEnum.PAID && <Divider />}
                <MenuItem sx={{ color: 'text.secondary' }} onClick={fileManagerDialog.handleOpenDialog} >
                    <ListItemIcon sx={{ mr: 1.5 }}>
                        <Icon icon="eva:file-fill" width={14} />
                    </ListItemIcon>
                    <ListItemText primary={t('files')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </ButtonPopover>
        </DialogActions>
        <ConfirmDialog
            open={confirmCancelDialogOpen}
            title={<Trans>cancelPurchase</Trans>}
            confirmText={<Trans>confirm</Trans>}
            cancelText={<Trans>cancel</Trans>}
            onConfirm={handleConfirmCancelPurchase}
            onCancel={() => setConfirmCancelDialogOpen(false)}>
            {<Trans>cancelPurchaseQuestion</Trans>}
        </ConfirmDialog>
        <CreateInvoiceDialog
            open={createInvoiceDialog.open}
            purchaseId={createInvoiceDialog.props.purchaseId}
            devices={createInvoiceDialog.props.devices}
            onClose={() => createInvoiceDialog.handleCloseDialog()} />
        <LinkPurchaseToInvoicesDialog
            open={linkInvoiceDialog.open}
            userId={userId}
            purchase={linkInvoiceDialog.props}
            onClose={() => linkInvoiceDialog.handleCloseDialog()} />
        <FileManagerDialog
            open={fileManagerDialog.open}
            title={`${t('purchase')} ${purchase.id}`}
            path={`clients/${userId}/purchases/${purchase.id}`}
            onClose={fileManagerDialog.handleCloseDialog} />
        {payModalVisible &&
            <PaymentModal
                open={payModalVisible}
                purchase={purchase}
                onDone={() => setPayModalVisible(false)}
                onCancel={() => setPayModalVisible(false)} />}
    </>
}

// function OptionsButton({ purchase, onClose }) {
//     //if (purchase.status !== PurchasesStatusEnum.PENDING) return '';
//     return <PurchaseOptions purchase={purchase} onClose={onClose} />

// }

function Content({ purchase }) {

    const { currency, userName, userRealName, purchaseDevices, status, statusDate, paymentType, creationDate, requestedBy, total } = purchase || {};
    const devices = useMemo(() => (purchaseDevices || []).map(d => ({ deviceId: d.deviceId, name: d.deviceName })), [purchaseDevices]);
    const currencySym = getCurrencySymbol(currency);

    return (<Stack
        minWidth={350}
        direction="column"
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={2}>
        {userName && <FieldList p={0}>
            <Field px={0} title={<Trans>username</Trans>} value={userRealName || userName} />
            <Field px={0} title={<Trans>email</Trans>} value={userName} />
        </FieldList>}
        <FieldList p={0}>
            <Field px={0} title={<Trans>status</Trans>} value={<Label color={getPurchaseStatus(status).color}>
                <Typography variant="subtitle2">
                    <Trans>{getPurchaseStatus(status).text}</Trans>
                </Typography>
            </Label>} />
            <Field px={0} title={<Trans>statusDate</Trans>} value={<DateTime datetime={statusDate} format="dd MMMM yyyy HH:mm" />} />
            <Field px={0} title={<Trans>payment</Trans>} value={<Label color='info'>
                <Typography variant="subtitle2">
                    <Trans>{(PaymentTypes[paymentType] || PaymentTypes.Other).text}</Trans>
                </Typography>
            </Label>} />
            <Field px={0} title={<Trans>created</Trans>} value={<DateTime datetime={creationDate} format="dd MMMM yyyy HH:mm" />} />
            {requestedBy && <Field px={0} title={<Trans>requestedBy</Trans>} value={requestedBy || '-'} />}
        </FieldList>
        <FieldList p={0}>
            <Field p={0}
                title={<Typography variant="span" fontSize="1.3em" fontWeight={600}>{<Trans>total</Trans>}</Typography>}
                value={<Typography variant="span" fontSize="1.3em" fontWeight="normal" color="primary.main">{`${total} ${currencySym}`}</Typography>} />
        </FieldList>
        <Box>
            <Typography variant="overline" color="text.secondary">
                <Trans>affectedDevices</Trans>
            </Typography>
            <Box sx={{ maxHeight: 130, overflow: 'auto' }}>
                <List>
                    {devices.length === 0 ?
                        <ListItemText primary="N/D" /> :
                        devices.map(d => (
                            <DeviceListItem sx={{ paddingRight: 0, paddingLeft: 0 }} device={d} key={d.deviceId} />
                        )
                        )}
                </List>
            </Box>
        </Box>
        {paymentType === PaymentTypesEnum.MULTIBANCO && <MbInfo purchase={purchase} />}
    </Stack>);
}

export default function PurchaseDetailsDialog({ open, purchase, showOptions = true, onClose }) {

    return (
        <Dialog
            open={open}
            fullWidth={false}
            onClose={onClose}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Trans>purchaseDetails</Trans> - {purchase.id}
                    <Box>
                        <CloseIconButton title={<Trans>close</Trans>} onClick={onClose} />
                    </Box>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Content purchase={purchase} />
            </DialogContent>
            {purchase && purchase.userId && showOptions && <PurchaseOptions purchase={purchase} onClose={onClose} />}
        </Dialog>
    );
}