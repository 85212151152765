import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import BoxInfo from '@nvtracker/common/components/ui/BoxInfo';
import Dialog from '@nvapps/common/components/ui/Dialog';
import SelectPlan from "@nvtracker/common/components/ui/SelectPlan";
import { addMonths, isValid } from 'date-fns';
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import DateTime from "../../components/Ui/DateTime";
import { useCallApi } from "../../hooks";
import { useDevicesActions } from "../../store/devices";
import { usePlansOfGsmNumber } from "../../store/plans";
import { DatePicker } from "@mui/lab";
import { getErrorMessage, hasError } from "@nvtracker/common/utils";
import { canRenewDevice } from "@nvtracker/common/devices";

function getErrors(formData) {

    const err = [];
    if (!formData) return null;

    if ((formData.invoiceNumber || '').trim().length === 0) err.push({ id: "invoiceNumber", message: "requiredField" });
    if (!formData.invoiceDate) err.push({ id: "invoiceDate", message: "invalidField" });
    if (formData.planId === '') err.push({ id: "planId", message: "requiredField" });

    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Content({ user, device, onSaved, onSuccess, onClose }) {

    const { renewDevice } = useDevicesActions();
    const [formData, setFormData] = useState({ planId : '', invoiceNumber : '', invoiceDate : new Date().toISOString(), invoiceObs : '' });
    const { callApi, isLoading } = useCallApi();
    const { t } = useTranslation();
    const { name, gsmNumber, brand, model } = device || {};
    const { name : userRealName, userName } = user || {};
    const [plans, isLoadingPlans] = usePlansOfGsmNumber(gsmNumber);
    const plan = (plans || []).find(e=> e.id === formData.planId);
    const brandModel = [brand, model].filter(e => (e || '').trim().length > 0).join(' ');
    const errors = useMemo(() => getErrors(formData), [formData]);

    const renewalDate = device ? new Date(device.renewalDate) : new Date();
    const newRenewalDate = addMonths(renewalDate, plan ? plan.months : 0);

    function getMessage(key, defaultValue) {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : defaultValue;
    }

    const handleRenew = async () => {
        const res = await callApi(renewDevice(device.id, formData).then(r => onSuccess && onSuccess(r.data)));
        if (res === true) {
            onClose && onClose();
        }
    }

    useEffect(() => setFormData(state => ({...state, planId : device ? device.planId : ''})), [device]);


    return <React.Fragment>
        <DialogTitle >
            <Trans>renewDevice</Trans>
        </DialogTitle>
        <DialogContent sx={{ position: 'relative' }}>
            {(isLoading || isLoadingPlans) && (<OverlapProgress progressProps={{ size: 75 }} />)}
            <BoxInfo sx={{ lineHeight : 1, pb : 4 }} title={name} subtitle={userRealName || userName || brandModel} subtitle2={userRealName ? userName : null} />
            <Grid container spacing={3} >
                <Grid item xs={12}>
                     <SelectPlan
                        required
                        label={t('plan')}
                        value={formData.planId}
                        name="planId"
                        onChange={ev => setFormData({...formData, planId : ev.target.value})}
                        error={hasError(errors, "planId")}
                        plans={plans}
                        fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', columnGap: 2, alignItems: 'center', justifyContent : 'space-evenly' }}>
                        <Box>
                            <Typography variant="caption">{t('currentRenewalDate')}</Typography>
                            <Typography variant="subtitle2">
                                <DateTime datetime={renewalDate} format="P" />
                            </Typography>
                        </Box>
                        <Typography>~</Typography>
                        <Box textAlign="right">
                            <Typography variant="caption">{t('nextRenewalDate')}</Typography>
                            <Typography variant="subtitle2">
                                <DateTime datetime={newRenewalDate} format="P" />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ mt : 3, mb : 3 }}>
                <Typography variant="h6" >
                    <Trans>invoice</Trans>
                </Typography>
            </Divider>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label={t('invoice')}
                        value={formData.invoiceNumber}
                        name="invoiceNumber"
                        error={hasError(errors, "invoiceNumber")}
                        helperText={getMessage("invoiceNumber")}
                        onChange={ev => setFormData({...formData, invoiceNumber : ev.target.value}) }
                        fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePicker
                        inputVariant="filled"
                        margin="dense"
                        clearable
                        label={t('invoiceDate')}
                        format="yyyy-MM-dd"
                        value={formData.invoiceDate}
                        onChange={date => setFormData({...formData, invoiceDate : (date && isValid(date) && date.toISOString()) || ''}) }
                        renderInput={(params) => <TextField {...params} fullWidth required error={hasError(errors, "invoiceDate")}
                            helperText={getMessage("invoiceDate")} />} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('obs')}
                        multiline
                        rows={2}
                        value={formData.invoiceObs}
                        name="invoiceObs"
                        onChange={ev => setFormData({...formData, invoiceObs : ev.target.value}) }
                        fullWidth />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={isLoading} color="secondary">
                <Trans>cancel</Trans>
            </Button>
            <ButtonWithProgress
                onClick={handleRenew}
                disabled={(plans || []).length === 0 || !canRenewDevice(device)}
                color="primary"
                loading={isLoading}>
                <Trans>renew</Trans>
            </ButtonWithProgress>
        </DialogActions>
    </React.Fragment>
}

export default function RenewalDeviceDialog({ open, user, device, onSuccess, onClose }) {

    return (
        <Dialog open={open} onClose={onClose} >
            <Content user={user} device={device} onSuccess={onSuccess} onClose={onClose} />
        </Dialog>
    );
}