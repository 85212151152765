import { authRequest } from "./utils";

const apiBaseUrl = "";

export function getNotificationsSettings(userId, deviceId) {

    let query = '';
    if (Boolean(deviceId))
        query += '&deviceId=' + deviceId;

    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/settings/notifications?userId=${userId}${query}`
    });
}

export function updateNotificationSettings(settings)
{
     return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/settings/notifications`,
        data : settings
    });
}

export const getReportsSettingsUrl = `${apiBaseUrl}/api/settings/reports`

export function updateReportsSettings(settings)
{
     return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/settings/reports`,
        data : settings
    });
}

export function getDevicesRegistered(userId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/settings/${userId}/DevicesRegistered`
    });
}

export function sendTestMessage(userId, token)
{
     return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/settings/TestNotification`,
        data : {userId, token}
    });
}