import { applyFilters, arrayToText } from '@nvtracker/common/utils';
import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as purchasesApi from "../api/purchases";
import { usePlansById } from './plans';
import { getFormatedDate } from '@nvapps/common/utils/dateUtils';
import { useProfileLanguage } from './user';
import { PaymentTypes, getPurchaseStatus } from '@nvtracker/common/helpers';

const purchases = createSlice({
    name: 'purchases',
    initialState: {
        list: [],
        filters:
        {
            searchText: '',
            sortBy: 'statusDate',
            sortDesc: true,
            page: 0,
            pageSize: 10,
        },
        loading: false,
        hasError: true
    },
    reducers: {
        fetchStart(state) {
            state.loading = true;
            state.hasError = false;
        },
        fetchFailed(state) {
            state.hasError = true;
            state.loading = false;
        },
        fetchSuccess(state, { payload }) {
            state.list = payload;
            state.loading = false;
        },
        updateFilters(state, { payload }) {
            state.filters = { ...state.filters, ...payload };
        },
    }
});

function fetchPurchases() {

    const actions = purchases.actions;

    return (dispatch, getState) => {

        const state = getState();
        if (state.purchases.isFetching) return;

        dispatch(actions.fetchStart());
        return purchasesApi.getPurchasesDetailed().then(
            res => {
                dispatch(actions.fetchSuccess(res.data));
            },
            err => dispatch(actions.fetchFailed(err))
        );
    }
}

const tableOptionsSelector = createSelector(
    state => state.purchases.filters,
    ({ page, pageSize }) => ({ page, pageSize }))

function getDevicesOfPurchase(purchase, devicesText) {

    const devices = purchase.purchaseDevices || [];
    if (devices.length !== 1) return `${devices.length} ${devicesText}`;
    else return devices[0].deviceName;
}

export function usePurchases() {
    const list = useSelector(state => state.purchases.list);
    const searchText = useSelector(state => state.purchases.filters.searchText);
    const { page, pageSize } = useSelector(tableOptionsSelector);
    const loading = useSelector(state => state.purchases.loading);
    const { fetchPurchases } = usePurchasesActions();
    const [plans] = usePlansById();
    const { t } = useTranslation();
    const devicesText = t('devices');
    const language = useProfileLanguage();

    useEffect(() => {
        fetchPurchases();
    }, [fetchPurchases]);

    const listTreated = useMemo(() => {
        return list.map(e => ({
            ...e,
            devices: getDevicesOfPurchase(e, devicesText),
            multibancoDetails :  arrayToText(e.purchaseMultibancoDetails.map(r => arrayToText([r.entity, r.reference]))),
            planName: plans && plans[e.planId] ? plans[e.planId].name : null
        }))
    }, [list, plans, devicesText])

    const listFiltered = useMemo(() => applyFilters(listTreated,
        [
            'userName',
            'userRealName',
            'planName',
            'devices',
            'id',
            'total',
            'multibancoDetails',
            { key: 'creationDate', formatter: (key, r) => getFormatedDate(r.creationDate, 'dd MMMM yyyy', language) },
            { key: 'statusDate', formatter: (key, r) => getFormatedDate(r.statusDate, 'dd MMMM yyyy', language) },
            { key: 'status', formatter: (key, r) => t(getPurchaseStatus(r.status).text) },
            { key: 'paymentType', formatter: (key, r) => t((PaymentTypes[r.paymentType] || PaymentTypes.Other).text) }
        ], searchText), [language, listTreated, searchText, t]);

    return [listFiltered, loading, page, pageSize];
}

export function usePurchasesLoading() {

    const state = useSelector(state => state.purchases.loading);
    return state;
}

export function usePurchasesFilters() {

    const state = useSelector(state => state.purchases.filters, shallowEqual);
    return state;
}

export function usePurchasesActions() {
    const dispatch = useDispatch();
    const actions = purchases.actions;

    return useMemo(() => ({
        fetchPurchases: () => dispatch(fetchPurchases()),
        updateFilters: value => dispatch(actions.updateFilters(value))
    }), [dispatch, actions]);
}

export default purchases.reducer;