import { Icon } from '@iconify/react';
import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { isImageFile } from '../../../utils';
import CenteredContent from '../CenteredContent';
import { Img } from '../Img';

const iconsByTypes =
{
    xls : 'ant-design:file-excel-outlined',
    xlsx : 'ant-design:file-excel-outlined',
    pdf : 'ant-design:file-pdf-outlined',
    doc : 'ant-design:file-word-outlined',
    docx : 'ant-design:file-word-outlined',
    zip  : 'ant-design:file-zip-outlined',
    txt : 'ant-design:file-text-outlined',
    cvs : 'ant-design:file-excel-outlined',
    png : 'ant-design:file-image-outlined',
    jpg : 'ant-design:file-jpg-outlined',
    jpeg : 'ant-design:file-jpg-outlined',
    gif : 'ant-design:file-gif-outlined',
    bmp : 'ant-design:file-image-outlined',
}

function getIconFile(name)
{
    const [, fileExt] = /\.([^.]*)$/.exec(name) || [];
    return iconsByTypes[fileExt] || 'eva:file-outline';
}

function FolderContentItem({ item, selected, authenticationToken, onClick, onDoubleClick }) {
    const folderSx = selected ? { border: 2, borderColor: '#0099ffd4' } : { border: 1 };
    const textSx = selected ? { borderRadius: 0.5, backgroundColor: '#0099ff40' } : {};

    return <Box display="flex" flexDirection="column" textAlign="center" sx={{ cursor: 'pointer' }} onClick={() => onClick(item.id)} onDoubleClick={() => onDoubleClick(item.id)}>
        <Box display="flex" alignItems="center" justifyContent="center" p={1.5} borderRadius={1} sx={{ height: 120, ...folderSx }}>
            {isImageFile(item.name) ? <Img src={item.url} alt={item.name} token={authenticationToken} /> :
                <Icon height={48} icon={item.type === 'File' ? getIconFile(item.name) : `eva:folder-outline`} />}
        </Box>
        <Box mt={1} sx={{ ...textSx }}>
            <Tooltip title={item.name}>
                <Typography variant="subtitle2" color="secondary" sx={{ userSelect : 'none', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</Typography>
            </Tooltip>
        </Box>
    </Box>;
}
export default function FolderContent({ folder, selected, translations, authenticationToken, onItemClick, onItemDoubleClick }) {

    const items = folder.items || [];

    return items.length == 0 ? <CenteredContent>
        <Typography variant='h5'>
            {translations.emptyFolder}
        </Typography>
    </CenteredContent> : <Box display="grid" gap={2} gridTemplateColumns="repeat(auto-fit, minmax(150px, 150px))">
        {items.map(item => <FolderContentItem key={item.id} item={item} selected={selected === item.id} authenticationToken={authenticationToken} onClick={onItemClick} onDoubleClick={onItemDoubleClick} />)}
    </Box>;
}
