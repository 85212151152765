import { authRequest } from "./utils";

const apiBaseUrl = "";

export function getAlerts() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/alerts`
    });
}
