import { Icon } from '@iconify/react';
import { Box, Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmPopover from './ConfirmPopover';
import MoreMenu from './MoreMenu';

export default function ActionMenu({ onEdit, onDelete, children, ...props }) {

    const ref = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { t } = useTranslation();

    const handleDelete = action => action === true && onDelete();

    return <Box ref={ref}>
        <ConfirmPopover text={t('deleteQuestion')} open={confirmOpen} anchorEl={ref.current} onClose={() => setConfirmOpen(false)} onResolve={handleDelete} />
        <MoreMenu {...props}>
            {onEdit &&
            <MenuItem sx={{ color: 'text.secondary' }} onClick={onEdit}>
                <ListItemIcon>
                    <Icon icon="eva:edit-fill" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={t('edit')} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>}
            {children && children}
            {onDelete && <Divider />}
            {onDelete && <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setConfirmOpen(true)}>
                <ListItemIcon>
                    <Icon icon="eva:trash-2-fill" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={t('delete')} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>}
        </MoreMenu>
    </Box>;
}
