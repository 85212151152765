import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@nvapps/common/hooks';

export function useCallApi(props) {

    const { successText, errorText, snackbarProps = {} } = props || {};
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const callApi = async promise => {
        setIsLoading(true);
        return await promise
            .then(e => {
                enqueueSnackbar(successText || t('operationSuccess', { defaultValue: 'Operação efectuada com sucesso' }), { variant: 'success', ...snackbarProps });
                setIsLoading(false);
                return Promise.resolve(true);
            })
            .catch(error => {
                const errorServer = error.response && error.response.data && error.response.data.error;
                enqueueSnackbar(errorText || (errorServer && t(`server_${errorServer}`)) || t('error', { defaultValue: 'Erro' }), { variant: 'error', ...snackbarProps });
                setIsLoading(false);
                return Promise.resolve(false);
            });
    }

    return { callApi, isLoading }
}

export function useSaveApi(props) {

    const { t } = useTranslation();
    const { successText } = props || {};
    const { callApi, isLoading } = useCallApi(
        {
            ...props,
            successText: successText || t('recordSaved', { defaultValue: 'Registo guardado' })
        });

    return { saveApi: callApi, isSaving: isLoading };
}


export function useDeleteApi(props) {

    const { t } = useTranslation();
    const { successText } = props || {};
    const { callApi, isLoading } = useCallApi(
        {
            ...props,
            successText: successText || t('recordDeleted', { defaultValue: 'Registo eliminado' })
        });

    return { deleteApi: callApi, isDeleting: isLoading };
}


export const useReduxFetch = (action, isFecthingSelector, dataSelector, fetchErrorSelector) => {
    const dispatch = useDispatch();

    const fetch = useCallback(refetch => dispatch(action(refetch)), [dispatch, action]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const isFetching = useSelector(isFecthingSelector);
    const data = useSelector(dataSelector);
    const error = useSelector(fetchErrorSelector);

    return [data, isFetching, error, fetch];
}

export function useGridActions(actions, filters)
{
    const [selectedRows, setSelectedRows] = useState([]);
    const { searchText, page, pageSize, sortBy, sortDesc } = filters();
    const [searchQuery, setSearchQuery] = useState(searchText);
    const actionsFN = actions();

    const onSearch = t => setSearchQuery(t);
    const sort = sortModel =>
    {
        if (sortModel && sortModel.length > 0) actionsFN.updateFilters({ sortBy : sortModel[0].field, sortDesc : sortModel[0].sort === 'desc' });
        else actionsFN.updateFilters({ sortBy : null, sortDesc : false });
    };
    const changePage = page => actionsFN.updateFilters({ page });
    const changePageSize = pageSize => actionsFN.updateFilters({ pageSize });
    const selectRows = rows => setSelectedRows(rows);

    useDebounce(() => {
        actionsFN.updateFilters({ searchText: searchQuery, page : 0 });
    }, 200, [searchQuery])

    return {
        page,
        pageSize,
        sortModel : sortBy ? [{ field : sortBy, sort : sortDesc ? 'desc' : 'asc' }] : [],
        searchQuery,
        selectedRows,

        changePage,
        changePageSize,
        selectRows,
        onSearch,
        sort,
        actions : actionsFN
    }
}