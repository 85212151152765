import { Icon } from '@iconify/react';
import { Button, ButtonGroup, ListItemIcon, ListItemText, MenuItem, Toolbar } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useIsMobile } from '../../../hooks';
import ConfirmPopover from '../ConfirmPopover';
import MoreMenu from '../MoreMenu';

export default function FileManagerToolbar({ showFileButtons, showViewButton, showDownloadButton, showDeleteButton, showUploadButton, showEditButton, translations, onCreateFolder, onUpload, onView, onDownload, onEditFolder, onDelete }) {

    const ref = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const isMobile = useIsMobile();

    return <Toolbar sx={{ display: 'flex' }}>
        <Button size="small" variant="outlined" onClick={onCreateFolder} startIcon={<Icon icon="ant-design:plus-outlined" />}>
            {translations.newFolder}
        </Button>
        {showUploadButton && onUpload &&
            <Button size="small" variant="outlined" onClick={onUpload} startIcon={<Icon icon="ant-design:upload-outlined" />} sx={{ ml: 1 }}>
                {translations.upload}
            </Button>}
        {!isMobile &&
        <ButtonGroup sx={{ ml: 'auto' }}>
            {showViewButton &&
                <Button size="small" variant='outlined' onClick={onView} startIcon={<Icon icon="ant-design:search-outlined" />}>
                    {translations.view}
                </Button>}
            {showDownloadButton &&
                <Button size="small" variant='outlined' onClick={onDownload} startIcon={<Icon icon="ant-design:download-outlined" />}>
                    {translations.download}
                </Button>}
            {showEditButton &&
                <Button size="small" variant='outlined' onClick={onEditFolder} startIcon={<Icon icon="ant-design:edit-outlined" />}>
                    {translations.edit}
                </Button>}
            {showDeleteButton &&
                <Button size="small" ref={ref} variant='outlined' onClick={() => setConfirmOpen(true)} startIcon={<Icon icon="ant-design:delete-outlined" />}>
                    {translations.delete}
                </Button>}
        </ButtonGroup>}
        {isMobile && (showViewButton || showDownloadButton || showEditButton || showDeleteButton) && <MoreMenu toolTip={translations.options} sx={{ ml: 'auto' }}>
            {showViewButton &&
            <MenuItem sx={{ color: 'text.secondary' }} onClick={onView} >
                <ListItemIcon>
                    <Icon icon="ant-design:search-outlined" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={translations.view} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>}
            {showDownloadButton &&
            <MenuItem sx={{ color: 'text.secondary' }} onClick={onDownload} >
                <ListItemIcon>
                    <Icon icon="ant-design:download-outlined" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={translations.download} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>}
            {showEditButton &&
            <MenuItem sx={{ color: 'text.secondary' }} onClick={onEditFolder} >
                <ListItemIcon>
                    <Icon icon="ant-design:edit-outlined" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={translations.edit} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>}
            {showDeleteButton &&
            <MenuItem sx={{ color: 'text.secondary' }} ref={ref} onClick={() => setConfirmOpen(true)} >
                <ListItemIcon>
                    <Icon icon="ant-design:delete-outlined" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={translations.delete} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>}
        </MoreMenu>}
        <ConfirmPopover text={translations.deleteQuestion} confirmText={translations.yes} rejectText={translations.no} open={confirmOpen} anchorEl={ref.current} onClose={() => setConfirmOpen(false)} onResolve={res => res === true && onDelete()} />
    </Toolbar>;
}
