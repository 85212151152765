import { Box, Grid } from '@mui/material';
import BillingInfoCard from './BillingInfoCard';
import ContactsCard from './ContactsCard';
import ProfileCard from './ProfileCard';

export default function TabGeneral({ userId, onSaved }) {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item sm={8}>
                    <ProfileCard userId={userId} onSaved={onSaved} />
                </Grid>
                <Grid item xs>
                    <Box height='100%'>
                        <ContactsCard userId={userId} />
                    </Box>
                </Grid>
                <Grid item >
                    <BillingInfoCard userId={userId} />
                </Grid>
            </Grid>
        </Box>
    );
}