
import { Card, Container, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import PurchasesMain from '../../components/Purchases/Purchases';


export default function Purchases()
{
    return <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" >
                <Trans>purchases</Trans>
            </Typography>
        </Stack>
        <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
           <PurchasesMain />
        </Card>
    </Container>
}