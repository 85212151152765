import { authRequest } from "./utils";

const apiBaseUrl = "";

export function getPurchases(userId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/purchases?userId=${userId}`
    });
}

export function getPurchasesDetailed() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/purchases/detailed`
    });
}

export function createPurchase(userId, deviceIds) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/purchases?userId=${userId}`,
        data: {
            deviceIds,
            paymentType: "Other"
        }
    });
}

export function cancelPurchase(purchaseId) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/purchases/${purchaseId}/cancel`,
        data: {}
    });
}

export function annulPurchase(purchaseId, password) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/purchases/${purchaseId}/annul`,
        data: { password }
    });
}

export function getPaymentDetails(purchase, readOnly = false) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/purchases/${purchase.id}/paymentDetails/${purchase.paymentType}?readOnly=${readOnly}`
    });
}


export function getPaidPurchasesOfDevice(userId, deviceId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/purchases/paid?userId=${userId}&deviceId=${deviceId}`
    });
}

export function linkInvoicesToPurchase(purchaseId, invoices) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/purchases/${purchaseId}/LinkInvoices`,
        data: { invoices }
    });
}

