import React, { useMemo } from "react";
import CenteredContent from "./CenteredContent";
import { Box, Typography } from '@mui/material';

const uploadAreaInputStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    height: "200%",
    width: "200%",
    opacity: 0,
    cursor: "pointer",
    overflow: "hidden"
};

export default function FileUploadArea({ children, selectedFile, emptyMessage = 'No file selected', accept, sx = {}, onChange, ...props }) {

    return (
        <Box sx={{ cursor: 'pointer', position: "relative", overflow: "hidden", borderRadius: 0.5, borderStyle: 'dotted', borderColor: '#eee', ...sx }} {...props}>
            <CenteredContent>
                {children ? children :
                    <Typography variant="h5">
                        {selectedFile ? selectedFile.name : emptyMessage}
                    </Typography>
                }
            </CenteredContent>
            <input
                type="file"
                onChange={onChange}
                accept={accept}
                style={uploadAreaInputStyle} />
        </Box>
    );
}
