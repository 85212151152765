import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Dialog from '@nvapps/common/components/ui/Dialog';
import React, { useEffect, useState } from 'react';

export default function DialogFolderName({ open, name, title, translations, onCancel, onConfirm }) {

    const [folderName, setFolderName] = useState(name);
    useEffect(() => setFolderName(name), [name]);
    useEffect(() =>
    {
        if (!open) setFolderName('');
    }, [open]);
    const hasError = (folderName || '').trim().length == 0;

    return <Dialog open={open} maxWidth='xs'>
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            <TextField
                label={translations.folderName}
                fullWidth
                margin='normal'
                value={folderName}
                autoFocus
                error={hasError}
                helperText={hasError ? translations.requiredField : ''}
                onChange={e => setFolderName(e.target.value)} />
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="secondary">
                {translations.cancel}
            </Button>
            <Button disabled={hasError} onClick={() => onConfirm(folderName)} color="primary">
                {translations.confirm}
            </Button>
        </DialogActions>
    </Dialog>;
}
