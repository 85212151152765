import { authRequest } from "./utils";

const apiBaseUrl = "";

export function getPlans() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/plans`
    });
}


export function getPlansByGsmNumber(gsmNumber) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/plans/gsm?gsmNumber=${encodeURIComponent(gsmNumber)}`
    });
}