import { Icon } from '@iconify/react';
import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import useDialogOpener, { useDebounce } from '@nvapps/common/hooks';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import EventDialog from '../../../../modals/events/EventDialog';
import { useEvents, useEventsActions, useEventsFilters, useEventsUserId } from '../../../../store/contexts/userEvents';
import DateTime from '../../../Ui/DateTime';
import NotificationsSettingsModal from './NotificationsSettingsModal';

export default function TabEvents() {

    const [events, isLoading] = useEvents();
    const { updateFilters } = useEventsActions();
    const { searchText, page, pageSize, sortBy, sortDesc, rowCount } = useEventsFilters();
    const userId = useEventsUserId();

    const [searchQuery, setSearchQuery] = useState(searchText);
    const eventDialog = useDialogOpener({ eventId : null });
    const settingDialog = useDialogOpener();

    const { t } = useTranslation();

    useDebounce(() => {
        updateFilters({ searchText: searchQuery, page: 0 });
    }, 200, [searchQuery])

    const handleSort = sortModel => {
        if (sortModel && sortModel.length > 0) updateFilters({ sortBy: sortModel[0].field, sortDesc: sortModel[0].sort === 'desc' });
        else updateFilters({ sortBy: null, sortDesc: false });
    };

     const columns = [{
        field: 'Id',
        headerName: t('id'),
        width: 120,
        renderCell: ({ row: { id } }) =>
            <Typography variant="subtitle2" noWrap>
                {id}
            </Typography>,
    },
    {
        field: 'DeviceName',
        headerName: t('device'),
        flex: 0.3,
        minWidth: 150,
        renderCell: ({ row: { deviceName, deviceBrand, deviceModel } }) =>
            <Box style={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${deviceName}`}
                </Typography>
                <Typography variant="caption" noWrap >
                    {[deviceBrand, deviceModel].filter(e => e).join(' ')}
                </Typography>
            </Box>
    },
    {
        field: 'EventTypeName',
        headerName: t('event'),
        flex: 0.5,
        minWidth: 150,
        renderCell: ({ row: { eventTypeName, eventTypeBody } }) =>
            <Box style={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${eventTypeName}`}
                </Typography>
                <Typography variant="caption" noWrap >
                    {eventTypeBody}
                </Typography>
            </Box>
    },
    {
        field: 'DateTime',
        headerName: t('dateTime'),
        width: 200,
        renderCell: ({ row: { dateTime } }) => <Box style={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    <DateTime datetime={dateTime} format='PP' />
                </Typography>
                <Typography variant="caption" noWrap >
                    <DateTime datetime={dateTime} format='pp' />
                </Typography>
            </Box> ,
    },
    {
        field: 'options',
        sortable: false,
        renderHeader: _ => <div></div>,
        renderCell: ({ row }) => <Tooltip title={t('seeDetails')} placement="top">
            <IconButton>
                <Icon icon="eva:search-outline" width={20} height={20} />
            </IconButton>
        </Tooltip>,
        align: 'right',
        width: 70
    }];
    return (
        <Card>
            <TableToolbar>
                <SearchBox
                    value={searchQuery}
                    onChange={ev => setSearchQuery(ev.target.value)}
                    placeholder={`${t('search')}...`} />
                    <Tooltip title={<Trans>notificationsSettings</Trans>}>
                        <IconButton onClick={() => settingDialog.handleOpenDialog()}>
                            <Icon icon="material-symbols:settings" width={20} height={20} />
                        </IconButton>
                    </Tooltip>
            </TableToolbar>
            <Box sx={{ height : 600 }}>
                <DataGrid
                    rows={events}
                    columns={columns}
                    pageSize={pageSize}
                    page={page}
                    rowsPerPageOptions={[1, 5, 10, 25]}
                    rowCount={rowCount}
                    loading={isLoading}
                    sortModel={sortBy ? [{ field: sortBy, sort: sortDesc ? 'desc' : 'asc' }] : []}
                    density="comfortable"
                    paginationMode="server"
                    sortingMode="server"
                    disableColumnMenu
                    onRowClick={({ row }) => eventDialog.handleOpenDialog({ eventId : row.id })}
                    hideFooterSelectedRowCount
                    pagination
                    onSortModelChange={handleSort}
                    onPageChange={page => updateFilters({ page })}
                    onPageSizeChange={pageSize => updateFilters({ pageSize })}
                    />
            </Box>
            <EventDialog open={Boolean(eventDialog.open)} userId={userId} eventId={eventDialog.props.eventId} onClose={eventDialog.handleCloseDialog}  />
            <NotificationsSettingsModal open={Boolean(settingDialog.open)} userId={userId} onClose={settingDialog.handleCloseDialog}  />
        </Card>);
}