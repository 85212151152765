import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import DevicesExpiring from '../../components/Dashboard/DevicesExpiring'

export default function Dashboard() {
    return (
        <Container maxWidth="xl">
            <Box sx={{ pb: 2 }}>
                <Typography variant="h4">Dashboard</Typography>
            </Box>
            <Grid container spacing={3}>
                 <Grid item xs={12} md={12}>
                    <DevicesExpiring />
                 </Grid>
            </Grid>
        </Container>
    )
}
