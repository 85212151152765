import { authRequest } from "./utils";

const apiBaseUrl = "";

export function getDocuments(path) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/documents/${path}`
    });
}

export function addFile(path, file, parent) {

    let formData = new FormData();

    formData.append('file', file);
    formData.append('name', "");
    formData.append('path', path);

    if (parent) formData.append('parentId', parent);

    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/documents/file`,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    });
}

export function createFolder(path, name, parentId) {

    const data = { name, parentId, path };

    return authRequest({
        method: "PUT",
        url: `${apiBaseUrl}/api/documents/folder`,
        data
    });
}

export function updateFolder(path, id, name, parentId) {

    const data = { name, parentId };

    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/documents/folder/${id}`,
        data
    });
}

export function deleteDocument(path, id) {

    return authRequest({
        method: "DELETE",
        url: `${apiBaseUrl}/api/documents/${id}`
    });
}

export function getFileUrl(url)
{
    return url ? `${apiBaseUrl}/api/${url}` : null ;
}