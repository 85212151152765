import Dialog from '@nvapps/common/components/ui/Dialog';
import React from 'react';
import { MapDialogContent } from '../../../../modals/devices/DeviceMapDialog';
import { useDevice } from '../../../../store/contexts/userDevices';

function Content({ deviceId, onClose }) {

    const [device] = useDevice(deviceId);
    const { position } = device || {};

    return <MapDialogContent device={device} position={position} onClose={onClose} />
}

export default function DeviceMapDialog({ open, deviceId, onClose }) {

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <Content deviceId={deviceId} onClose={onClose} />
        </Dialog>
    );
}
