import { DatePicker } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import Dialog from '@nvapps/common/components/ui/Dialog';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import { useTimeout } from '@nvapps/common/hooks';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import { isValid } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCallApi } from '../../hooks';
import { useDevicesActions } from '../../store/devices';

function getErrors(password, date) {
	const err = [];

	if ((password || '').trim().length === 0) err.push({ id: 'password', message: 'requiredField' });
	if (!Boolean(date)) err.push({ id: 'date', message: 'invalidDate' });
	return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Content({ device, onClose }) {
	const { id, renewalDate } = device || {};
	const [password, setPassword] = useState('');
	const [date, setDate] = useState(renewalDate || new Date().toISOString());
	const inputRef = useRef();
	const { replaceDeviceRenewalDate } = useDevicesActions();
	const { t } = useTranslation();
	const { callApi, isLoading } = useCallApi();
	const errors = useMemo(() => getErrors(password, date), [password, date]);
	const hasErrors = errors !== null;
	useEffect(() => setDate(renewalDate || new Date().toISOString()), [renewalDate]);

	const handleOk = async () => {
		const res = await callApi(replaceDeviceRenewalDate(id, date, password));
		if (res === true) {
			onClose && onClose();
		}
	};

	useTimeout(() => {
		inputRef.current.querySelector('input').focus();
	}, 200);

	function getMessage(key, defaultValue) {
		const msg = getErrorMessage(errors, key);
		return msg ? t(msg) : defaultValue;
	}

	return (
		<React.Fragment>
			{isLoading && <OverlapProgress progressProps={{ size: 100 }} />}
			<DialogTitle>
				<Trans>changeRenewalDate</Trans>
			</DialogTitle>
			<DialogContent>
				<Box component='form' autoComplete='off' display='flex' gap={2} flexDirection='column'>
					{/* <TextField style={{ display: 'none' }} /> */}
					<DatePicker
						inputVariant='standard'
						margin='normal'
						clearable
						label={<Trans>renewalDate</Trans>}
						format='yyyy-MM-dd'
						value={date}
						id='newDate'
						name='newDate'
						autoComplete='newDate'
						onChange={date => setDate(date && isValid(date) && date.toISOString())}
						renderInput={params => (
							<TextField
								{...params}
								fullWidth
								required
								variant='standard'
								error={hasError(errors, 'date')}
								helperText={getMessage('date')}
							/>
						)}
					/>
					<TextField
						htmlFor='password'
						required
						fullWidth
						variant='standard'
						autoFocus
						label={<Trans>password</Trans>}
						type='password'
						id='new-password'
						name='new-password'
						ref={inputRef}
						autoComplete='new-password'
						onChange={e => setPassword(e.target.value)}
						error={hasError(errors, 'password')}
						helperText={getMessage('password')}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} disabled={isLoading} color='secondary'>
					<Trans>cancel</Trans>
				</Button>
				<ButtonWithProgress onClick={handleOk} disabled={hasErrors} color='primary' loading={isLoading}>
					<Trans>ok</Trans>
				</ButtonWithProgress>
			</DialogActions>
		</React.Fragment>
	);
}

export default function ReplaceDeviceRenewalDateDialog({ open, device, onClose }) {
	return (
		<Dialog open={open} maxWidth='xs' onClose={onClose}>
			<Content device={device} onClose={onClose} />
		</Dialog>
	);
}
