
import { Icon } from '@iconify/react';
import {
    Avatar, Box, Button, Divider, ListItemIcon, ListItemText, MenuItem, Stack, Tooltip, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Label from '@nvapps/common/components/ui/Label';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import useDialogOpener from '@nvapps/common/hooks';
import { ContractStatusEnum, PositionCommunications, getStatusColorOfContract, getStatusOfContract, getStatusOfPosition, getStatusOfPositionCommunication } from '@nvtracker/common/helpers';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import DateTime from '../../components/Ui/DateTime';
import MoreMenu from '../../components/Ui/MoreMenu';
import DeviceMapDialog from '../../modals/devices/DeviceMapDialog';
import { ViewUserAndDeviceDialog } from '../../modals/devices/ViewDeviceDialog';
import { useDevices, useDevicesActions, useDevicesFilters, useDevicesSuspendedExport } from '../../store/devicesSuspended';
import BlinkIcon from '../Ui/BlinkIcon';
import { GsmState } from '../Ui/GsmState';
import { useDevicesGridActions } from './DevicesLogic';

function ContractCell({ renewalDate, hasPendingPurchase }) {

    const { t } = useTranslation();
    const statusContract = getStatusOfContract(renewalDate);
    const translation = statusContract === ContractStatusEnum.EXPIRED ? 'expired' : 'expires';
    const text = renewalDate ? t(`dates.${translation}`, { date: new Date(renewalDate) }) : '-';

    return <Box display="flex" flexDirection="row" gap={1}>
        <Tooltip title={text}>
            <Box>
                <Label variant="ghost" color={getStatusColorOfContract(renewalDate)}>
                    <Typography variant="subtitle2">
                        <DateTime datetime={renewalDate} format="PPP" />
                    </Typography>
                </Label>
            </Box>
        </Tooltip>
        {hasPendingPurchase && <Tooltip title={<Trans>pendingPurchase</Trans>}>
            <Box color="warning.dark">
                <BlinkIcon icon="ant-design:warning-outlined" height={24} width={24} />
            </Box>
        </Tooltip>}
    </Box>
}

export default function DevicesInUse() {

    const [devices, loading] = useDevices();
    const { pageSize, sortModel, searchQuery, selectedRows, changePage, changePageSize, selectRows, onSearch, sort, fetchDevices, page } = useDevicesGridActions(useDevicesActions, useDevicesFilters);
    const { t } = useTranslation();
    const mapDialog = useDialogOpener({ device: null, position: null });
    const detailsDialog = useDialogOpener({ user: null, device: null });

    const getUserAndDeviceOfRow = row =>
    ({
        user:
        {
            userName: row.userName,
            name: row.userRealName,
            phoneNumber: row.userPhoneNumber
        },
        device:
        {
            id : row.id,
            userId: row.userId,
            name: row.name,
            brand: row.brand,
            model: row.model,
            gsmNumber: row.gsmNumber,
            gsmNumberActive: row.gsmNumberActive,
            gsmNumberStateDate: row.gsmNumberStateDate,
            gsmNumberStateUsername : row.gsmNumberStateUsername,
            smsLimit: row.smsLimit,
            smsCredits: row.smsCredits,
            smsDate: row.smsDate,
            serialNumber: row.serialNumber,
            renewalDate: row.renewalDate,
            planId: row.planId,
            equipmentVersion: row.equipmentVersion,
            status: row.status,
            deviceTypeId: row.deviceTypeId,
            position: row.position,
            lastResult: row.lastResult,
        },
        position: row.position
    });


    const handlePositionClick = (e, row) => {
        e.stopPropagation();
        row.position.stateEndTime && mapDialog.handleOpenDialog(getUserAndDeviceOfRow(row))
    }

    const columns = [{
        field: 'serialNumber',
        headerName: t('device'),
        minWidth: 190,
        renderCell: ({ row: { serialNumber, gsmNumber, smNumber, gsmNumberActive, gsmNumberStateDate, gsmNumberStateUsername } }) =>
            <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2" noWrap>
                    {serialNumber}
                </Typography>
                {/* <Typography variant="caption" noWrap pt={0.5}>
                    <PhoneNumber value={gsmNumber} />
                </Typography> */}
                <GsmState gsmNumber={gsmNumber} gsmNumberActive={gsmNumberActive} gsmNumberStateDate={gsmNumberStateDate} gsmNumberStateUsername={gsmNumberStateUsername} typographyProps={{ variant: "caption2", noWrap: true }} pt={0.5} />
            </Box>
    },
    {
        field: 'userName',
        headerName: t('client'),
        minWidth: 200,
        flex: 0.4,
        valueGetter: params => {
            const text = [(params.row.userRealName || ''), (params.userName || '')].filter(e => e);
            return text.length === 0 ? null : text.join(' ');
        },
        renderCell: ({ row }) => !row.userName ? <Trans>n_A</Trans> : <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={row.userRealName} />
            <Box sx={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${row.userRealName || row.userName}`}
                </Typography>
                {row.userRealName &&
                    <Typography variant="caption" noWrap>
                        {`${row.userName}`}
                    </Typography>}
            </Box>
        </Stack>
    },
    {
        field: 'deviceName',
        headerName: t('info'),
        minWidth: 200,
        flex: 0.3,
        valueGetter: ({ row }) => {
            const text = [(row.name || ''), (row.brand || ''), (row.model || '')].filter(e => e);
            return text.length === 0 ? null : text.join(' ');
        },
        renderCell: ({ row }) => !row.name ? <Trans>n_A</Trans> :
            <Box sx={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${row.name}`}
                </Typography>
                <Typography variant="caption" noWrap >
                    {[row.brand, row.model].filter(e => e).join(' ')}
                </Typography>
            </Box>
    },
    {
        field: 'renewalDate',
        headerName: t('contractValidity'),
        minWidth: 200,
        valueGetter: ({ row }) => row.renewalDate,
        renderCell: ({ row: { renewalDate, planName, hasPendingPurchase } }) => Boolean(renewalDate) === false && Boolean(planName) === false ? <Trans>n_A</Trans> :
            <Box display="flex" flexDirection="column" gap={0.5}>
                {Boolean(planName) === true &&
                    <Box display="flex" flexDirection="row" gap={0.5}>
                        <Typography variant="subtitle2" noWrap>
                            <Trans>plan</Trans>:
                        </Typography>
                        <Typography variant="body2" noWrap>
                            {planName}
                        </Typography>
                    </Box>}
                {Boolean(renewalDate) && <ContractCell renewalDate={renewalDate} hasPendingPurchase={hasPendingPurchase} />}
            </Box>
    },
    {
        field: 'position',
        headerName: t('status'),
        valueGetter: ({ row }) => row.position ? row.position.stateEndTime : '',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: ({ row }) => Boolean(row.position) === false ? <Trans>n_A</Trans> :
            <Tooltip title={row.position.stateEndTime ? t('openMap') : ''} placement="top">
                <Box display="flex" gap={1} flexDirection="column" alignItems="center" onClick={e => handlePositionClick(e, row)} width="100%">
                    {row.position.stateEndTime && <Label color={PositionCommunications[getStatusOfPositionCommunication(row.position.stateEndTime)].color} sx={{ width: "100%", cursor: 'pointer', fontWeight: 600 }}>
                        <DateTime datetime={row.position.stateEndTime} />
                    </Label>}
                    <Label color={'info'} sx={{ width: "100%", cursor: 'pointer' }}>
                        {t(getStatusOfPosition(row.position.status).text)}
                    </Label>
                </Box>
            </Tooltip>
    },
    {
        field: 'Options',
        renderHeader: () => <div></div>,
        sortable: false,
        renderCell: ({ row }) => <MoreMenu>
            <MenuItem sx={{ color: 'text.secondary' }} onClick={() => detailsDialog.handleOpenDialog(getUserAndDeviceOfRow(row))} >
                <ListItemIcon>
                    <Icon icon="eva:search-outline" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={t('view')} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
            <Divider />
            <MenuItem sx={{ color: 'text.secondary' }} component={RouterLink} to={`/app/clients/${row.userId}/general`}>
                <ListItemIcon>
                    <Icon icon="eva:person-fill" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary={t('clientRecord')} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
        </MoreMenu>,
        align: 'right',
        width: 70
    }]

    const [exportList, isExporting] = useDevicesSuspendedExport();

    return <>
        <TableToolbar>
            <SearchBox
                value={searchQuery}
                onChange={ev => onSearch(ev.target.value)}
                placeholder={`${t('search')}...`} />
            <Button
                sx={{ ml: 'auto' }}
                variant="outlined"
                onClick={() => fetchDevices()}
                startIcon={<Icon icon="eva:refresh-fill" />}>
                <Trans>refresh</Trans>
            </Button>
            <MoreMenu sx={{ ml: 1 }}>
                <MenuItem sx={{ color: 'text.secondary' }} onClick={() => exportList(searchQuery, sortModel)} >
                    <ListItemIcon>
                        <Icon icon="ic:round-print" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary={t('export')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </MoreMenu>
        </TableToolbar>
        <Box sx={{ flex: 1, width: '100%' }}>
            <DataGrid
                rows={devices}
                columns={columns}
                pageSize={pageSize}
                page={page}
                rowsPerPageOptions={[5, 10, 25]}
                loading={loading || isExporting}
                sortModel={sortModel}
                selectionModel={selectedRows}
                density="comfortable"
                disableColumnMenu
                onRowClick={({ row }) => detailsDialog.handleOpenDialog(getUserAndDeviceOfRow(row))}
                hideFooterSelectedRowCount
                pagination
                onSortModelChange={sort}
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                onSelectionModelChange={selectRows}
            />
        </Box>
        <ViewUserAndDeviceDialog open={detailsDialog.open} showRenewButton={false} showGsmActionsButton={false} user={detailsDialog.props.user} device={detailsDialog.props.device} onClose={detailsDialog.handleCloseDialog} />
        <DeviceMapDialog open={mapDialog.open} device={mapDialog.props.device} position={mapDialog.props.position} onClose={mapDialog.handleCloseDialog} />
    </>
}

