import { urlEncode } from "@nvapps/common/utils";
import { authRequest } from "./utils";

const apiBaseUrl = "";

export function getUserDevices(userId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices?userId=${userId}`
    });
}

export function registerDevice(userId, data = { brand: null, model: null, gsmNumber: null }) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/devices?userId=${userId}`,
        data
    });
}

export function getSerialNumberInfo(deviceTypeId, serialNumber, gsmNumber) {
    const encodedSerialNumber = urlEncode(serialNumber);
    const encodedGsmNum = urlEncode(gsmNumber);
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/checkSerialNumber?deviceTypeId=${deviceTypeId}&serialNumber=${encodedSerialNumber}&gsmNumber=${encodedGsmNum}`
    });
}

export function getDevice(id) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/${id}`
    });
}

export function updateDevice(id, data = { brand: null, model: null, gsmNumber: null }) {
    return authRequest({
        method: "PUT",
        url: `${apiBaseUrl}/api/devices/${id}`,
        data
    });
}

export function removeDevice(id) {
    return authRequest({
        method: "DELETE",
        url: `${apiBaseUrl}/api/devices/${id}`
    });
}

export function renewDevice(id, formData) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/devices/${id}/renewal`,
        data: formData
    });
}

export function getRoute(id, from, to) {
    const encodedFrom = encodeURIComponent(from);
    const encodedTo = encodeURIComponent(to);
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/${id}/route?from=${encodedFrom}&to=${encodedTo}`
    });
}

export function getUserDevicesPositions(userId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/positions?userId=${userId}`
    });
}

export function getDevicesPositions() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/positions`
    });
}

export function getDevicePosition(deviceId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/${deviceId}/position`
    });
}

export function getDevicesAvailable() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/availableSerialNumbers`
    });
}

export const getDevicesExpiringUrl = `${apiBaseUrl}/api/devices/expiring`;

export function getDevicesExpiring(daysMargin, onlyExpired) {

    return authRequest({
        method: "GET",
        url: `${getDevicesExpiringUrl}?daysMargin=${daysMargin}`
    });
}

export function exportDevicesExpiring(daysMargin) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/expiring.xls?daysMargin=${daysMargin}`,
        responseType: 'blob'
    });
}

export const getDevicesPagedUrl = `${apiBaseUrl}/api/devices/paged`
export const getDevicesAllUrl = `${apiBaseUrl}/api/devices/detailed`


export function getDevicesInUse() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/inuse`
    });
}

export function exportDevicesInUse({ searchText, sortBy, sortDesc }) {

    let filters = [];

    if (searchText) filters.push(`searchText=${urlEncode(searchText)}`);
    if (sortBy) filters.push(`&sortBy=${sortBy}`);
    if (sortDesc != null) filters.push(`&sortDesc=${sortDesc}`);

    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/inuse.xls?${filters.join('&')}`,
        responseType: 'blob'
    });
}

export function getDevicesSuspended() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/suspended`
    });
}

export function exportDevicesSuspended({ searchText, sortBy, sortDesc }) {

    let filters = [];

    if (searchText) filters.push(`searchText=${urlEncode(searchText)}`);
    if (sortBy) filters.push(`&sortBy=${sortBy}`);
    if (sortDesc != null) filters.push(`&sortDesc=${sortDesc}`);

    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/suspended.xls?${filters.join('&')}`,
        responseType: 'blob'
    });
}

export function getDevicesStock() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/stock`
    });
}

export function exportDevicesStock({ searchText, sortBy, sortDesc }) {

    let filters = [];

    if (searchText) filters.push(`searchText=${urlEncode(searchText)}`);
    if (sortBy) filters.push(`&sortBy=${sortBy}`);
    if (sortDesc != null) filters.push(`&sortDesc=${sortDesc}`);

    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/stock.xls?${filters.join('&')}`,
        responseType: 'blob'
    });
}

export function removeDevicePlanAndRenewalDate(deviceId) {
    return authRequest({
        method: "DELETE",
        url: `${apiBaseUrl}/api/devices/${deviceId}/removePlanRenewalDate`
    });
}

export function getDeviceExtraInfo(deviceId) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/devices/${deviceId}/extraInfo`
    });
}

export function suspendDeviceGsm(id) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/devices/${id}/suspendGsm`,
    });
}

export function activateDeviceGsm(id) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/devices/${id}/activateGsm`,
    });
}

export function repairDeviceSerialNumber(id) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/devices/${id}/repairSerialNumber`
    });
}

export function replaceDeviceRenewalDate(id, date, password) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/devices/${id}/replaceRenewalDate`,
        data: { password, date }
    });
}

export function finishDeviceInstallation(id, formData) {
    return authRequest({
        method: "POST",
        url: `${apiBaseUrl}/api/devices/${id}/finishInstallation`,
        data: formData
    });
}
